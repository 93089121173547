import React, { Component } from 'react';


import { BrowserRouter, Route, Link } from "react-router-dom";
class infoConent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            text: this.props.text,
        }
    }
    componentWillReceiveProps(props) {

        this.setState({
            show: props.show,
            text: props.text
        });
    }

    closePopup() {
        this.setState({ show: false })
    }
    render() {
        return (
            <section id="under" style={{ visibility: this.state.show ? 'visible' : 'hidden', opacity: this.state.show ? '1' : '0', transition: 'opacity 1.5' }}>

                <div className="boxPopup">
                    <div className="contentPopup">
                        <div className="titlecontentPopup">{this.state.text} </div>

                    </div>
                </div>
            </section>
        );
    }
}
export default infoConent;