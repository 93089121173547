import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';
import LoginPopup from './popup/loginPopup';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { Base64 } from 'js-base64';

import Footer from '../Component/pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


 


let lang = require('../lang/ar').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'Ar');
    lang = require('../lang/ar').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}



class HomeScreen extends Component {
    state = {
        items: [],
        type: localStorage.getItem('tabId') == undefined ? 'songs' : localStorage.getItem('tabId'),
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        showPlaylist: false,
        showLoginPopup: false,
        favSt: '',
        no_contents_available: '',
        load: true,
        progressDataValue: 0,
        CurrentItem: 0,
        lang: lang,
        popupSub: false
    }

    changeType = (type) => {
        // console.log(type)
        var arr = JSON.parse(localStorage.getItem('token'));
        this.setState({
            type: type,
            showPopup: false,
            showPopup2: false,
            showLoginPopup: false
        })
        localStorage.setItem('tabId', type)
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/rowsScreen";
        let formData = new FormData();
        if (JSON.parse(localStorage.getItem('token')).access_token == 'guest')
            formData.append('profile_id', 'guest');
        else
            formData.append('profile_id', arr.UserData._id);

        formData.append('access_token', arr.access_token);
        formData.append('typeOfContent', type.toLowerCase());

        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(url,
            formData,
        ).then(response2 => {
            // console.log(response2.data.allarray)
            this.setState({
                items: response2.data.allarray,
                load: false
            })
        })
    }
    componentDidMount() {

        this.setState({
            type: localStorage.getItem('tabId') == undefined || localStorage.getItem('tabId') == null ? 'songs' : localStorage.getItem('tabId')
        })
        let formDataMsg = new FormData();
        formDataMsg.append('idmsg', 'no_contents_available');
        formDataMsg.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getmsgbyid",
            formDataMsg,
        ).then(response2 => {
            this.setState({ no_contents_available: response2.data.msg })
        })



        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            localStorage.removeItem('token');
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            if (arr.access_token == 'guest') {
                const url = backend_url + "/app/rowsScreen";
                let formData = new FormData();

                formData.append('profile_id', 'guest');
                formData.append('typeOfContent', this.state.type);

                formData.append('lang', localStorage.getItem('lang'));
                Axios.post(url,
                    formData, {
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        // console.log("onUploadProgress", totalLength);
                        if (totalLength !== null) {
                            this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                        }
                    },
                    onDownloadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        // console.log("onDownloadProgress", totalLength);
                        if (totalLength !== null) {
                            this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                            this.setState({
                                progressDataValue: ((this.progressData * 875) / 100)
                            })
                            if (this.progressData == 100) {
                                setTimeout(function () {
                                    this.setState({ load: false })
                                }.bind(this), 1000);
                            }
                        }
                    },
                }
                ).then(response2 => {

                    localStorage.setItem('plan', Base64.encode('free'))
                    this.setState({
                        items: response2.data.allarray,
                    })
                });
            }
            else {
                const url = backend_url + "/app/accesscode";
                let formData = new FormData();
                formData.append("access_token", arr.access_token);
                Axios.post(url,
                    formData,
                ).then(response => {
                    if (response.data.data == undefined) {
                        localStorage.removeItem('token');
                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                    }
                    else {
                        let backend_url = process.env.REACT_APP_BACKEND_URL;
                        let formData2 = new FormData();
                        formData2.append('access_token', arr.access_token);

                        formData2.append('lang', localStorage.getItem('lang'));

                        Axios.post(backend_url + "/app/getProfile",
                            formData2,
                        ).then(Profiles => {
                            if (Profiles.data.message == 'Failed Auth.') {
                                localStorage.removeItem('token');
                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                            }
                            Profiles.data.newarray.map((pr) => {
                                if (pr.mainProfile) {
                                    localStorage.setItem('mainProfile', JSON.stringify(pr));
                                }
                            })
                            //plan 
                            localStorage.setItem('plan', Base64.encode(Profiles.data.newarray[0].usersPlan[Profiles.data.newarray[0].usersPlan.length - 1]))
                            localStorage.setItem('Profiles', JSON.stringify(Profiles.data));
                            const url = backend_url + "/app/rowsScreen";
                            let formData = new FormData();
                            formData.append('access_token', arr.access_token);
                            Profiles.data.newarray.map((item) => {
                                if (item.mainProfile) {
                                    formData.append('profile_id', item._id);
                                    localStorage.setItem('minImg', Base64.encode(backend_url + '/public/' + item.avatarData[0]));
                                }
                            })
                            formData.append('typeOfContent', this.state.type);

                            formData.append('lang', localStorage.getItem('lang'));


                            Axios.post(url,
                                formData, {
                                onUploadProgress: (progressEvent) => {
                                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                                    // console.log("onUploadProgress", totalLength);
                                    if (totalLength !== null) {
                                        this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);

                                        // console.log("onUploadProgress", this.progressData);
                                    }
                                },
                                onDownloadProgress: (progressEvent) => {
                                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                                    // console.log("onDownloadProgress", totalLength);
                                    if (totalLength !== null) {
                                        this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                                        // console.log("onUploadProgress", this.progressData);
                                        this.setState({
                                            progressDataValue: ((this.progressData * 875) / 100)
                                        })
                                        if (this.progressData == 100) {
                                            setTimeout(function () {
                                                this.setState({ load: false })
                                            }.bind(this), 1000);
                                        }
                                    }
                                },
                            }
                            ).then(response2 => {
                                // console.log(response2.data.allarray)
                                let tempval = JSON.parse(localStorage.getItem('token'));
                                localStorage.setItem('choosePlan', tempval.UserData._id)
                                this.setState({
                                    items: response2.data.allarray,
                                })
                            });
                        });
                    }
                })
            }


        }
    }

    showPopupAction(video) {
        if (JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
            var arr = JSON.parse(localStorage.getItem('token'));

            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/getFavoriteStatus";
            let formData = new FormData();
            formData.append('access_token', arr.access_token);
            formData.append('profile_id', arr.UserData._id);
            formData.append('contentID', video._id);
            formData.append('lang', localStorage.getItem('lang'));


            Axios.post(url,
                formData,
            ).then(response2 => {
                // console.log(response2.data)
                if (response2.data.status == true) {
                    this.setState({
                        popupshow: video,
                        showPopup: false,
                        showPopup2: true,
                        favSt: true
                    })
                }
                else {
                    this.setState({
                        popupshow: video,
                        showPopup: false,
                        showPopup2: true,
                        favSt: false
                    })
                }
            })
        }



    }
    onCurrentChange = current => {
        this.setState(
            { CurrentItem: current.index }
        )
    }
    render() {
        const { items } = this.state;
        return (
            <div>

                <div style={{ position: 'fixed', top: '0', right: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(75,62,150,0.94)', zIndex: '99999', display: this.state.load ? 'block' : 'none', transform: localStorage.getItem('lang') == 'Ar' ? 'rotate(180deg)' : '' }}>
                    <svg width="914.227" height="233.452" viewBox="0 0 914.227 233.452" className="loadingSVG">
                        <defs>
                            <linearGradient id="linear-gradient" x1="0.5" y1="0.069" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#a9de1e" />
                                <stop offset="0.429" stopColor="#8ad225" />
                                <stop offset="1" stopColor="#549f24" />
                            </linearGradient>
                            <clipPath id="clip-path">
                                <rect id="spacer" width={this.state.progressDataValue} height="78.223" rx="39.112" fill="url(#linear-gradient)" />
                            </clipPath>
                        </defs>
                        <g id="Group_5023" data-name="Group 5023" transform="translate(14352 1995.68)">
                            <g id="Group_5022" data-name="Group 5022" transform="translate(0 -577)">
                                <rect id="spacer-2" data-name="spacer" width="904.227" height="106.68" rx="53" transform="translate(-14352 -1418.68)" fill="#6456be" />
                                <rect id="spacer-3" data-name="spacer" width="870.964" height="78.223" rx="39" transform="translate(-14337.862 -1404.451)" fill="#3f2e8e" />
                            </g>
                            <g id="Group_5021" data-name="Group 5021" transform="translate(0 -577)">
                                <rect id="spacer-4" data-name="spacer" width={this.state.progressDataValue} height="77.985" rx="38.993" transform="translate(-14337.386 -1404.388)" fill="url(#linear-gradient)" />
                                <path id="Path_387" data-name="Path 387" d="M-1928.608,528.031c-3.757,0-26.736,2.868-27.7,23.665" transform="translate(-12364.298 -1917.021)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="10" opacity="0.5" />
                                <g id="Mask_Group_33" data-name="Mask Group 33" transform="translate(-14337.862 -1404.451)" opacity="0.293" clipPath="url(#clip-path)">
                                    <g id="Group_2224" data-name="Group 2224" transform="translate(-4.14 -14.229)" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                                        <path id="Path_2808" data-name="Path 2808" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6293.516 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2806" data-name="Path 2806" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6134.938 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2810" data-name="Path 2810" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6450.962 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2813" data-name="Path 2813" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6687.696 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2805" data-name="Path 2805" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6056.783 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2809" data-name="Path 2809" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6371.672 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2814" data-name="Path 2814" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6608.408 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2807" data-name="Path 2807" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6214.227 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2811" data-name="Path 2811" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6529.118 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2812" data-name="Path 2812" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6765.853 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2804" data-name="Path 2804" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(5977.494 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                <section id="home" >
                    <div className="popupSub" style={{ opacity: this.state.popupSub ? 1 : 0, visibility: this.state.popupSub ? 'visible' : 'hidden', transition: 'all 0.7s' }}>
                        <div className="colsebtn" onClick={() => this.setState({ popupSub: false })}>X</div>
                        <div className="boxPopup">

                            <img src={require("../assets/img/Lock icon.png")} />
                            <div className="textPopup">{this.state.lang['SubscribeNowPopup'].Upgrade}</div>
                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
                                    }}
                                >{this.state.lang['SubscribeNowPopup'].UpgradeBTN}</a>
                            </div>
                        </div>

                    </div>
                    <div className="header">
                        <div className="leftSec">
                            <img src={require("../assets/img/logo.png")} />
                            {/* <div className="orangeSer playList"
                                onClick={() => {
                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist');
                                }}
                            ></div>
                            <div className="orangeSer fav" onClick={() => {
                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'favorites');
                            }}></div>
                            <div className="whiteSer search"
                                onClick={() => {
                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'search');
                                }}></div> */}
                        </div>
                        <div className="centerSec">
                            <div className={this.state.type == "songs" ? "Songs activeSongs" : "Songs"}
                                onClick={() => {
                                    this.changeType('songs')
                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelSongsCount}</div>
                            </div>
                            <div className={this.state.type == "stories" ? "Stories activeStories" : "Stories"}
                                onClick={() => {
                                    this.changeType('stories')

                                }}
                            >
                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelStoriesCount}</div>

                            </div>
                            <div className={this.state.type == "shows" ? "Shows activeShows" : "Shows"}
                                onClick={() => {

                                    this.changeType('shows')

                                }}
                            >

                                <div className="typetext">{this.state.lang['PlaylistScreen'].labelShowsCount}</div>
                            </div>
                        </div>
                        <div className="rightSec">

                            <Tooltip TransitionComponent={Zoom} title={this.state.lang['HeaderTitles'].titleMyPlaylists} aria-label="My Playlists" arrow>
                                <div className="orangeSer playList"
                                    onClick={() => {
                                        if (JSON.parse(localStorage.getItem('token')).access_token != 'guest')
                                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'playlist');
                                        else
                                            this.setState({
                                                showLoginPopup: true,
                                                showPopup2: false,
                                                showPopup: false
                                            })
                                    }}
                                ></div>
                            </Tooltip>



                            <Tooltip TransitionComponent={Zoom} title={this.state.lang['HeaderTitles'].titleMyFavorite} aria-label="Favorites" arrow>
                                <div className="orangeSer fav" onClick={() => {
                                    if (JSON.parse(localStorage.getItem('token')).access_token != 'guest')
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'favorites');
                                    else
                                        this.setState({
                                            showLoginPopup: true,
                                            showPopup2: false,
                                            showPopup: false
                                        })
                                }}></div>
                            </Tooltip>

                            <Tooltip title={this.state.lang['SearchScreen'].textInputSearchLabel} enterDelay={500} leaveDelay={200} arrow>

                                <div className="orangeSer search"
                                    onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'search');
                                    }}></div>
                            </Tooltip>


                            <Tooltip TransitionComponent={Zoom} title={localStorage.getItem('lang') == 'Ar' ? "حسابي" : "My account"} aria-label={localStorage.getItem('lang') == 'Ar' ? "حسابي" : "My account"} arrow>
                                <div>
                                    <img src={localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? localStorage.getItem('minImg') ? Base64.decode(localStorage.getItem('minImg')) : '' : require("../assets/img/Group 4967.png") : ''} onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'parents');
                                    }} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    {localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' && (
                        <ActionContent show2={{ showPopup2: this.state.showPopup2, data2: this.state.popupshow, favSt: this.state.favSt, type: this.state.type }} showPlaylist={this.state.showPlaylist} />
                    ) : ''}
                    {localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')).access_token == 'guest' && (
                        <LoginPopup showLoginPopup={this.state.showLoginPopup} />
                    ) : ''}
                    <InfoConent show={{ showPopup: this.state.showPopup, data: this.state.popupshow, tags: this.state.tags, type: this.state.type }} />
                    <div className="bodyHome">
                        {
                            items ?
                                items.map((item, index) =>

                                    <TransitionGroup key={index}>
                                        <CSSTransition
                                            key={item.title}
                                            timeout={500}
                                            classNames="messageout"
                                        >
                                            <div className="sliderSec" id={item.title}>
                                                <div className="titleSlider">{item.title}</div>
                                                <div className="slider">
                                                    <Carousel focusOnSelect={false} itemsToShow={5} pagination={false} outerSpacing={0}
                                                        itemPosition={"flex-start"} showEmptySlots={true}
                                                        onNextEnd={this.onCurrentChange}
                                                        onPrevEnd={this.onCurrentChange}
                                                        isRTL={localStorage.getItem('lang') == 'Ar' ? true : false}
                                                    >

                                                        {item.items.map(video => {
                                                            return <div key={video._id} className="item">

                                                                <img id="more" style={{ display: this.state.type != 'shows' ? 'block' : 'none' }} src={require("../assets/img/Group 855.png")}
                                                                    onClick={(() => {
                                                                        if (JSON.parse(localStorage.getItem('token')).access_token != 'guest')
                                                                            if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))
                                                                                this.showPopupAction(video)
                                                                            else
                                                                                this.setState({
                                                                                    popupSub: true,
                                                                                    showPopup2: false,
                                                                                    showPopup: false
                                                                                })

                                                                        else
                                                                            this.setState({
                                                                                showLoginPopup: true,
                                                                                showPopup2: false,
                                                                                showPopup: false
                                                                            })
                                                                    })}
                                                                />
                                                                <img id="info" src={require("../assets/img/Group 4983.png")}
                                                                    onClick={(() => {
                                                                        this.setState({
                                                                            popupshow: video,
                                                                            tags: video.tags,
                                                                            showPopup2: false,
                                                                            showLoginPopup: false,
                                                                            showPopup: true
                                                                        })
                                                                    })}
                                                                />
                                                                <div className="lockIcon" style={{ display: !video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3') ? 'none' : '' }}></div>
                                                                <div className="gotocontent"
                                                                    onClick={() => {
                                                                        if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))
                                                                            if (this.state.type != 'shows')
                                                                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'content/' + this.state.type + '/' + video._id);
                                                                            else
                                                                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'season/' + video._id + '/' + video.title);
                                                                        else
                                                                            this.setState({
                                                                                popupSub: true,
                                                                                showPopup2: false,
                                                                                showPopup: false
                                                                            })
                                                                    }}
                                                                ></div>
                                                                <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                                <div className={localStorage.getItem('lang') == 'En' ? "lableContentEn" : "lableContentAr"}>{video.title}</div>
                                                            </div>
                                                        }


                                                        )}
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    </TransitionGroup>



                                )
                                : ''
                        }

                        {/* {items.length == 0 ? <samp style={{ display: this.state.load ? 'none' : 'block' }}>{this.state.no_contents_available}</samp> : ''} */}

                    </div>
                </section>

                <Footer />
            </div >
        );
    }
}

export default HomeScreen;