import React, { useEffect } from 'react';

import Axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import FadeIn from 'react-fade-in';
import { Base64 } from 'js-base64';
import Footer from './pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PayPalButton } from "react-paypal-button-v2";

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}
export default function Checkout() {
    const { id } = useParams();
    const [data, setData] = React.useState('');
    const [load, setLoad] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const alert = useAlert();



    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const status = urlParams.get('status')
        const message = urlParams.get('message')
        const code = urlParams.get('code')
        if (status == '200') {
            let formData = new FormData();
            formData.append('user_id', id);
            formData.append('code', code);
            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/mondia/subscribe",
                formData,
            ).then(res => {
                let partners =
                    <div style={{
                        fontSize: '25px',
                        marginTop: '20px',
                        color: 'green',
                    }}>
                        {lang1['ResetPasswordScreen'].succ}

                        <br />
                        <div className="btns" style={{ marginTop: '10px', zIndex: '99999999' }}>
                            <a className="btn1"
                                onClick={() => {
                                    history.push('/');
                                }}
                            >
                                <span >{lang1['ResetPasswordScreen'].quit}</span>

                            </a>
                        </div>
                    </div >
                setData(partners)
            });

        }
        else if (status == '402') {
            let partners =
                <div style={{
                    fontSize: '25px',
                    marginTop: '20px',
                    color: 'red',
                }}>
                    {lang1['ResetPasswordScreen'].error1}!
                    <br />
                    <div className="btns" style={{ marginTop: '10px', zIndex: '99999999' }}>
                        <a className="btn1"
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <span >{lang1['ResetPasswordScreen'].quit}</span>

                        </a>
                    </div>
                </div >
            setData(partners)
        }
        else if (status == '409') {
            let partners =
                <div style={{
                    fontSize: '25px',
                    marginTop: '20px',
                    color: 'red',
                }}>
                    {lang1['ResetPasswordScreen'].error2}!
                    <br />
                    <div className="btns" style={{ marginTop: '10px', zIndex: '99999999' }}>
                        <a className="btn1"
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <span >{lang1['ResetPasswordScreen'].quit}</span>

                        </a>
                    </div>
                </div >
            setData(partners)
        }
        else {
            let partners =
                <div style={{
                    fontSize: '25px',
                    marginTop: '20px',
                    color: 'red',
                }}>
                    {lang1['ResetPasswordScreen'].error3}!
                    <p>{message === "USER_DECLINED_PURCHASE_DURING_CONFIRMATION" ? lang1['ResetPasswordScreen'].USER_DECLINED_PURCHASE_DURING_CONFIRMATION : message}</p>
                    <br />
                    <div className="btns" style={{ marginTop: '10px', zIndex: '99999999' }}>
                        <a className="btn1"
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <span >{lang1['ResetPasswordScreen'].quit}</span>

                        </a>
                    </div>
                </div >
            setData(partners)
        }

    }, []);


    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ display: load ? 'block' : 'none' }} >

                <section id="footer" >

                    <div className="stage">



                    </div>
                    <FadeIn>
                        <div className={"title fadeInAnmthionActive"} >
                            <img className="imgLogo pointer" style={{ width: '150px' }} src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                            {
                                data
                            }

                        </div>

                    </FadeIn>

                </section>

            </section>
            {/* <Footer /> */}
        </div>
    );
}