import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';


import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50vw',

    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState('En');
  const [privacyPolicy, setPrivacyPolicy] = React.useState('');
  const classes = useStyles();

  const [load, setLoad] = React.useState(true);

  useEffect(() => {
    setLoad(false);
    let backend_url = process.env.REACT_APP_BACKEND_URL;
    const url = backend_url + "/app/getprivacy";
    let formData = new FormData();
    formData.append('lang', localStorage.getItem('lang'));

    Axios.post(url,
      formData,
    ).then(response2 => {
      // console.log(response2.data.privacyPolicy)
      setLoad(true);
      setPrivacyPolicy(response2.data.privacyPolicy)

    })
  }, []);
  let history = useHistory();

  const prettyDate = (date) => {

    var d = new Date(date);

    return d.toDateString() + " ";
  }

  return (
    <div>
      {/* <Backdrop className="backdrop" open={!load}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <section id="privacyPolicy" style={{ display: load ? 'block' : 'none' }} >
        <div className="privacyPolicyBody" dangerouslySetInnerHTML={{ __html:  privacyPolicy}} />

      </section>


    </div>
  );
}