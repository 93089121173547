import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Base64 } from 'js-base64';

import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import ReactHlsPlayer from 'react-hls-player';
import { colors } from '@material-ui/core';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import UnderPopup from './popup/underpopup';
let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}

class Player extends Component {
    state = {
        items: [],
        type: 'songs',
        favSt: false,
        dataContent: [],
        getRelated: [],
        player: [],
        play: true,
        loop: false,
        Seconds: 0,
        duration: 0,
        playedSecondsBar: 0.0,
        played: 0,
        sound: true,
        sadasdsad: 0,
        showCratePlaylist: false,
        showCratePlaylist2: false,
        NameNewPlayList: '',
        playListItems: [],
        showPopup: false,
        controls: false,
        textPopup: '',
        hidediv: false,
        load: true,
        showtitlealert: false,
        progressDataValue: 100,
        lang: lang1,
        indexshow: 0,
        popupSub: false

    }


    componentDidMount() {


        document.addEventListener('fullscreenchange', this.exitHandler);
        document.addEventListener('webkitfullscreenchange', this.exitHandler);
        document.addEventListener('mozfullscreenchange', this.exitHandler);
        document.addEventListener('MSFullscreenChange', this.exitHandler);

        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData
            ).then(response => {
                if (response.data.data == undefined && JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {
                    let backend_url = process.env.REACT_APP_BACKEND_URL;
                    const url = backend_url + "/app/getSingleContent";
                    let formData = new FormData();
                    if (JSON.parse(localStorage.getItem('token')).access_token == 'guest')
                        formData.append('profile_id', 'guest');
                    else
                        formData.append('profile_id', arr.UserData._id);
                    formData.append('access_token', arr.access_token);
                    formData.append('contnetId', this.props.match.params.id);
                    formData.append('typeContent', this.props.match.params.content);
                    formData.append('lang', localStorage.getItem('lang'));



                    Axios.post(url,
                        formData, {
                        onUploadProgress: (progressEvent) => {
                            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                            // console.log("onUploadProgress", totalLength);
                            if (totalLength !== null) {
                                this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);

                            }
                        },
                        onDownloadProgress: (progressEvent) => {
                            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                            // console.log("onDownloadProgress", totalLength);
                            if (totalLength !== null) {
                                this.progressData = Math.round((progressEvent.loaded * 100) / totalLength);
                                // console.log("onUploadProgress", this.progressData);

                                this.setState({
                                    progressDataValue: ((this.progressData * 875) / 100)
                                })


                            }
                        },
                    }
                    ).then(response2 => {
                        if (response2.data.items[0] != undefined) {
                            
                            if (response2.data.items[0].isPaid && ((Base64.decode(localStorage.getItem('plan')) == 'plan_1') || JSON.parse(localStorage.getItem('token')).access_token == 'guest'))
                            // this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
                            {
                                this.setState({
                                    popupSub: true
                                });
                                return
                            }

                            localStorage.setItem('tabId', this.props.match.params.content);
                            this.setState({
                                dataContent: response2.data.items[0],
                                showtitlealert: true
                            });
                            setTimeout(function () {
                                this.setState({
                                    showtitlealert: false
                                });
                            }.bind(this), 5000);


                            if (JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                                const url = backend_url + "/app/getFavoriteStatus";
                                let formData = new FormData();
                                formData.append('access_token', arr.access_token);
                                formData.append('profile_id', arr.UserData._id);
                                formData.append('contentID', response2.data.items[0]._id);
                                formData.append('lang', localStorage.getItem('lang'));


                                Axios.post(url,
                                    formData,
                                ).then(response2 => {
                                    // console.log(response2.data)
                                    if (response2.data.status == true) {
                                        this.setState({
                                            favSt: true
                                        })
                                    }
                                    else {
                                        this.setState({
                                            favSt: false
                                        })
                                    }


                                    // /app/history/start
                                    let formData = new FormData();
                                    let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));
                                    let profileID = '';
                                    profileID = mainProfile ? mainProfile._id : '';
                                    formData.append('profileId', profileID);
                                    formData.append('access_token', arr.access_token);
                                    formData.append('contantID', this.props.match.params.id);
                                    formData.append('typeOFContent', this.props.match.params.content);


                                    Axios.post(backend_url + "/app/history/start",
                                        formData,
                                    ).then(response2 => {
                                        if (response2.data.booleanValue) {

                                            let formData222 = new FormData();
                                            formData222.append('refId', response2.data.refId);
                                            formData222.append('access_token', arr.access_token);

                                            formData222.append('lang', localStorage.getItem('lang'));

                                            setTimeout(function () {
                                                Axios.post(backend_url + "/app/history/end",
                                                    formData222,
                                                ).then(response => {
                                                    // console.log(response)
                                                })
                                            }.bind(this), 5000);
                                        }
                                    });
                                });
                            }




                            ///////////////////////////////
                            const url2 = backend_url + "/app/getRelated";
                            let formData2 = new FormData();
                            formData2.append('profile_id', 'guest');

                            formData2.append('content_id', response2.data.items[0]._id);
                            formData2.append('content_type', this.props.match.params.content);
                            formData2.append('row_id', '');
                            if (this.props.match.params.content == 'shows') {
                                formData2.append('showID', this.state.dataContent.showId);
                            }

                            formData2.append('lang', localStorage.getItem('lang'));


                            Axios.post(url2,
                                formData2,
                            ).then(response22 => {
                                // console.log(this.state.dataContent._id)
                                let ah = [];
                                response22.data.items.map((item) => {
                                    if (this.state.dataContent._id != item._id) {
                                        ah.push(item)
                                    }
                                });
                                ah[0] = this.state.dataContent;
                                // console.log(ah)
                                this.setState({
                                    getRelated: ah,
                                });
                            })


                            if (JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                                let formDat2a = new FormData();
                                formDat2a.append('access_token', arr.access_token);

                                let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));
                                let profileID = '';
                                profileID = mainProfile ? mainProfile._id : '';

                                formDat2a.append('profileId', profileID);

                                formDat2a.append('lang', localStorage.getItem('lang'));

                                formDat2a.append('content_id', this.props.match.params.id);
                                Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getListPlayList",
                                    formDat2a,
                                ).then(response => {
                                    this.setState({ playListItems: response.data.items });
                                    //return; 
                                })

                            }

                        }
                        else {
                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');

                        }
                    })
                }
            })

        }
    }


    setFav() {
        // console.log(id)
        var arr = JSON.parse(localStorage.getItem('token'));
        // console.log(st)
        let backend_url2 = process.env.REACT_APP_BACKEND_URL;
        const url234 = backend_url2 + "/app/setFavorite";
        let formData333 = new FormData();
        formData333.append('access_token', arr.access_token);
        formData333.append('profile_id', arr.UserData._id);
        formData333.append('contentID', this.props.match.params.id);
        formData333.append('lang', localStorage.getItem('lang'));
        let stsent = !this.state.favSt ? 1 : 0;
        formData333.append('typeOFContent', this.props.match.params.content);
        formData333.append('favOrUnfav', stsent)

        Axios.post(url234,
            formData333,
        ).then(response32 => {
            this.setState({
                favSt: !this.state.favSt,
            })
        })

    }
    formatVideo = (seconds) => {
        const date = new Date(seconds * 1000)
        const hh = date.getUTCHours()
        const mm = date.getUTCMinutes()
        const ss = this.pad(date.getUTCSeconds())
        if (hh) {
            return `${hh}:${this.pad(mm)}:${ss}`
        }
        return `${mm}:${ss}`
    }

    pad = (string) => {
        return ('0' + string).slice(-2)
    }

    onProgress = ({ played, playedSeconds, loaded, duration }) => {
        let valPlay = played * 100;
        let valSeconds = this.formatVideo(playedSeconds);
        this.setState({
            playedSecondsBar: valPlay + '%',
            Seconds: valSeconds,
            played: played,
            sadasdsad: playedSeconds,
            load: false
        })
    }

    handleEnded = () => {
        // console.log('onEnded')
        let newIndex = this.state.indexshow < this.state.getRelated.length ? this.state.indexshow + 1 : this.state.getRelated.length;


        if (!this.state.getRelated[newIndex].isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3')) {
            this.setState({ dataContent: this.state.getRelated[newIndex], indexshow: newIndex });
            this.setState({
                showtitlealert: true
            });
            setTimeout(function () {
                this.setState({
                    showtitlealert: false
                });
            }.bind(this), 5000);
        }


    }


    handleDuration = (duration) => {
        this.setState({ duration: this.formatVideo(duration) })
    }
    exitHandler = () => {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {

            this.setState({ hidediv: false })
        }
    }
    handleClickFullscreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle(findDOMNode());
            this.setState({ hidediv: !this.state.hidediv })
        }
        // this.setState({ controls: !this.state.controls})
    }


    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }
    ref = player => {
        this.player = player
    }
    back10sec = e => {
        if (this.state.sadasdsad > 15)
            this.player.seekTo(parseFloat(this.state.sadasdsad - 5))

    }
    next10sec = e => {
        this.player.seekTo(parseFloat(this.state.sadasdsad + 15))
    }

    addtoPlaylist = (id) => {
        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('playListId', id);
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getContent",
            formData,
        ).then(response => {
            //props.contentId
            // console.log(this.props.match.params.content)
            let songsArr = [];
            let storiesArr = [];
            let showsArr = [];
            if (this.props.match.params.content == 'songs') {
                response.data.allarray[0].songslist.map((item) => {
                    songsArr.push(item._id)
                });
                response.data.allarray[1].showslist.map((item) => {
                    showsArr.push(item._id)
                });
                response.data.allarray[2].storieslist.map((item) => {
                    storiesArr.push(item._id)
                });

                songsArr.push(this.props.match.params.id)
            }
            else if (this.props.match.params.content == 'shows') {
                response.data.allarray[0].songslist.map((item) => {
                    songsArr.push(item._id)
                });
                response.data.allarray[1].showslist.map((item) => {
                    showsArr.push(item._id)
                });
                response.data.allarray[2].storieslist.map((item) => {
                    storiesArr.push(item._id)
                });
                showsArr.push(this.props.match.params.id)
            }
            else if (this.props.match.params.content == 'stories') {
                response.data.allarray[0].songslist.map((item) => {
                    songsArr.push(item._id)
                });
                response.data.allarray[1].showslist.map((item) => {
                    showsArr.push(item._id)
                });
                response.data.allarray[2].storieslist.map((item) => {
                    storiesArr.push(item._id)
                });
                storiesArr.push(this.props.match.params.id)
            }
            ////////////////////////////

            let formData2 = new FormData();
            formData2.append('access_token', arr.access_token);
            formData2.append('name', response.data.playListName);
            let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));
            let profileID = '';
            profileID = mainProfile ? mainProfile._id : '';
            formData2.append('profileId', profileID);
            formData2.append('songslist', JSON.stringify(songsArr));
            formData2.append('showslist', JSON.stringify(showsArr));
            formData2.append('storieslist', JSON.stringify(storiesArr));

            formData2.append('lang', localStorage.getItem('lang'));

            formData2.append('playlist_id', id);

            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/edit",
                formData2,
            ).then(response => {
                this.setState({
                    showPopup: true,
                    textPopup: response.data.msg,
                    showCratePlaylist: false,
                    showCratePlaylist2: false
                });

                setTimeout(function () {
                    this.setState({
                        showPopup: false,
                        textPopup: ''
                    });

                }.bind(this), 1000);
                if (JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                    let formDat2a = new FormData();
                    formDat2a.append('access_token', arr.access_token);

                    let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));
                    let profileID = '';
                    profileID = mainProfile ? mainProfile._id : '';

                    formDat2a.append('profileId', profileID);

                    formDat2a.append('lang', localStorage.getItem('lang'));

                    formDat2a.append('content_id', this.props.match.params.id);
                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getListPlayList",
                        formDat2a,
                    ).then(response => {
                        this.setState({ playListItems: response.data.items });
                        //return; 
                    })

                }
            })
            //return; 
        })
    };

    nextVideoFun = () => {
        let newIndex = this.state.indexshow < this.state.getRelated.length ? this.state.indexshow + 1 : this.state.getRelated.length;
        console.log(Base64.decode(localStorage.getItem('plan')))
        if (this.state.getRelated[newIndex].isPaid && (Base64.decode(localStorage.getItem('plan')) != 'plan_2' && Base64.decode(localStorage.getItem('plan')) != 'plan_3')) {
            this.setState({ indexshow: newIndex, popupSub: true })
        }
        else {
            this.setState({ dataContent: this.state.getRelated[newIndex], indexshow: newIndex });
            this.setState({
                showtitlealert: true
            });
            setTimeout(function () {
                this.setState({
                    showtitlealert: false
                });
            }.bind(this), 5000);
        }

    }

    backVideoFun = () => {
        let newIndex = this.state.indexshow - 1;
        if (this.state.getRelated[newIndex].isPaid && (Base64.decode(localStorage.getItem('plan')) != 'plan_2' && Base64.decode(localStorage.getItem('plan')) != 'plan_3')) {
            this.setState({ indexshow: newIndex, popupSub: true })
        }
        else {
            this.setState({ dataContent: this.state.getRelated[newIndex], indexshow: newIndex });

            this.setState({
                showtitlealert: true
            });
            setTimeout(function () {
                this.setState({
                    showtitlealert: false
                });
            }.bind(this), 5000);
        }

    }
    createNewPlaylist = () => {
        let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
        let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));

        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('name', this.state.NameNewPlayList);
        formData.append('profileId', mainProfile._id);
        formData.append(this.props.match.params.content + 'list', JSON.stringify([this.props.match.params.id]));

        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/create",
            formData,
        ).then(response => {
            if (response.data.booleanValue) {
                this.setState({
                    showPopup: true,
                    textPopup: response.data.msg,
                    showCratePlaylist: false,
                    showCratePlaylist2: false
                });

                setTimeout(function () {
                    this.setState({
                        showPopup: false,
                        textPopup: ''
                    });

                }.bind(this), 1000);
            }
        })


    }
    render() {
        const { items } = this.state;

        return (
            <div>
                <div className="popupSub" style={{ opacity: this.state.popupSub ? 1 : 0, visibility: this.state.popupSub ? 'visible' : 'hidden', transition: 'all 0.7s' }}>
                    <div className="colsebtn" onClick={() => this.setState({ popupSub: false })}>X</div>
                    <div class="boxPopup">

                        <img src={require("../assets/img/Lock icon.png")} />
                        <div className="textPopup">{this.state.lang['SubscribeNowPopup'].Upgrade}</div>
                        <div className="btns">
                            <a className="btn1"
                                onClick={() => {
                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
                                }}
                            >{this.state.lang['SubscribeNowPopup'].UpgradeBTN}</a>
                        </div>
                    </div>

                </div>
                <div style={{ position: 'fixed', top: '0', right: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(75,62,150,0.94)', zIndex: '99999', display: this.state.load ? 'block' : 'none', transform: localStorage.getItem('lang') == 'Ar' ? 'rotate(180deg)' : '' }}>
                    <svg width="914.227" height="233.452" viewBox="0 0 914.227 233.452" className="loadingSVG">
                        <defs>
                            <linearGradient id="linear-gradient" x1="0.5" y1="0.069" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <stop offset="0" stopColor="#a9de1e" />
                                <stop offset="0.429" stopColor="#8ad225" />
                                <stop offset="1" stopColor="#549f24" />
                            </linearGradient>
                            <clipPath id="clip-path">
                                <rect id="spacer" width={this.state.progressDataValue} height="78.223" rx="39.112" fill="url(#linear-gradient)" />
                            </clipPath>
                        </defs>
                        <g id="Group_5023" data-name="Group 5023" transform="translate(14352 1995.68)">
                            <g id="Group_5022" data-name="Group 5022" transform="translate(0 -577)">
                                <rect id="spacer-2" data-name="spacer" width="904.227" height="106.68" rx="53" transform="translate(-14352 -1418.68)" fill="#6456be" />
                                <rect id="spacer-3" data-name="spacer" width="870.964" height="78.223" rx="39" transform="translate(-14337.862 -1404.451)" fill="#3f2e8e" />
                            </g>
                            <g id="Group_5021" data-name="Group 5021" transform="translate(0 -577)">
                                <rect id="spacer-4" data-name="spacer" width={this.state.progressDataValue} height="77.985" rx="38.993" transform="translate(-14337.386 -1404.388)" fill="url(#linear-gradient)" />
                                <path id="Path_387" data-name="Path 387" d="M-1928.608,528.031c-3.757,0-26.736,2.868-27.7,23.665" transform="translate(-12364.298 -1917.021)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="10" opacity="0.5" />
                                <g id="Mask_Group_33" data-name="Mask Group 33" transform="translate(-14337.862 -1404.451)" opacity="0.293" clipPath="url(#clip-path)">
                                    <g id="Group_2224" data-name="Group 2224" transform="translate(-4.14 -14.229)" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                                        <path id="Path_2808" data-name="Path 2808" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6293.516 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2806" data-name="Path 2806" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6134.938 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2810" data-name="Path 2810" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6450.962 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2813" data-name="Path 2813" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6687.696 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2805" data-name="Path 2805" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6056.783 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2809" data-name="Path 2809" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6371.672 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2814" data-name="Path 2814" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6608.408 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2807" data-name="Path 2807" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6214.227 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2811" data-name="Path 2811" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6529.118 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2812" data-name="Path 2812" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(6765.853 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                        <path id="Path_2804" data-name="Path 2804" d="M-5871.05-1566.887l-106.443,106.68" transform="translate(5977.494 1566.887)" fill="none" stroke="#55a024" strokeWidth="17" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
                <Helmet>
                    {/* <title>{this.state.dataContent.titleEn + ' | Roya Kids'} </title> */}
                </Helmet>
                <section id="conentViewer" >
                    <div className='logoincontent'>

                        <img className="img pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} style={{ visibility: this.state.hidediv ? 'hidden' : 'visible' }} />
                        <img 
                            className="pointer"
                            onClick={() => { this.props.history.goBack() }} 
                            src={require("../assets/img/Group 4877.png")} 
                        />
                    </div>

                    <UnderPopup show={this.state.showPopup} text={this.state.textPopup} />
                    <div>
                        {localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' && (
                            <section id="playlist" style={{ visibility: this.state.showCratePlaylist ? 'visible' : 'hidden', opacity: this.state.showCratePlaylist ? '1' : '0', transition: 'opacity 2.5' }}>
                                <div className="colseBtn"
                                    onClick={() => this.setState({ showCratePlaylist: !this.state.showCratePlaylist })}
                                >X</div>
                                <div className="boxPopup">

                                    <div className="contentPopup">
                                        <div className="titlecontentPopup">{this.state.lang['AddToPlaylistPopup'].title}</div>

                                    </div>
                                    <div className="descriptionPopup">
                                        <div className="descriptionPopup">
                                            <div className="btn" onClick={() => {
                                                this.setState({ showCratePlaylist2: true })
                                            }}>{this.state.lang['CreatePlaylistNamePopup'].title}</div>

                                            <div className="list">
                                                {
                                                    this.state.playListItems.length != 0 ?
                                                        this.state.playListItems.map((item, index) => {
                                                            if (!item.addedBefore) {
                                                                return <div className="itemList" key={index} onClick={() => {
                                                                    this.addtoPlaylist(item._id)
                                                                }}>
                                                                    <div className="imgsList">
                                                                        {item.songsImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.songsImg[0]} /> : ''}
                                                                        {item.songsImg[1] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.songsImg[1]} /> : ''}
                                                                        {item.showsImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.showsImg[0]} /> : ''}
                                                                        {item.storiesImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.storiesImg[0]} /> : ''}

                                                                    </div>

                                                                    <div className="nameplaylist">{item.namePlayList}</div>

                                                                    <div className="addtoplaylist"></div>
                                                                </div>
                                                            }

                                                        })
                                                        : ''
                                                }

                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </section>
                        ) : ''}


                        {localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' && (
                            <section id="CreatePlaylist" style={{ visibility: this.state.showCratePlaylist2 ? 'visible' : 'hidden', opacity: this.state.showCratePlaylist2 ? '1' : '0', transition: 'opacity 2.5' }} >
                                <div className="colseBtn"
                                    onClick={() => {
                                        this.setState({ showCratePlaylist2: !this.state.showCratePlaylist2 })
                                    }}
                                >X</div>
                                <div className="boxPopup">

                                    <div className="contentPopup">
                                        <div className="titlecontentPopup">{this.state.lang['CreatePlaylistNamePopup'].title}</div>

                                    </div>
                                    <div className="descriptionPopup">
                                        <div className="descriptionPopup">
                                            <div className="input">
                                                <input placeholder={this.state.lang['CreatePlaylistNamePopup'].inputTxtLabel}
                                                    onChange={(e) => {
                                                        this.setState({ NameNewPlayList: e.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="btn"
                                                onClick={() => {
                                                    this.createNewPlaylist()
                                                }}
                                            >{this.state.lang['CreatePlaylistNamePopup'].ButtonCreateLabel}</div>
                                        </div>
                                    </div>


                                </div>


                            </section>

                        ) : ''}

                    </div>

                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>

                    <div className="bodyHome">

                        {/* {this.state.dataContent.fileSrc} */}
                        {/* url='https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8' */}
                        <div className="titleContentalert" style={{ visibility: this.state.showtitlealert ? 'visible' : 'none', opacity: this.state.showtitlealert ? '1' : '0', transition: 'opacity 1s' }}>
                            {this.state.dataContent.title}
                        </div>
                        <div className={this.state.hidediv ? 'player-wrapper-full' : 'player-wrapper'}>
                            <ReactPlayer
                                url={{
                                    url: this.state.dataContent.fileSrc,
                                    played: 0,
                                    loaded: 0,
                                    pip: false
                                }}
                                ref={this.ref}
                                className='react-player'
                                url={this.state.dataContent.fileSrc}
                                style={{
                                    // minWidth: '100vw', 
                                    minHeight: this.state.hidediv ? '100vh' : '',
                                    top: this.state.hidediv ? '0' : '10%'

                                }}
                                width='100%'
                                height='70%'
                                playing={this.state.play}
                                onProgress={this.onProgress}
                                onDuration={this.handleDuration}
                                loop={this.state.loop}
                                muted={!this.state.sound}
                                onSeek={e => console.log('onSeek', e)}
                                onEnded={this.handleEnded}
                                controls={this.state.controls}
                            />

                            <div className="poseBtnHide" onClick={() => {
                                this.setState({ play: !this.state.play })
                            }}
                                onDoubleClick={() =>
                                    this.handleClickFullscreen()
                                }
                            ></div>
                            <div className="btnbar">
                                <svg className="svg" width="60" height="60" viewBox="0 0 60 60" style={{ display: this.state.play ? 'none' : 'block' }}
                                    onClick={() => {
                                        this.setState({ play: !this.state.play })
                                    }}
                                >
                                    <g id="Group_4987" data-name="Group 4987" transform="translate(-397 -733)">
                                        <circle id="Ellipse_420" data-name="Ellipse 420" cx="30" cy="30" r="30" transform="translate(397 733)" fill="#65daa3" />
                                        <g id="Group_1431" data-name="Group 1431" transform="translate(417.709 749.153)">
                                            <path id="Path_183" data-name="Path 183" d="M-841.785,118.538l13.55-7.975-13.55-7.975Z" transform="translate(847.108 -97.266)" fill="#fff" />
                                            <path id="Path_184" data-name="Path 184" d="M-858.671,106.974a5.318,5.318,0,0,1-2.642-.7,5.322,5.322,0,0,1-2.68-4.62V85.7a5.322,5.322,0,0,1,2.68-4.62,5.323,5.323,0,0,1,5.342.033l13.55,7.975a5.323,5.323,0,0,1,2.623,4.587,5.322,5.322,0,0,1-2.623,4.587l-13.55,7.975A5.318,5.318,0,0,1-858.671,106.974Z" transform="translate(863.994 -80.38)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                                <svg className="svg" width="60" height="60" viewBox="0 0 60 60" style={{ display: this.state.play ? 'block' : 'none' }}
                                    onClick={() => {
                                        this.setState({ play: !this.state.play })
                                    }}
                                >
                                    <g id="Group_4986" data-name="Group 4986" transform="translate(-395 -733)">
                                        <circle id="Ellipse_420" data-name="Ellipse 420" cx="30" cy="30" r="30" transform="translate(395 733)" fill="#65daa3" />
                                        <g id="Group_1421" data-name="Group 1421" transform="translate(414.767 753.333)">
                                            <rect id="Rectangle_242" data-name="Rectangle 242" width="6" height="21" rx="2" transform="translate(0.233 -0.333)" fill="#fff" />
                                            <rect id="Rectangle_243" data-name="Rectangle 243" width="7" height="21" rx="2" transform="translate(14.233 -0.333)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                                <input
                                    className="bar"
                                    type='range' min={0} max={0.999999} step='any'
                                    value={this.state.played}
                                    onMouseDown={this.handleSeekMouseDown}
                                    onChange={this.handleSeekChange}
                                    onMouseUp={this.handleSeekMouseUp}
                                />
                                {/* <div className="bar">

                                    <div className="activeBar" style={{ width: this.state.playedSecondsBar }}
                                        onMouseDown={this.handleSeekMouseDown}
                                        onChange={this.handleSeekChange}
                                        onMouseUp={this.handleSeekMouseUp}
                                    ></div>
                                </div> */}
                                <div className="Seconds">
                                    <div className="Seconds">{this.state.Seconds} / {this.state.duration}</div>
                                </div>
                                <VolumeUpIcon style={{ color: '#fff', padding: '0 0.5vw', fontSize: '1.7vw', display: this.state.sound ? 'block' : 'none', cursor: 'pointer', zIndex: '999', position: 'relative' }}
                                    onClick={() => this.setState({ sound: !this.state.sound })}
                                />
                                <VolumeOffIcon style={{ color: '#fff', padding: '0 0.5vw', fontSize: '1.7vw', display: this.state.sound ? 'none' : 'block', cursor: 'pointer', zIndex: '999', position: 'relative' }}
                                    onClick={() => this.setState({ sound: !this.state.sound })}
                                />

                                <div className="fullSec">
                                    <svg width="26.693" height="20.72" viewBox="0 0 26.693 20.72" onClick={this.handleClickFullscreen}>
                                        <g id="Group_1433" data-name="Group 1433" transform="translate(375.369 4916.43)">
                                            <path id="Path_2393" data-name="Path 2393" d="M-357.869-4914.93h4.422c2.727,0,3.271,1.772,3.271,3.385v2.335" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                            <path id="Path_2396" data-name="Path 2396" d="M-357.869-4909.21h4.422c2.727,0,3.271-1.772,3.271-3.385v-2.335" transform="translate(0 12)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                            <path id="Path_2394" data-name="Path 2394" d="M-350.176-4914.93H-354.6c-2.727,0-3.271,1.772-3.271,3.385v2.335" transform="translate(-16)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                            <path id="Path_2395" data-name="Path 2395" d="M-350.176-4909.21H-354.6c-2.727,0-3.271-1.772-3.271-3.385v-2.335" transform="translate(-16 12)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                        </g>
                                    </svg>

                                </div>
                            </div>
                        </div>
                        {/* <ReactHlsPlayer
                            url={this.state.dataContent.fileSrc}
                            autoplay={true}
                            controls={true}
                            className="videoShow"
                            ref={el => this.state.player = el}
                            preload='auto'
                        /> */}


                        <div className="controlVideo" style={{ visibility: this.state.hidediv ? 'hidden' : 'visible' }}>
                            <img id="homepage" src={require("../assets/img/Path 2390.png")}
                                onClick={() => {
                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
                                }} />

                            {localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' && (
                                <img id="fav" src={this.state.favSt ? require("../assets/img/Polygon 110.png") : require("../assets/img/Polygon 10.png")}
                                    onClick={() => {
                                        this.setFav();
                                    }}
                                />
                            ) : ''}
                            {localStorage.getItem('token') != null ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' && (
                                <img id="playlistBtn" src={require("../assets/img/Group 1520.png")}
                                    onClick={() => {
                                        this.setState({
                                            showCratePlaylist: true
                                        })
                                    }}
                                />
                            ) : ''}

                            <img id="backVideo" src={this.state.indexshow == 0 ? require("../assets/img/Group 141311.png") : require("../assets/img/Group 1413.png")}
                                onClick={
                                    this.state.indexshow == 0 ? '' :
                                        () => {
                                            this.backVideoFun()
                                        }
                                }
                            />
                            <img id="backVideo" src={require("../assets/img/Group 1420.png")}
                                onClick={() => {
                                    this.back10sec();
                                }}
                            />

                            <img id="playandpr" src={this.state.play ? require("../assets/img/Group 1421.png") : require("../assets/img/Path 17.png")}
                                onClick={() => {
                                    this.setState({ play: !this.state.play })
                                }}
                            />
                            <img id="nextVideo" src={require("../assets/img/Group 1419.png")} onClick={() => {
                                this.next10sec();
                            }} />
                            <img id="nextVideo" src={this.state.getRelated.length <= (this.state.indexshow + 1) ? require("../assets/img/Group 1412111.png") : require("../assets/img/Group 1412.png")} onClick={
                                this.state.getRelated.length <= (this.state.indexshow + 1) ?
                                    '' :
                                    () => {
                                        this.nextVideoFun();
                                    }

                            } />
                            <img id="repetition" src={this.state.loop ? require("../assets/img/Group 1418.png") : require("../assets/img/Group 4986.png")}
                                onClick={() => {
                                    this.setState({ loop: !this.state.loop })
                                }}
                            />
                        </div>
                        <div className="realtedVideo" style={{ display: this.state.hidediv ? 'none' : 'block' }}>



                            <div className="sliderSec2" id={'item.title'}>
                                <div className="slider">
                                    <Carousel itemsToShow={7} pagination={false}
                                        isRTL={localStorage.getItem('lang') == 'Ar' ? true : false}>

                                        {this.state.getRelated.map((video, index) => {
                                            if (index == this.state.indexshow) {
                                                return <TransitionGroup key={index}>
                                                    <CSSTransition
                                                        key={video._id}
                                                        timeout={1000}
                                                        classNames="messageout"
                                                    >

                                                        <div key={video._id} className="item itemActive " >
                                                            <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                        </div>

                                                    </CSSTransition>
                                                </TransitionGroup>

                                            }
                                            else {
                                                return <TransitionGroup key={index}>
                                                    <CSSTransition
                                                        key={video._id}
                                                        timeout={1000}
                                                        classNames="messageout"
                                                    >

                                                        <div key={video._id} className="item" onClick={() => {
                                                            if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))
                                                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'content/' + this.props.match.params.content + '/' + video._id);
                                                            else
                                                                this.setState({ popupSub: true })
                                                        }}>
                                                            <div className="lockIcon" style={{ display: !video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3') ? 'none' : '' }}></div>
                                                            <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                        </div>

                                                    </CSSTransition>
                                                </TransitionGroup>
                                            }
                                        }

                                        )}
                                    </Carousel>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </div >
        );
    }
}

export default Player;