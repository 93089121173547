import React, { useEffect } from "react";
import Footer from "./pageSec/footer";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FadeIn from "react-fade-in";
import { useHistory, useParams } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import PhoneInput from "react-phone-input-2";
import axios from "axios";

let lang1 = require("../lang/en").default;
const Ksa = () => {
  const { operator } = useParams();
  const [load, setLoad] = React.useState(true);
  const [iconPage, setIconPage] = React.useState(
    require("../assets/img/Group 1360.png")
  );
  const [step, setStep] = React.useState(1);
  const history = useHistory();
  const [showAlert, setShowAlert] = React.useState([]);
  const [planInfo, setPlanInfo] = React.useState(null);
  const [phone, setPhone] = React.useState("");
  const [code, setCode] = React.useState("");
  const [data, setData] = React.useState(null);
  
  const getPlanInfo = async () => {  
    const data = new FormData();
    data.append("os", "web");
    data.append("lang", localStorage.getItem("lang").toLowerCase());
    var config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "/app/subscriptionMethod",
      data: data
    };
    const response = await axios(config);
    const purchaseTypeId = localStorage.getItem("subscription") === "plan_3,annualy" ? "10" : "4";
    const operator = localStorage.getItem("op");
    const result = response.data.methods.filter(
      (item) =>
        item.Operator === operator && item.PurchaseTypeId === purchaseTypeId
    );
    setPlanInfo(result[0]);
  };

  useEffect(() => {
    getPlanInfo();
  }, []);

  const getPincode = async () => {
    const userId = JSON.parse(localStorage.getItem("Profiles")).newarray[0]._id;
    const data = new FormData();
    data.append("userId", userId);
    data.append("msisdn", phone);
    data.append("operator", operator);
    data.append("pinType", 1);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/ksaSubscription/sendPin`,
      data
    };
    try {
      const response = await axios(config);
      if(response.data.error) {
        setShowAlert(prevState => [...prevState, response.data.message])
      } else if (response.status === 200) {
        setStep(2);
        setShowAlert([]);
      }
    } catch (err) {
      showAlert((prevState => [...prevState, err.message]))
    }
  };

  const subscribeWithPin = async () => {
    const data = new FormData();
    data.append("msisdn", phone);
    data.append("operator", operator);
    data.append("userId", JSON.parse(localStorage.getItem("Profiles")).newarray[0]._id);
    data.append("byClient", "KSA");
    data.append("pincode", code);
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/ksaSubscription/subscribePin`,
      data,
    };
    const response = await axios(config);
    if (response.status === 200 && response.data.status === 1) {
      setData(
        <div style={{ fontSize: "25px", marginTop: "20px", color: "green" }}>
            {lang1["ResetPasswordScreen"].succ}
          <br />
          <div className="btns" style={{ marginTop: "10px", zIndex: "99999999" }}>
            <button
              className="btn1"
              onClick={() => {
                history.push("/");
              }}
            >
              <span>{response.data.message}</span>
            </button>
          </div>
        </div>
      );
    } else {
      setShowAlert((prevState) => [...prevState, lang1["AlertMessages"].onInvalidCode])
    }
  };

  const handleSubmit = async () => {
    setShowAlert([]);
    if (phone.length !== 12) {
      setShowAlert((prevState) => [ ...prevState, lang1["AlertMessages"].onInvalidMobileNumber ]);
    } else if(step === 2 && (code.length !== 4 || code.length !== 6)) {
      setShowAlert((prevState) => [ ...prevState, lang1["AlertMessages"].onInvalidCode ]);  
    }
    if (step === 1 && phone.length === 12) {
      await getPincode();
    } else if(step === 2 && (code.length === 4 || code.length === 6)) {
      await subscribeWithPin();
    }
  };
  return (
    <div>
      <Backdrop className="backdrop" open={!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <section id="createAcount" style={{ display: load ? "block" : "none" }}>
        <section id="footer">
          <div className="stage">
            <img
              alt=""
              id="cloud1"
              src={require("../assets/img/Path 2346.png")}
            />
            <img
              alt=""
              id="cloud2"
              src={require("../assets/img/Path 2337.png")}
            />
            <img
              alt=""
              id="backbtn"
              onClick={() => {
                history.goBack();
              }}
              src={require("../assets/img/Group 4877.png")}
            />
          </div>
          <FadeIn>
            <div className={"title fadeInAnmthionActive"}>
              <div className="img">
                <img alt="" src={iconPage} />
              </div>

              <div
                className="textTitle"
                style={{ display: data !== null ? "none" : "block" }}
              >
                {step === 1
                  ? lang1["RegisterationPhoneNumberVerificationScreen"]
                      .enterPhone
                  : lang1["RegisterationPhoneNumberVerificationScreen"].title}
              </div>
              {data}
              <div
                style={{
                  display: data !== null ? "none" : "block",
                }}
              >
                <div
                  className="errorMsg"
                  style={{
                    opacity: showAlert.length != 0 ? "1" : "0",
                    visibility: showAlert.length != 0 ? "visible" : "hidden",
                    transition: "all 1s",
                    height: showAlert.length != 0 ? "auto" : "0",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="33"
                    viewBox="0 0 29 33"
                  >
                    <g
                      id="Group_4992"
                      data-name="Group 4992"
                      transform="translate(-13 -9.802)"
                    >
                      <g
                        id="Group_1332"
                        data-name="Group 1332"
                        transform="translate(13.213 12.27)"
                      >
                        <circle
                          id="Ellipse_386"
                          data-name="Ellipse 386"
                          cx="14.5"
                          cy="14.5"
                          r="14.5"
                          transform="translate(-0.213 -0.468)"
                          fill="#fff"
                        />
                      </g>
                      <text
                        id="_"
                        data-name="!"
                        transform="translate(30, 36.802)"
                        fill="#fd7777"
                        fontSize="25"
                        x="50%"
                        y="50%"
                        fontFamily="SegoeUI, Segoe UI"
                        textAnchor="middle"
                      >
                        <tspan x="-2" y="0">
                          !
                        </tspan>
                      </text>
                    </g>
                  </svg>

                  <div style={{ margin: "1vw", padding: "0" }}>
                    {showAlert.map((item, index) => {
                      return <div key={index}>{item}</div>;
                    })}
                  </div>
                </div>
                {step === 1 && (
                  <>
                    <div className="inputPhone">
                      <PhoneInput
                        country={"sa"}
                        enableSearch={true}
                        disableSearchIcon={true}
                        inputProps={{
                          name: "phone",
                          required: true,
                          disableSearchIcon: false.valueOf,
                        }}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </div>
                    <div className="planInfo">
                      {planInfo?.planDetails?.price}
                    </div>
                    <div className="planInfo">
                      {planInfo?.planDetails?.details}
                    </div>
                    <div className="planInfo" style={{ marginTop: "50px" }}>
                      {planInfo?.planDetails?.unsubscribe}
                    </div>
                  </>
                )}
                <div className="inputPhoneV">
                  <div
                    className={
                      true
                        ? "title fadeInAnmthionActive"
                        : "title fadeInAnmthion"
                    }
                  >
                    {step === 2 && (
                      <div className="inputPhoneV">
                        <ReactCodeInput
                          fields={operator === "3206" ? 6 : 4}
                          onChange={(vals) => {
                            setCode(vals);
                          }}
                          autoFocus={true}
                        />
                      </div>
                    )}
                    <div className="btns">
                      <button className="btn1" onClick={(e) => handleSubmit()}>
                        {
                          lang1["RegisterationPhoneNumberVerificationScreen"]
                            .buttonNextLabel
                        }
                      </button>
                    </div>
                    <div id="recaptcha-container"></div>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        </section>
      </section>
      <Footer />
    </div>
  );
};
export default Ksa;
