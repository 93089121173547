import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Base64 } from 'js-base64';

import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}


class HomeScreen extends Component {
    state = {
        items: [],
        type: 'shows',
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        favSt: '',
        items2: [],
        lang1: lang1,
        popupSub:false
    }

    componentDidMount() {
        localStorage.setItem('tabId', 'shows');
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined && arr.access_token != 'guest') {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {
                    let backend_url = process.env.REACT_APP_BACKEND_URL;
                    const url = backend_url + "/app/getSeasons";
                    let formData = new FormData();
                    if (arr.access_token != 'guest')
                        formData.append('access_token', arr.access_token);
                    else {
                        formData.append('profile_id', 'guest');

                        formData.append('access_token', 'guest');
                    }

                    formData.append('show_id', this.props.match.params.id);

                    formData.append('lang', localStorage.getItem('lang'));


                    Axios.post(url,
                        formData,
                    ).then(response2 => {
                        console.log(response2)
                        this.setState({
                            items: response2.data,
                            items2: response2.data.items
                        })
                    })
                }
            })

        }
    }

    render() {
        return (
            <div>
                <section id="season" >
                <div className="popupSub" style={{ opacity: this.state.popupSub ? 1 : 0, visibility: this.state.popupSub ? 'visible' : 'hidden', transition: 'all 0.7s' }}>
                        <div className="colsebtn" onClick={() => this.setState({ popupSub: false })}>X</div>
                        <div className="boxPopup">
                        
                            <img src={require("../assets/img/Lock icon.png")} />
                            <div className="textPopup">{this.state.lang1['SubscribeNowPopup'].Upgrade}</div>
                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
                                    }}
                                >{this.state.lang1['SubscribeNowPopup'].UpgradeBTN}</a>
                            </div>
                        </div>

                    </div>
                    <div className="header">
                        <img className="img pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />
                        <div className="titleSeason"> {this.props.match.params.title}</div>
                        <img id="backbtn" onClick={() => {
                            this.props.history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} />
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    <InfoConent show={{ showPopup: this.state.showPopup, data: this.state.popupshow, tags: this.state.tags, type: this.state.type }} />
                    <div className="bodyHome">
                        <div className="coverSeason">
                            <img src={this.state.items.backendurl + this.state.items.coverImg ? this.state.items.backendurl + this.state.items.coverImg : ''} />
                        </div>
                        <div className="countitem">{this.state.items2.length} {this.state.lang1['Shared'].Seasons}</div>
                        <div className="seasons">

                            {this.state.items2.map((video, index) =>
                                <div key={index}>
                                    <div key={video._id} className="item">

                                        <img id="info" src={require("../assets/img/Group 4983.png")}
                                            onClick={(() => {
                                                this.setState({
                                                    popupshow: video,
                                                    tags: [],
                                                    showPopup2: false,
                                                    showPopup: true
                                                })
                                            })}
                                        />
                                        <div className="lockIcon" style={{ display: !video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3') ? 'none' : '' }}></div>
                                        <div className="gotocontent"
                                            onClick={() => {
                                                if (!video.isPaid || (Base64.decode(localStorage.getItem('plan')) == 'plan_2' || Base64.decode(localStorage.getItem('plan')) == 'plan_3'))
                                                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'episode/' + video._id + '/' + video.title + '/' + this.props.match.params.title);
                                                else
                                                    this.setState({
                                                        popupSub: true,
                                                        showPopup2: false,
                                                        showPopup: false
                                                    })
                                            }}
                                        ></div>
                                        <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>

                                    </div>
                                    <div className="lableSeason">{video.title}</div>
                                </div>





                            )

                            }
                        </div>


                    </div>
                </section>


            </div>
        );
    }
}

export default HomeScreen;