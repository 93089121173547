import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';


import RouterPublic from './RouterPublic';
import MobileView from '../src/Component/pageSec/MobileView';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-194586256-1');
ReactGA.pageview(window.location.pathname + window.location.search);
// import './App.scss'; 

// import './AppAr.scss';


class App extends Component {

  componentDidMount() {
    console.log(window.location.pathname.split('/')[1]);
    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', 'Ar');
      const Arabic = import('./AppAr.scss');
    }
    else {
      if (localStorage.getItem('lang') === 'En') {
        const English = import('./App.scss');
      }
      else {
        const Arabic = import('./AppAr.scss');
      }
    }
  }

  render() {
    if (isMobile && window.location.pathname.split('/')[1] != "mondia") {
      return <MobileView />
    }
    return (
      <div className="App">
        <RouterPublic />
      </div>
    );
  }
}
export default App;
