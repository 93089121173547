import React, { Fragment, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";


let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '' ) {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}

const Parents = () => {
    const alert = useAlert();
    let items = [];
    let num1 = getRandomInt();
    let num2 = getRandomInt(); 
  const [ans, setAns] = React.useState('');
    const [state, setState] = useState({ num11: getRandomInt(), num22: getRandomInt() });

    useEffect(() => {
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined && JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {
                    // let backend_url = process.env.REACT_APP_BACKEND_URL;
                    // const url = backend_url + "/app/listFavorite";
                    // let formData = new FormData();
                    // formData.append('access_token', arr.access_token);
                    // formData.append('typeOFContent', this.state.type);

                    // formData.append('profile_id', arr.UserData._id);
                    // formData.append('lang', 'En');




                    // Axios.post(url,
                    //     formData,
                    // ).then(response2 => {
                    //     items = response2.data.allarray;


                    // })
                }
            })

        }
    }, [setAns]);
    function getRandomInt() {
        let min = Math.ceil(1);
        let max = Math.floor(20);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    function handleSubmit() {
        if (ans == '') {
            alert.show(lang1['ParentSectionPopupScreen'].warningInsertCode);
        }
        else if (state.num11 + state.num22 != ans) {
            alert.error(lang1['ParentSectionPopupScreen'].alertWrongPasscode);
            setAns('')
            setState({ num11: getRandomInt(), num22: getRandomInt() })
        }
        else {
            history.push(process.env.REACT_APP_FRONT_URL + 'settings');
        }
    }
    function handleChange(e) {
        setAns(e.target.value)
    };
    let history = useHistory();


    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    }

    return (
        <div>
            <section id="Parents" >

                <div className="header">
                    <div className="leftSec">
                        <img id="backbtn" style={{ cursor: 'pointer' }} onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} />
                    </div>


                </div>
                <div className="stage">

                    <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                    <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                </div>
                <div className="bodyHome" >

                    <div className="title">
                        <div className="img">
                            <img src={require("../assets/img/Group 2167.png")} />
                        </div>
                        <div className="textTitle">
                            {lang1['ParentSectionPopupScreen'].title}
                            </div>
                        <div className="textTitle2">{lang1['ParentSectionPopupScreen'].hint}</div>
                        <div className="input">
                            <div className="qus">
                                {state.num11} + {state.num22} =
                                </div>
                            <input type="number"
                                value={ans}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                onKeyPress={handleKeyPress}
                            />
                        </div>


                        <div className="btns">
                            <a className="btn1"
                                onClick={() => {
                                    handleSubmit()
                                }}>{lang1['ParentSectionPopupScreen'].buttonNextLabel}</a>
                        </div>

                    </div>
                </div>
            </section>

            {/* <Fragment>
                <button
                    onClick={() => {
                        alert.show("Oh look, an alert!");
                    }}
                >
                    Show Alert
      </button>
                <button
                    onClick={() => {
                        alert.error("You just broke something!");
                    }}
                >
                    Oops, an error
      </button>
                <button
                    onClick={() => {
                        alert.success("It's ok now!");
                    }}
                >
                    Success!
      </button>
            </Fragment> */}
        </div>

    );
};

export default Parents;
