

import React, { useEffect } from 'react';
import { render } from "react-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Apps from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
const options = {
  timeout: 2000,
  position: positions.BOTTOM_CENTER,
};




const App = () => (
  <Provider template={AlertTemplate} {...options}>
    <Apps />
  </Provider>
);


render(
  <App />, document.getElementById("root"));
serviceWorker.unregister();