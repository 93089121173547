import React, { useEffect } from 'react'

import Axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'

import 'react-phone-input-2/lib/style.css'
import { useAlert } from 'react-alert'
import FadeIn from 'react-fade-in'
import { Base64 } from 'js-base64'
import Footer from './pageSec/footer'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { PayPalButton } from 'react-paypal-button-v2'
import ReactCodeInput from 'react-verification-code-input'
import PhoneInput from 'react-phone-input-2'

let lang1 = require('../lang/en').default
if (
  localStorage.getItem('lang') === undefined ||
  localStorage.getItem('lang') === null ||
  localStorage.getItem('lang') === ''
) {
  localStorage.setItem('lang', 'En')
  lang1 = require('../lang/en').default
} else {
  if (localStorage.getItem('lang') === 'En') {
    lang1 = require('../lang/en').default
  } else {
    lang1 = require('../lang/ar').default
  }
}
export default function Checkout () {
  const { id } = useParams()
  const [data, setData] = React.useState(null)
  const [load, setLoad] = React.useState(true)
  const [user, setUser] = React.useState({})
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()
  const alert = useAlert()
  const [iconPage, setIconPage] = React.useState(
    require('../assets/img/Group 1360.png')
  )
  const [errorMsg, setErrorMsg] = React.useState(false)
  const [msgError, setMsgError] = React.useState(
    'The verification code you entered is incorrect'
  )
  const [verificationType, setVerificationType] = React.useState('phone')
  const [verifyyour, setVerifyyour] = React.useState(
    lang1['RegisterationPhoneNumberVerificationScreen'].title
  )
  const [phone, setPhone] = React.useState('')

  const [step, setStep] = React.useState(1)
  const [code, setCode] = React.useState('')
  const [pincodeID, setPincodeID] = React.useState('')

  const [showAlert, setshowAlert] = React.useState([])
  const [planInfo, setPlanInfo] = React.useState(null)

  useEffect(() => {
    //{{Royakids}}app/subscriptionMethod

    var data = new FormData()
    data.append('os', 'web')
    data.append('lang', localStorage.getItem('lang').toLowerCase())

    var config = {
      method: 'post',
      url: process.env.REACT_APP_BACKEND_URL + '/app/subscriptionMethod',
      data: data
    }

    Axios(config).then(feed => {
      ///subscription
      let PurchaseTypeId =
        localStorage.getItem('subscription') === 'plan_3,annualy' ? '10' : '4'
      let Operator = localStorage.getItem('op')
      let FainalData = feed.data.methods.filter(
        item =>
          item.Operator === Operator && item.PurchaseTypeId === PurchaseTypeId
      )
      setPlanInfo(FainalData[0])
    })
  }, [])

  const handleSubmitEnd = () => {
    setshowAlert([])
    let formIsValid = true
    var arr = JSON.parse(localStorage.getItem('token'))

    if (step === 1) {
      if (phone.length !== 12) {
        setshowAlert(oldArray => [
          ...oldArray,
          lang1['AlertMessages'].onInvalidMobileNumber
        ])
        formIsValid = false
      }
      if (formIsValid) {
        //970521312312
        let phoneTemp = phone.replace('+', '')
        phoneTemp = phoneTemp.replace('970', '0')
        var data = new FormData()
        data.append('token', id)
        data.append('MerchantID', '59')
        data.append(
          'ServiceID',
          localStorage.getItem('subscription') === 'plan_3,annualy'
            ? '263'
            : '260'
        ) // 263
        data.append(
          'PurchaseTypeId',
          localStorage.getItem('subscription') === 'plan_3,annualy' ? '10' : '4'
        ) // 10
        data.append('phone', phoneTemp)
        data.append('TransactionChannel', 'HE')
        data.append('Subscription', 'ZXW_31')
        data.append('Operator', localStorage.getItem('op'))
        data.append('lang', localStorage.getItem('lang') === 'En' ? 'en' : 'ar')

        var config = {
          method: 'post',
          url: 'https://royakids.tv:4000/app/palestine/pincode',
          data: data,
          headers: {
            token: id,
            access_token: arr.access_token
          },
        }

        Axios(config)
          .then(function (response) {
            setPincodeID(response.data.pincodeID)
            setStep(2)
          })
          .catch(function (error) {
            setshowAlert(oldArray => [...oldArray, error.response.data.message])
          })
      }
    } else {
      // localStorage.getItem('subscription') === 'plan_3,annualy' ? '263' : '260'
      let idSub =
        localStorage.getItem('subscription') === 'plan_3,annualy'
          ? '263'
          : '260'
      let langSend = localStorage.getItem('lang') === 'En' ? 'en' : 'ar'
      var config = {
        method: 'put',
        url: `https://royakids.tv:4000/app/palestine/confirmation?code=${code}&id=${pincodeID}&lang=${langSend}&idSub=${idSub}`,
        headers: {
          token: id,
          access_token: arr.access_token
        },
        data: data
      }

      Axios(config)
        .then(function (response) {
          let partners = (
            <div
              style={{
                fontSize: '25px',
                marginTop: '20px',
                color: 'green'
              }}
            >
              {lang1['ResetPasswordScreen'].succ}

              <br />
              <div
                className='btns'
                style={{ marginTop: '10px', zIndex: '99999999' }}
              >
                <a
                  className='btn1'
                  onClick={() => {
                    history.push('/')
                  }}
                >
                  <span>{response.data.message}</span>
                </a>
              </div>
            </div>
          )
          setData(partners)
        })
        .catch(function (error) {
          setshowAlert(oldArray => [...oldArray, error.response.data.message])
        })
    }
  }

  return (
    <div>
      <Backdrop className='backdrop' open={!load}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <section id='createAcount' style={{ display: load ? 'block' : 'none' }}>
        <section id='footer'>
          <div className='stage'>
            <img id='cloud1' src={require('../assets/img/Path 2346.png')} />

            <img id='cloud2' src={require('../assets/img/Path 2337.png')} />
            <img
              id='backbtn'
              onClick={() => {
                history.goBack()
              }}
              src={require('../assets/img/Group 4877.png')}
            />
          </div>
          <FadeIn>
            <div className={'title fadeInAnmthionActive'}>
              <div className='img'>
                <img src={iconPage} />
              </div>

              <div
                className='textTitle'
                style={{
                  display: data !== null ? 'none' : 'block'
                }}
              >
                {step === 1
                  ? lang1['RegisterationPhoneNumberVerificationScreen']
                      .enterPhone
                  : lang1['RegisterationPhoneNumberVerificationScreen'].title}
              </div>

              {data}

              <div
                style={{
                  display: data !== null ? 'none' : 'block'
                }}
              >
                <div
                  className='errorMsg'
                  style={{
                    opacity: showAlert.length != 0 ? '1' : '0',
                    visibility: showAlert.length != 0 ? 'visible' : 'hidden',
                    transition: 'all 1s',
                    height: showAlert.length != 0 ? 'auto' : '0'
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='29'
                    height='33'
                    viewBox='0 0 29 33'
                  >
                    <g
                      id='Group_4992'
                      data-name='Group 4992'
                      transform='translate(-13 -9.802)'
                    >
                      <g
                        id='Group_1332'
                        data-name='Group 1332'
                        transform='translate(13.213 12.27)'
                      >
                        <circle
                          id='Ellipse_386'
                          data-name='Ellipse 386'
                          cx='14.5'
                          cy='14.5'
                          r='14.5'
                          transform='translate(-0.213 -0.468)'
                          fill='#fff'
                        />
                      </g>
                      <text
                        id='_'
                        data-name='!'
                        transform='translate(30, 36.802)'
                        fill='#fd7777'
                        font-size='25'
                        font-family='SegoeUI, Segoe UI'
                      >
                        <tspan x='0' y='0'>
                          !
                        </tspan>
                      </text>
                    </g>
                  </svg>

                  <div style={{ margin: '1vw', padding: '0' }}>
                    {showAlert.map(item => {
                      return <div>{item}</div>
                    })}
                  </div>
                </div>
                {step === 1 && (
                  <>
                    <div className='inputPhone'>
                      <PhoneInput
                        country={'ps'}
                        enableSearch={true}
                        disableSearchIcon={true}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          disableSearchIcon: false.valueOf
                        }}
                        onChange={phone => setPhone(phone)}
                      />
                    </div>
                    <div className='planInfo'>
                      {planInfo?.planDetails?.price}
                    </div>
                    <div className='planInfo'>
                      {planInfo?.planDetails?.details}
                    </div>
                    <div
                      className='planInfo'
                      style={{
                        marginTop: '50px'
                      }}
                    >
                      {planInfo?.planDetails?.unsubscribe}
                    </div>
                  </>
                )}

                <div className='inputPhoneV'>
                  <div
                    className={
                      true
                        ? 'title fadeInAnmthionActive'
                        : 'title fadeInAnmthion'
                    }
                  >
                    {step === 2 && (
                      <div className='inputPhoneV'>
                        <ReactCodeInput
                          fields={4}
                          onChange={vals => {
                            setCode(vals)
                          }}
                          autoFocus={true}
                        />
                      </div>
                    )}

                    <div className='btns'>
                      <a
                        className='btn1'
                        onClick={() => {
                          handleSubmitEnd()
                        }}
                      >
                        {
                          lang1['RegisterationPhoneNumberVerificationScreen']
                            .buttonNextLabel
                        }
                      </a>
                    </div>
                    <div id='recaptcha-container'></div>

                    {/* <div className="textunderphone" style={{ marginBottom: '2vw' }}>
                                        {lang1['RegisterationPhoneNumberVerificationScreen'].instructionsText} <a
                                            onClick={() => {
                                                'resendCode();'
                                            }}
                                        > {lang1['RegisterationPhoneNumberVerificationScreen'].buttonResendCodeLabel}</a>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        </section>
      </section>
      <Footer />
    </div>
  )
}
