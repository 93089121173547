/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import Axios from 'axios';
import { useHistory, Link } from "react-router-dom";

import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import FadeIn from 'react-fade-in';

import Footer from '../Component/pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Base64 } from 'js-base64';

import city from './city.json';
import countres from '../assets/countres.json';
import states from '../assets/states.json';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';


import TextField from '@material-ui/core/TextField';
import { PayPalButton } from "react-paypal-button-v2";

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}

export default function Checkout() {
    const [data, setData] = React.useState(true);

    const [load, setLoad] = React.useState(true);


    const [name, setName] = React.useState(null);
    const [citys, setCity] = React.useState(99999999999);
    const [citys2, setCity2] = React.useState(null);
    const [citys3, setCity3] = React.useState(null);
    const [email, setEmail] = React.useState(JSON.parse(localStorage.getItem('token')).UserData.email != undefined ? JSON.parse(localStorage.getItem('token')).UserData.email : null);
    const [address, setAddress] = React.useState(null);
    const [amount, setAmount] = React.useState(null);
    const [showAlert, setShowAlert] = React.useState(false)



    const history = useHistory();
    const alert = useAlert();
    const handleSubmitEnd = (data, order) => {
        if (order.status == 'COMPLETED') {
            var arr = JSON.parse(localStorage.getItem('token'));
            let palnId = localStorage.getItem('subscription').split(",");

            let formData2 = new FormData();
            formData2.append('lang', localStorage.getItem('lang'));
            formData2.append('access_token', arr.access_token);

            formData2.append('platform', 'web');

            formData2.append('planDuration', palnId[1]);
            formData2.append('iduser', arr.UserData._id);
            formData2.append('planId', palnId[0]);
            formData2.append('transactionId', order);

            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getSubscriptioInfo",
                formData2,
            ).then(res => {
                if (res.data.booleanValue) {
                    setLoad(true)
                    localStorage.removeItem('choosePlan');
                    localStorage.removeItem('subscription');
                    history.push(process.env.REACT_APP_FRONT_URL + 'interests');
                }
            });
        }
    }

    function opensection() {
        let formData = new FormData();
        formData.append('amount', amount);
        formData.append('email', email);
        formData.append('address', address);
        formData.append('name', name);
        formData.append('citys2', citys2);
        formData.append('citys3', citys3);
        formData.append('iduser', JSON.parse(localStorage.getItem('token')).UserData._id);
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/hyperpay/step1",
            formData,
        ).then(response2 => {
            //hyperpay/:id/checkout
            // console.log(response2.data)
            let id = Base64.encode(response2.data.id)
            history.push(process.env.REACT_APP_FRONT_URL + 'hyperpay/' + id + '/checkout');
        })
    }
    const modiaPay = () => {
        history.push(process.env.REACT_APP_FRONT_URL + 'mondia/' + JSON.parse(localStorage.getItem('token')).access_token + '/subscribe');
    }

    const zainKsaGateway = () => {
        localStorage.setItem("op", "Zain");
        history.push(process.env.REACT_APP_FRONT_URL + 'ksa/' + JSON.parse(localStorage.getItem('token')).access_token + '/3207' +'/subscribe');
    }

    const mobilyKsaGateway = () => {
        localStorage.setItem("op", "Mobily");
        history.push(process.env.REACT_APP_FRONT_URL + 'ksa/' + JSON.parse(localStorage.getItem('token')).access_token + '/3206' +'/subscribe');
    }

    const stcKsaGateway = () => {
        localStorage.setItem("op", "STC"); 
        history.push(process.env.REACT_APP_FRONT_URL + 'ksa/' + JSON.parse(localStorage.getItem('token')).access_token + '/3222' +'/subscribe');
    }

    const palestinePay = (op) => {
        var config = {
            method: 'get',
            url: 'https://royakids.tv:4000/app/palestine/token',
            headers: {}
        };
        Axios(config)
            .then(function (response) {
                localStorage.setItem('op', op);
                history.push(process.env.REACT_APP_FRONT_URL + 'palestine/' + response.data.token + '/subscribe');

            })
            .catch(function (error) {
                console.log(error);
            });
    }
        useEffect(() => {
            if (localStorage.getItem('subscription') == undefined) {
                history.push(process.env.REACT_APP_FRONT_URL);
            }
            setLoad(false)



            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/getPlanSubscription";
            let formData = new FormData();
            formData.append('lang', localStorage.getItem('lang'));
            Axios.post(url,
                formData,
            ).then(async response2 => {


                var config = {
                    method: 'get',
                    url: backend_url + '/app/getCurrentCountry',
                };

                let country = await Axios(config)
                    .then(function (response) {
                        return response.data.geo.country
                    })
                    .catch(function (error) {
                        return false
                    });

                setLoad(true)
                let palnId = localStorage.getItem('subscription').split(",");



                let partners = response2.data.row && response2.data.row.length > 0 ?
                    response2.data.row.map((p, index) => {
                        if (p._id == palnId[0]) {
                            return (
                                <div className="checkout">




                                    <div className="detal">
                                        {/* <div className="inputdet">

                                            <div className="row">
                                                <TextField id="outlined-basic" label={lang1['checkout'].planName} variant="outlined" value={p.title} InputProps={{
                                                    readOnly: true,
                                                }} style={{ width: '100%' }} />
                                            </div>
                                            <div className="row">
                                                <TextField id="outlined-basic" label={lang1['checkout'].planType} variant="outlined" value={
                                                    (localStorage.getItem('lang') == 'Ar') ? palnId[1] == 'monthly' ? 'شهري' : 'سنوي' : palnId[1]
                                                } InputProps={{
                                                    readOnly: true,
                                                }} style={{ width: '100%' }} />
                                            </div>
                                            <div className="row">
                                                <TextField id="outlined-basic" label={lang1['checkout'].totalBilled} variant="outlined" value={p[palnId[1]] + '$'} InputProps={{
                                                    readOnly: true,
                                                }} style={{ width: '100%' }} />
                                            </div>
                                        </div> */}


                                        <div className="paypal"

                                        >
                                            <svg width="1027" height="400" viewBox="0 0 1027 400" style={{ width: '50vw', height: '20vw' }}>
                                                <defs>

                                                </defs>
                                                <g id="Group_5028" data-name="Group 5028" transform="translate(-4380 8777)">
                                                    <g transform="matrix(1, 0, 0, 1, 4380, -8777)" filter="url(#Rectangle_1556)">
                                                        <rect id="Rectangle_1556-2" data-name="Rectangle 1556" width="853" height="467" rx="43" transform="translate(87 52)" fill="#fff" />
                                                    </g>

                                                    <g id="Rectangle_1557" data-name="Rectangle 1557" transform="translate(4516 -8669)" fill="#f4faff" stroke="#cbdbf5" strokeWidth="2">
                                                        <rect width="755" height="165" rx="31" stroke="none" />
                                                        <rect x="1" y="1" width="753" height="163" rx="30" fill="none" />
                                                    </g>
                                                    <text id="Basic_Plan" data-name={p.title} transform={localStorage.getItem('lang') === 'En' ? "translate(4547 -8589)" : "translate(4747 -8589)"} fill="#4f5472" fontSize="28px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="0" y="34">{p.title}</tspan></text>
                                                    <text id="Monthly" transform={localStorage.getItem('lang') === 'En' ? "translate(4794 -8589)" : "translate(4994 -8589)"} fill="#4f5472" fontSize="28px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="0" y="34">{(localStorage.getItem('lang') === 'Ar') ? palnId[1] === 'monthly' ? 'شهري' : 'سنوي' : palnId[1]}</tspan></text>
                                                    <g id="Group_5026" data-name="Group 5026" transform={localStorage.getItem('lang') === 'En' ? "translate(3541.417 -8733)" : "translate(3671.417 -8733)"}  >
                                                        <text id="_4.99" data-name="4.99" transform="translate(1530.583 144)" fill="#4f5472" fontSize="28px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="0" y="34">{p[palnId[1]]}</tspan></text>
                                                        <text id="_" data-name="$" transform={localStorage.getItem('lang') === 'En' ? "translate(1504.583 145)" : "translate(1454.583 145)"} fill="#4f5472" stroke="#525875" strokeWidth="2" fontSize="28px" fontFamily="VarelaRound-Regular, Varela Round"><tspan x="0" y="31">$</tspan></text>
                                                    </g>
                                                    <path id="Path_6444" data-name="Path 6444" d="M3160.863-8691v163.766" transform="translate(1606.637 22.386)" fill="none" stroke="#cbdbf5" strokeWidth="2" />
                                                    <path id="Path_6443" data-name="Path 6443" d="M3160.863-8691v163.766" transform="translate(1858.637 22.386)" fill="none" stroke="#cbdbf5" strokeWidth="2" />
                                                    <text id="Plan_Name:" data-name="Plan Name:" transform={localStorage.getItem('lang') === 'En' ? "translate(4547 -8609)" : "translate(4747 -8609)"} fill="#65b5ff" fontSize="25px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="0" y="0">{lang1['checkout'].planName}:</tspan></text>
                                                    <text id="Plan_Type:" data-name="Plan Type:" transform={localStorage.getItem('lang') === 'En' ? "translate(4794 -8609)" : "translate(4994 -8609)"} fill="#65b5ff" fontSize="25px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="0" y="0">{lang1['checkout'].planType}:</tspan></text>
                                                    <g id="Group_5027" data-name="Group 5027" transform="translate(3933 -9052)">
                                                        <path id="Path_6441" data-name="Path 6441" d="M3160.863-8691v264.191" transform="translate(-7843.808 -2551.881) rotate(90)" fill="none" stroke="#cbdbf5" strokeWidth="2" />
                                                        <path id="Path_6442" data-name="Path 6442" d="M3160.863-8691v264.191" transform="translate(-7353.999 -2551.881) rotate(90)" fill="none" stroke="#cbdbf5" strokeWidth="2" />
                                                        <text id="Payment_Method::" data-name="Payment Method::" transform={localStorage.getItem('lang') === 'En' ? "translate(960 617)" : "translate(1110 617)"} fill="#65b5ff" fontSize="25px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="-90.148" y="0">{lang1['checkout'].method}</tspan></text>
                                                    </g>
                                                    <text id="Total_Billed:" data-name="Total Billed:" transform={localStorage.getItem('lang') === 'En' ? "translate(5046 -8609)" : "translate(5246 -8609)"} fill="#65b5ff" fontSize="25px" fontFamily="'ArbFONTS-DroidKufi', Somatic"><tspan x="0" y="0">{lang1['checkout'].totalBilled}:</tspan></text>
                                                </g>
                                            </svg>

                                            <div className="flexRow">
                                                {
                                                    (localStorage.getItem('subscription') === 'plan_2,monthly' && country === 'JO' && (JSON.parse(localStorage.getItem('mainProfile'))._id !== "userApp_50883")) &&
                                                    <div onClick={modiaPay}>
                                                        <img style={{ width: '60px', cursor: 'pointer' }} src={"https://royakids.tv:4000/public/static/orange.png"} />
                                                    </div>
                                                }
                                                {
                                                    //country === 'PS' ||
                                                    // (country === 'PS' || JSON.parse(localStorage.getItem('mainProfile'))._id === "userApp_50883")
                                                    true
                                                    &&
                                                    <div onClick={() => palestinePay('JW')}>
                                                        <img alt="" style={{ width: '90px', cursor: 'pointer' }} src={require("../assets/img/jawwal-logo-new.png")} />
                                                    </div>
                                                }

                                                {
                                                    //country === 'PS' ||
                                                    // (country === 'PS' || JSON.parse(localStorage.getItem('mainProfile'))._id === "userApp_50883")
                                                    true
                                                    &&
                                                    <div onClick={() => palestinePay('WM')}>
                                                        <img style={{ width: '120px', cursor: 'pointer' }} src={require("../assets/img/ooredoo-logo-2022-1660844757.svg")} />
                                                    </div>
                                                }

                                                {
                                                    <div onClick={zainKsaGateway}>
                                                        <img alt="" style={{ width: '60px', cursor: 'pointer' }} src={require("../assets/img/zain.png")} />
                                                    </div>
                                                }
                                                {
                                                    <div onClick={mobilyKsaGateway}>
                                                        <img alt="" style={{ width: '60px', cursor: 'pointer' }} src={require("../assets/img/mobily.png")} />
                                                    </div>
                                                }
                                                {/* {
                                                   (JSON.parse(localStorage.getItem('mainProfile'))._id === "userApp_52961") &&
                                                    <div onClick={stcKsaGateway}>
                                                        <img alt="" style={{ width: '60px', cursor: 'pointer' }} src={require("../assets/img/stc.png")} />
                                                    </div>
                                                } */}
                                                <div className="hyperpay" onClick={() => {
                                                    setAmount(p[palnId[1]]);
                                                    setShowAlert(true);
                                                }}>
                                                    {/* <img src={require("../assets/img/hyperpay.png")} /> */}
                                                    <img src={"/hyperpay.png"} />
                                                </div>
                                                <PayPalButton
                                                    amount={p[palnId[1]]}
                                                    shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                    onSuccess={(details, data) => {
                                                        // alert("Transaction completed by " + details.payer.name.given_name);
                                                        handleSubmitEnd(data, details)

                                                        // OPTIONAL: Call your server to save the transaction
                                                        return fetch("/paypal-transaction-complete", {
                                                            method: "post",
                                                            body: JSON.stringify({
                                                                orderId: data.orderID
                                                            })
                                                        });
                                                    }}
                                                    options={{
                                                        clientId: "AYsdLi2isQj9AiZpqC7rxdZwFhosso6hsAMpn0QiQaIZuakmvzc4tPX6lX0Se5H68fAVAbV89dhfh6Wa",

                                                    }}
                                                    style={{
                                                        layout: 'horizontal',
                                                        Size: 'large',
                                                        color: 'blue',
                                                        shape: 'pill',
                                                        label: 'pay',
                                                    }}
                                                    onCancel={() => {
                                                        setLoad(true)
                                                    }}
                                                    onClick={() => {
                                                        setLoad(false)
                                                    }}
                                                />

                                            </div>



                                        </div>
                                    </div>


                                </div >

                            )
                        }
                    }

                    ) : <span>Error</span>;



                setData(partners)




            })




        }, []);


    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ display: load ? 'block' : 'none' }} >

                <section id="footer" >

                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} />


                    </div>
                    <FadeIn>

                        <div className={"title fadeInAnmthionActive"} >
                            <img className="imgLogo pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                            <div className="textTitle">
                                <b>{lang1['checkout'].title}</b>
                            </div>

                            {data}
                            <div style={{ display: showAlert ? 'block' : 'none' }}>

                                <section id="LoginPopup" style={{ zIndex: '999' }}>
                                    <div className="colseBtn"
                                        onClick={() => setShowAlert(false)}
                                        style={{ right: '24%' }}
                                    >X</div>
                                    <div className="boxPopup" style={{ width: '45%' }}>


                                        <div className="contentPopup">
                                            <div className="formForPay">
                                                <div className="titleForm">{lang1['checkout'].titletop}</div>
                                                <TextField
                                                    placeholder={lang1['checkout'].email}
                                                    variant="outlined"
                                                    value={email}
                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />

                                                <TextField
                                                    placeholder={lang1['checkout'].name}
                                                    variant="outlined"
                                                    value={name}
                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                    onChange={(e) => {
                                                        setName(e.target.value);
                                                    }}
                                                />


                                                <div className="row">
                                                    <FormControl variant="outlined">

                                                        <label>{lang1['checkout'].country}</label>

                                                        <Select
                                                            placeholder="Month"
                                                            id="demo-simple-select-filled11"
                                                            value={citys + ',' + citys3}
                                                            style={{ width: '15vw', backgroundColor: '#fff' }}
                                                            className="inputDate"
                                                            onChange={(e) => {
                                                                setCity(e.target.value.split(',')[0])
                                                                setCity3(e.target.value.split(',')[1])
                                                            }}
                                                        >
                                                            {countres.countries.map((c) => {
                                                                return <MenuItem value={c.id + ',' + c.sortname}>{c.name}</MenuItem>
                                                            }
                                                            )}


                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined">
                                                        <label>{lang1['checkout'].city}</label>

                                                        <Select
                                                            placeholder="Month"
                                                            id="demo-simple-select-filled11"
                                                            value={citys2}
                                                            style={{ width: '15vw', backgroundColor: '#fff' }}
                                                            className="inputDate"
                                                            onChange={(e) => setCity2(e.target.value)}
                                                        >
                                                            {states.states.map((c) => {
                                                                if (c.country_id == citys)
                                                                    return <MenuItem value={c.name}>{c.name}</MenuItem>
                                                            }
                                                            )}


                                                        </Select>

                                                    </FormControl>

                                                </div>
                                                <TextField
                                                    placeholder={lang1['checkout'].address}
                                                    variant="outlined"
                                                    value={address}
                                                    style={{ width: '100%', backgroundColor: '#fff' }}
                                                    onChange={(e) => {
                                                        setAddress(e.target.value);
                                                    }}
                                                />


                                                <div className={address && citys2 && citys && name && email ? "btns2" : "btns2dis"}
                                                    onClick={() => address && citys2 && citys && name && email ? opensection() : ''}>
                                                    {lang1['checkout'].continue}
                                                </div>


                                            </div>
                                        </div>




                                    </div>


                                </section>


                            </div>


                        </div>

                    </FadeIn>

                </section>

            </section>
            <Footer />
        </div>
    );
}
