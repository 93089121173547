import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '50vw',

    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

let lang1 = require('../../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
  localStorage.setItem('lang', 'En');
  lang1 = require('../../lang/en').default;
}
else {
  if (localStorage.getItem('lang') === 'En') {
    lang1 = require('../../lang/en').default;
  }
  else {
    lang1 = require('../../lang/ar').default;
  }
}

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState('En');
  const [notification, setNotification] = React.useState([]);
  const classes = useStyles();
  const [load, setLoad] = React.useState(true);


  useEffect(() => {
    setLoad(false);
    var arr = JSON.parse(localStorage.getItem('token'));
    if (arr == undefined) {
      this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
    }
    else {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      const url = backend_url + "/app/accesscode";
      let formData = new FormData();
      formData.append("access_token", arr.access_token);
      formData.append('lang', localStorage.getItem('lang'));
      Axios.post(url,
        formData,
      ).then(response => {
        if (response.data.data == undefined) {
          this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
          let backend_url = process.env.REACT_APP_BACKEND_URL;
          const url = backend_url + "/app/listNotification";

          Axios.post(url,
            formData,
          ).then(response2 => {
            // console.log(response2.data)
            setLoad(true);
            setNotification(response2.data.result)

          })
        }
      })

    }
  }, []);
  let history = useHistory();

  const prettyDate = (date) => {

    var d = new Date(date);

    return d.toDateString() + " ";
  }

  return (
    <div>
      {/* <Backdrop className="backdrop" open={!load}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <section id="Notification" style={{ display: load ? 'block' : 'none' }} >

        <div className={classes.root}>
          {
            notification.length == 0 ?
              lang1['NotificationsScreen'].noContentsAvailableLabel
              :
              notification
                .slice(0, 5)
                .map((item) => {
                  return (
                    <Alert severity="info" className="severityinfo">
                      {item.message}
                      {/* <div className="dateR">{prettyDate(item.created)}</div> */}
                    </Alert>
                  )
                })
          }


        </div>

      </section>


    </div>
  );
}