export default {
    createAccountScreen: {
        title: 'حساب جديد',
        inputEmail: 'البريد الإلكتروني',
        inputPassword: 'كلمة المرور',
        buttonNext: 'متابعة',
        or: 'أو',
        signuptxt: 'هل لديك حساب بالفعل؟',
        signup: 'تسجيل الدخول',
    },
    authenticationScreen: {
        title: 'أهلاً بكم بتطبيق رؤيا الأطفال',
        buttonCreateAccount: 'حساب جديد',
        buttonLogin: 'تسجيل دخول',
        buttonGuestUser: 'النسخة المجانية',
    },
    LoginScreen: {
        title: 'تسجيل دخول',
        inputEmail: 'البريد الإلكتروني',
        inputPassword: 'كلمة المرور',
        buttonNext: 'متابعة',
        buttonForgetPassword: 'نسيت كلمة المرور؟',
        or: 'أو',
        signuptxt: 'ليس لديك حساب؟',
        signup: 'سجل',
    },
    ResetPasswordScreen: {
        title: 'تغيير كلمة المرور',
        inputEmail: 'البريد الإلكتروني',
        instruction: 'سيصلك بريد إلكتروني بكلمة المرور الجديدة',
        buttonNext: 'تغيير كلمة المرور',
        subscribebtn: 'إشتراك',
        error: 'هذا المستخدم غير موجود',
        error1: 'رصيدك غير كافي',
        error2: 'يوجد لديك اشتراك فعال على نفس رقم الهاتف',
        error3: 'عملية غير مكتملة',
        USER_DECLINED_PURCHASE_DURING_CONFIRMATION: "رفض المستخدم الشراء أثناء التأكيد",
        USER_IS_BLOCKED: "المستخدم محظور",
        USER_NOT_ACTIVE: "المستخدم غير نشط",
        USER_IS_BLACKLISTED: "المستخدم في القائمة السوداء",
        EXPIRED_OTP: "OTP منتهي",
        USER_BLOCKED_BY_PROVIDER: "المستخدم محظور من قبل المزود",
        succ: 'تم الإشتراك بنجاح!',
        quit: 'الصفحة الرئيسية',
    },
    NavigationButtonsMain: {
        button1: 'أغاني',
        button2: 'قصص',
        button3: 'برامج',
        button4: 'الألعاب',
    },
    NavigationButtonsInner: {
        button1: 'الكل',
        button2: 'أغاني',
        button3: 'قصص',
        button4: 'برامج',
        button5: 'ألعاب',
    },

    RegisterationEnterPhoneNumberScreen: {
        title: "رقم هاتفك",
        buttonNextLabel: "متابعة",
        instructionsText: "سيصلك رسالة تحتوي على رمز تأكيد",
        buttonSendEmailLabel: "أو باستخدام البريد الإلكتروني",
    },
    RegisterationPhoneNumberVerificationScreen: {
        title: "تأكيد رقم الهاتف",
        titleEmail: "التأكيد عبر البريد الإلكتروني",
        buttonNextLabel: "متابعة",
        instructionsText: "لم يصلك رمز التأكيد؟",
        buttonResendCodeLabel: "إعادة طلب الرمز",
        enterPhone: "أدخل رقم الهاتف",
    },
    RegistrationChildFirstNameScreen: {
        titlePage: "إنشاء ملف تعريف طفلك",
        title: "الاسم الأول لطفلك",
        inputChildNameLabel: "أدخل الاسم الأول لطفلك",
        buttonContinueLabel: "متابعة",

    },
    RegistrationChildDateOfBirthScreen: {
        title: "تاريخ ميلاد طفلك",
        inputYearLabel: "السنة",
        inputMonthLabel: "الشهر",
        inputDayLabel: "اليوم",
        buttonContinueLabel: "متابعة",
    },
    RegistrationChildGenderScreen: {
        title: 'جنس طفلك',
        labelMale: 'ولد',
        labelFemale: 'بنت',
        buttonContinueLabel: 'متابعة',
    },
    RegistrationChildProfileImageScreen: {
        title: 'قم بتحميل صورة الملف الشخصي',
        titleSub: 'أو الاختيار من التالي',
        buttonContinueLabel: 'متابعة',
    },

    InterestsStoriesScreen: {
        title: 'اختيار الاهتمامات قصصية',
        buttonSkip: 'تخطي',
        buttonNext: 'متابعة',
        finish: 'إنهاء'
    },
    InterestsSongsScreen: {
        title: 'اختيار الاهتمامات للأغاني',
        buttonSkip: 'تخطي',
        buttonNext: 'متابعة',
    },
    InterestsShowsScreen: {
        title: 'اختيار الاهتمامات للبرامج',
        buttonSkip: 'تخطي',
        buttonNext: 'متابعة',
    },



    ItemMenuPopup: {
        buttonAddToPlaylistLabel: 'إضافة إلى قائمة التشغيل',
        buttonDownloadLabel: 'تحميل المحتوى',
        buttonDownloadedLabel: 'تم التحميل',
        buttonAddToFavouriteLabel: 'إضافة إلى المفضلة',
        buttonRemoveFromFavouriteLabel: 'إزالة من المفضلة',
        buttonDeleteContentLabel: 'حذف'
    },

    DeletePlaylistPopup: {
        label: 'هل أنت متأكد من حذف قائمة التشغيل؟',
        delete:'حذف',
        cancel: 'إلغاء'
    },

    PlaylistsMainScreen: {
        buttonCreatePlayListLabel: 'إنشاء قائمة تشغيل',
    },
    AddToPlaylistPopup: {
        title: 'إضافة إلى قائمة التشغيل',
        ButtonCreatePlaylistLabel: 'إنشاء قائمة تشغيل',

    },
    CreatePlaylistNamePopup: {
        title: 'إنشاء قائمة تشغيل',
        inputTxtLabel: 'أدخل اسم قائمة التشغيل',
        ButtonCreateLabel: 'إنشاء',

    },
    PlaylistScreen: {
        editButtonLabel: 'تعديل',
        deleteButtonLabel: 'حذف',
        saveButtonLAbel: 'حفظ',
        textInputSearchLabel: 'بحث',

        labelSongsCount: 'أغاني',
        labelStoriesCount: 'قصص',
        labelShowsCount: 'برامج',

        buttonTabAddShowsLabel: 'إضافة برامج',
        buttonTabAddStoriesLabel: 'إضافة قصص',
        buttonTabAddSongsLabel: 'إضافة أغاني',
        all: 'الجميع'
    },

    HeaderTitles: {
        titleMyFavorite: 'المفضلة',
        titleMyDownloads: 'التنزيلات',
        titleMyPlaylists: 'قوائم التشغيل',
        titleSettings: 'الإعدادات',
        titleUpdateProfile: 'تحديث الملف الشخصي',
        titleHistory: 'سجل المشاهدات',
        titleCreateProfile: 'إنشاء ملف تعريف'
    },

    SearchScreen: {
        textInputSearchLabel: 'بحث',

    },


    SettingsScreen: {
        buttonTabProfilesLabel: 'الملفات الشخصية',
        buttonTabSubscriptionsLabel: 'الاشتراك',
        buttonTabLanguagesLabel: 'تغيير اللغة',
        buttonTabNotificationsLabel: 'الإشعارات',
        buttonTabPrivacyPoliciesLabel: 'سياسة الخصوصية',
        buttonTabTermsOfUseLabel: 'شروط و أحكام',
    },

    profilesSwitchScreen: {
        title: 'للوصول للمحتوى',
        buttonCreateAccountLabel: 'حساب جديد',
        buttonLoginLabel: 'تسجيل دخول',
        buttonLogoutLabel: 'تسجيل خروج',
    },
    ManageProfilePopup: {
        switchProfileButtonLabel: 'تفعيل الملف الشخصي',
        manageProfileButtonLabel: 'إدارة الملف الشخصي',
        deleteProfle: 'حذف الملف الشخصي'
    },

    LanguageSelectionScreen: {
        buttonSaveLabel: 'حفظ',
        buttonArabicLabel: 'عربي',
        buttonEnglishLabel: 'انجليزي',
    },
    NotificationsScreen: {
        noContentsAvailableLabel: 'لا يوجد اشعارات حالياً',
    },

    UpdateProfileScreen: {
        titleEditSettings: "تعديل إعدادات",
        childNameLabel: 'اسم الطفل',
        textInputChildNameLabel: 'اسم الطفل الأول',
        dateOfBirthLabel: 'تاريخ الميلاد',
        textInputDobDayLabel: 'اليوم',
        textInputDobMonthLabel: 'الشهر',
        textInputDobYearLabel: 'السنة',
        buttonMaleLabel: 'ولد',
        buttonFemaleLabel: 'بنت',
        genderLabel: 'الجنس',
        buttonEditInterestsLabel: 'تعديل الاهتمامات',
        buttonSaveLabel: 'حفظ',
        buttonCancelLabel: 'إلغاء',

        labelHistory: "السجل",
        labelWatchHistory: "سجل المشاهدات",
        labelClearHistory: "مسح السجل",
        labelScreenTime: "مجموع وقت المشاهدات",
        labelDeleteProfile: "حذف الملف الشخصي",
        btnDelete: "حذف"
    },
    DeleteProfilePopup: {
        message: 'حذف الملف الشخصي؟',
        buttonOkLabel: 'نعم',
        buttonCancelLabel: 'لا'
    },
    ClearWatchedHistoryPopup: {
        message: 'مسح السجل؟',
        buttonOkLabel: 'نعم',
        buttonCancelLabel: 'لا'
    },

    Shared: {
        noContentsAvailable: 'قريباً',
        noHistoryContentsAvailable: 'لا يوجد بيانات',
        noContentsAvailable2: 'لا يوجد محتوى',
        buttonPrivacyPolicyLabel: 'سياسة الخصوصية',
        buttonTermsOfUseLabel: 'شروط و أحكام',
        Seasons: 'مواسم'
    },
    SaveChangesPopup: {
        buttonOkLabel: 'نعم',
        buttonCancelLabel: 'لا'
    },

    AlertMessages: {
        Warning: 'تنبيه',
        acceptedYear: 'لا يمكن قبول هذا العام!',
        //Create account/Login
        emptyEmail: "الرجاء إدخال بريد إلكتروني",
        invalidEmail: "الرجاء إدخال بريد إلكتروني صحيح",
        emptyPassword: "الرجاء إدخال كلمة مرور",
        minPasswordLength: "الحد الأدنى لطول كلمة المرور 6 خانات",
        loginFacebookCancelled: 'تم إلغاء تسجيل الدخول من قبلكم',
        somthingWentWrong: 'حدث خطأ ما!',
        loginGoogleCancelled: 'تم إلغاء تسجيل الدخول من قبلكم',
        loginAlreadyProcessing: 'جاري عملية تسجيل الدخول',
        loginOutOfDate: 'خدمة التسجيل منتهية الصلاحية',

        //Interests selection
        onEmptyInterests: 'الرجاء اختيار واحد على الأقل أو التخطي',

        //AvatarImage
        onEmptyAvatatImage: "الرجاء تحميل صورة أو الاختيار من الصور في الأعلى",

        //Add for number
        onInvalidMobileNumber: "الرجاء إدخال رقم هاتف صحيح مع رمز الدولة",
        onEmptyMobileNumber: "الرجاء إدخال رقم هاتف",

        //BirthDateScreen
        onEmptyBirthDate: "الرجاء تحديد تاريخ الميلاد",

        //Gender Selection
        onEmptyGender: "الرجاء تحديد الجنس",

        //Add Child name
        onEmptyChildName: "الرجاء إدخال اسم الطفل",
        onChildNameTooShort: "الرجاء إدخال اسم صحيح", //Min is two

        //Verification screen
        onInvalidCode: "الرمز خاطئ",
        onShortCode: "الرجاء ادخال الرمز",
        onVerificationSuccess: 'تم عملية التأكيد بنجاح',
        onCodeAlreadyRequested: 'تم طلب الرمز مسبقاً',
        onCodeRequestSent: 'تم طلب الرمز',

        //Create playlist popup
        onInvalidPlaylistName: 'الرجاء ادخال اسم لقائمة التشغيل',
        playlistNameAlreadyExists: 'الاسم موجود من قبل',

        //Playlist creation screen
        onNoneSelected: 'الرجاء اختيار واحد على الأقل',

        //Download manager
        onErrorDownloadContent: "خطأ في تحميل",
        onStartDownloadContent: "بدأ تحميل",
        onDownloadSuccessfully: "تم التحميل بنجاح",
        onDownloadFailed: "خطأ في التحميل",
    },

    //GAMES
    MemoryGame: {
        endScreenWonTitle: "لقد فزت!",
        buttonConfirmWonLabel: "التالي",
        //buttonCancelWonLabel: "خروج",
        buttonCancelWonLabel: "حسناً",
        endScreenLoseTitle: "لقد خسرت",
        buttonConfirmLoseLabel: "محاولة أخرى",
        //buttonCancelLoseLabel: "خروج",
        buttonCancelLoseLabel: "رجوع",
    },
    PuzzleGame: {
        movesCountLabel: "حركات",

    },
    GamesLoadingScreen: {
        loadingText: 'جاري التحميل',
    },

    ParentSectionPopupScreen: {
        title: "قسم الأهل",
        hint: "الرجاء حل المعادلة للمتابعة",
        buttonNextLabel: "متابعة",
        instructionsText: "ليس لديكم رمز مرور؟",
        buttonSendEmailLabel: "أنشئ رمز",
        warningInsertCode: "الرجاء حل المعادلة للمتابعة",
        alertWrongPasscode: "إجابة خاطئة",
    },

    PlanSelection: {
        screenTitle: "اختر نوع الاشتراك",

        monthly: "شهري",
        annualy: "سنوي",
        watchAtSameTime: "عدد الأجهزة في نفس الوقت",
        watchAtSameTimeOneDevice: "جهاز في نفس الوقت",
        exclusive: "محتوى حصري",
        watchDevices: "المشاهدة على الهاتف و التابلت",
        cancel: "إلغاء الاشتراك في أي وقت",
        buttonLabel: "اشترك",
        noPlanSelected: "الرجاء اختيار نوع الاشتراك",
    },

    SubscribeNowPopup: {
        title: "اشترك الآن",
        hint: "اشترك لتحصل على قصص و أغاني و أكثر",
        buttonLabel: "اشترك الآن",
        Upgrade: 'قم بترقية خطتك للوصول للمحتوى',
        UpgradeBTN: 'ترقية الخطة الأن'
    },
    CurrentSubscriptionScreen: {
        title: "الاشتراك الحالي",
        monthly: "شهري",
        annualy: "سنوي",
        upgradeSubscriptionButtonLabel: "تعديل الاشتراك",
        cancelSubscriptionButtonLabel: "إلغاء الاشتراك",
        renewDate: "تاريخ التجديد: "
    },


    ForceLogoutScreen: {
        title: "قم بتسجيل خروج من أحد الأجهزة للمتابعة",
        buttonLabel: "تسجيل خروج",
        buttonLoginLabel: 'تسجيل دخول',
        loggedOutInfo: "تم تسجيل خروج من قبل ",
    },

    InternetConnectionError: {
        message: "الرجاء الاتصال بالانترنت",
        buttonLabelRetry: "إعادة المحاولة",
        buttonLabelGoToDownloads: "الذهاب إلى التنزيلات"
    },
    footer: {
        rights: 'جميع الحقوق محفوظة',
        royakids: 'مجموعة رؤيا الإعلامية',
        about: 'من نحن  ',
        privacy: 'سياسة الخصوصية',
        conditions: 'الشروط و الأحكام',
        contact: 'اتصل بنا',
        contacttxt: 'هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.',
        abouttxt: 'هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت.',
    },
    checkout: {
        title: 'الدفع',
        planName: 'اسم الخطة',
        planType: 'نوع الخطة',
        totalBilled: 'إجمالي الفاتورة',
        method: 'طريقة الدفع::',
        sucss: 'تمت عملية الدفع بنجاح!',
        error: 'لم تتم عملية الدفع!',

        continue: 'أستمر',
        email: 'البريد الإلكتروني',
        name: 'الأسم الكامل',
        country: 'الدولة',
        city: 'المدينة',
        address: 'العنوان',
        titletop: 'نود منك ملئ المزيد من التفاصيل لمواصلة عملية الدفع الآمنة.',
    },
    countactus: {
        name: 'الأسم',
        email: 'البريد الإلكتروني',
        msg: 'رسالتك',
        btn: 'إرسال',
        ErrorMsg: 'الرجاء إدخال جميع الحقول!',
        SucMsg: 'تم إرسال الرسالة بنجاح!'
    }
};