import React, { Component } from 'react';


import { BrowserRouter, Route, Link } from "react-router-dom";
class infoConent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup: this.props.show.showPopup,
            genreData: [this.props.show.data.genre].join(', '),
            tags: JSON.stringify(props.show.tags),
        }
    }
    componentWillReceiveProps(props) {
        let arrayLenghts = [];
        let array = JSON.stringify(props.show.tags)
        Object.keys(array).map((key, index) => {
            arrayLenghts[index] = array[key].length;
        });
        // console.log(arrayLenghts)
        this.setState({
            showPopup: props.show.showPopup,
            genreData: [props.show.data.genre].join(', '),
            tags: arrayLenghts
        });
    }

    closePopup() {
        this.setState({ showPopup: false })
    }

    render() {

        return (

            <div style={{ display: this.state.showPopup ? 'block' : 'none' }}>

                <section id="infoConent" >
                    <div className="colseBtn"
                        onClick={this.closePopup.bind(this)}
                    >X</div>
                    <div className="boxPopup">
                        <div className="tagimg">
                            <img id="" src={this.props.show.type == 'songs' ? require("../../assets/img/Group 836.png") : this.props.show.type == 'shows' ? require("../../assets/img/show.png") : require("../../assets/img/story.png")} />
                        </div>
                        <div className="agerate" style={{
                            display: this.props.show.data.ageGroup != undefined ? 'block' : 'none'
                        }}>
                            {this.props.show.data.ageGroup}
                        </div>
                        <div className="contentPopup">
                            <div className="titlecontentPopup">{this.props.show.data.title}</div>
                            <div className="GenrecontentPopup">
                                {this.state.genreData}
                            </div>
                        </div>
                        <div className="descriptionPopup">
                            <div className="descriptionPopup">
                                {this.props.show.data.description}
                            </div>
                        </div>
                        <Link className="play" style={{ display: this.props.show.type == 'shows' || this.props.show.data.isPaid ? 'none' : 'block' }} to={process.env.REACT_APP_FRONT_URL + 'content/' + this.props.show.type + '/' + this.props.show.data._id}></Link>

                        <div className="tags">
                            { 
                            this.state.tags.length > 0 && (
                                this.props.show.tags.map(ite => {
                                    return <div className="itemtag">{ite}</div>
                                })
                            )}
                        </div>

                    </div>


                </section>


            </div>
        );
    }
}

export default infoConent;