import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import InfoConent from './popup/infoConent';
import ActionContent from './popup/actionContent';


let lang = require('../lang/en').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'En');
    lang = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}

class Search extends Component {
    state = {
        items: [],
        type: 'songs',
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        favSt: '',
        keyword: '',
        genres: [],
        GenreList: [],
        GenreListselected: [],
        lang: lang
    }

    changeType = (type) => {
        console.log(type)
        var arr = JSON.parse(localStorage.getItem('token'));
        this.setState({
            type: type,
            showPopup: false,
            showPopup2: false,
            items: []
        })
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/listFavorite";
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('typeOFContent', type);

        formData.append('profile_id', arr.UserData._id);
        formData.append('lang', localStorage.getItem('lang'));




        Axios.post(url,
            formData,
        ).then(response2 => {
            console.log(response2)
            this.setState({
                items: response2.data.allarray
            })

        })
    }
    componentDidMount() {
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined) {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {


                    let backend_url = process.env.REACT_APP_BACKEND_URL;
                    let formData2 = new FormData();
                    formData2.append('access_token', arr.access_token);

                    formData2.append('lang', localStorage.getItem('lang'));

                    Axios.post(backend_url + "/app/getProfile",
                        formData2,
                    ).then(Profiles => {
                        Profiles.data.newarray.map((item) => {
                            if (item.mainProfile) {
                                // this.setState({
                                //     items: response2.data.allarray
                                // })
                                let formData33 = new FormData();
                                formData33.append('access_token', arr.access_token);

                                formData33.append('profileId', item._id);
                                formData33.append('lang', localStorage.getItem('lang'));


                                Axios.post(backend_url + "/app/history/list",
                                    formData33,
                                ).then(response2 => {

                                    // console.log(response2.data.allarray)
                                    this.setState({
                                        items: response2.data.allarray
                                    })

                                    document.body.scrollTop = 0;
                                    document.documentElement.scrollTop = 0;
                                })
                            }

                        })

                    })





                }
            })

        }
    }



    render() {

        return (
            <div>
                <section id="Search" >

                    <div className="header">
                        <div className="leftSec">
                            <img id="backbtn" onClick={() => {
                                this.props.history.goBack()
                            }} src={require("../assets/img/Group 4877.png")} />
                            <div className="SearchTitle">{this.state.lang['HeaderTitles'].titleHistory}</div>
                        </div>
                        <div className="centerSec">

                        </div>
                        <div className="rightSec">
                            <div>
                                {/* <img src={require("../assets/img/logo.png")} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    <ActionContent show2={{ showPopup2: this.state.showPopup2, data2: this.state.popupshow, favSt: this.state.favSt, type: this.state.type }} />
                    <InfoConent show={{ showPopup: this.state.showPopup, data: this.state.popupshow, tags: this.state.tags, type: this.state.type }} />
                    <div className="bodyHome">

                        <div className="Result">
                            {
                                this.state.items.length != 0 ?
                                    this.state.items.map(video =>
                                        <div>
                                            {video != null ?
                                                <div key={video._id} className="item">


                                                    <div className="gotocontent"
                                                        onClick={() => {
                                                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'content/' + video.typeOFContent + '/' + video._id);
                                                        }}
                                                    ></div>
                                                    <img className="mainImg" src={process.env.REACT_APP_BACKEND_URL + "/public/" + video.coverImg}></img>
                                                    <div className="titleitem">{video.title}</div>
                                                </div>
                                                : ''
                                            }
                                        </div>





                                    )
                                    :

                                    <div className="nocontent">{this.state.lang['Shared'].noHistoryContentsAvailable}</div>

                            }
                        </div>
                    </div>
                </section>


            </div>
        );
    }
}

export default Search;