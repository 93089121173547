import React, { useEffect } from 'react';

import Axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import FadeIn from 'react-fade-in';
import { Base64 } from 'js-base64';
import Footer from './pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PayPalButton } from "react-paypal-button-v2";

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}
export default function Checkout() {
    const { id } = useParams();
    const [data, setData] = React.useState({});
    const [load, setLoad] = React.useState(true);
    const [user, setUser] = React.useState({});
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory();
    const alert = useAlert();


    const handleSubmitEnd = (data, order) => {

        if (order.status == 'COMPLETED') {
            var arr = JSON.parse(localStorage.getItem('token'));
            // let palnId = localStorage.getItem('subscription').split(",");

            // let formData2 = new FormData();
            // formData2.append('lang', localStorage.getItem('lang'));
            // formData2.append('access_token', arr.access_token);

            // formData2.append('platform', 'web');

            // formData2.append('planDuration', palnId[1]);
            // formData2.append('iduser', arr.UserData._id);
            // formData2.append('planId', palnId[0]);
            // formData2.append('transactionId', order);

            Axios.post(`http://gateway.mondiapay.com/v1/api/oauth/token?grant_type=client_credentials&client_id=${process.env.client_id}&client_secret=${process.env.client_secret}`,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'accept': 'application/json',
                    }
                }
            ).then(res => {
                console.log(res)
            });
        }
    }


    const handleClick = () => {

        //app/mondiapay/pay
        let backend_url = process.env.REACT_APP_BACKEND_URL;

        Axios.get(backend_url + `/app/mondiapay/pay?user_id=${user._id}&access_token=${data.access_token}`).then(res => {
            // console.log(res.data.res._links.initiatePurchase.href)
            window.location.href = res.data.res._links.initiatePurchase.href;

        });


        // var data2 = JSON.stringify({
        //     "redirect": `http://webapp.royakids.tv/mondia/result/${user._id}`,
        //     "subscriptionTypeId": 74290001,
        //     "marketingCampaign": {
        //         "conversionId": "12345",
        //         "agency": "na",
        //         "medium": "na",
        //         "name": "na",
        //         "source": "na"
        //     }
        // });
        // var config = {
        //     method: 'post',
        //     url: 'http://gateway.mondiapay.com/v1/api/purchase/subscription/prepare',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + data.access_token
        //     },
        //     data: data2
        // };

        // Axios(config)
        //     .then(function (response) {
        //         console.log(JSON.stringify(response.data));
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }

    useEffect(() => {

        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/accesscode";
        let formData = new FormData();
        formData.append("access_token", id);
        Axios.post(url,
            formData,
        ).then(response => {
            if (response.data.data !== undefined) {
                setUser(response.data.data);
                //https://royakids.tv/backend/app/mondiapay/token
                Axios.get(backend_url + "/app/mondiapay/token").then(res => {
                    // console.log(res.data.res)
                    setData(res.data.res)
                    setLoading(false);

                    Axios.get(backend_url + `/app/mondiapay/pay?user_id=${user._id}&access_token=${res.data.res.access_token}`).then(res2 => {
                        // console.log(res.data.res._links.initiatePurchase.href)
                        window.location.href = res2.data.res._links.initiatePurchase.href;
            
                    });


                });
            }
            else {
                setError(true)
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ display: load ? 'block' : 'none' }} >

                <section id="footer" >

                    <div className="stage">

                        {/* <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} /> */}
                        {/* <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} /> */}


                    </div>
                    <FadeIn>
                        <div className={"title fadeInAnmthionActive"} >
                            <img className="imgLogo pointer" style={{ width: '150px' }} src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                            {!error && (
                                <div className="btns" style={{ marginTop: '50px', zIndex: '99999999' }}>
                                    {/* <a className="btn1"
                                        onClick={() => {
                                            return !loading ? handleClick() : ''
                                        }}
                                        style={{ width: '250px' }}
                                    >
                                        <img style={{ display: loading ? 'block' : 'none', textAlign: 'center', margin: 'auto' }} id="cloud1" src={require("../assets/img/loading.gif")} />

                                        <span style={{ display: !loading ? 'block' : 'none' }}>{lang1['ResetPasswordScreen'].subscribebtn}</span>

                                    </a> */}
                                    <img style={{ display: 'block', textAlign: 'center', margin: 'auto' }} id="cloud1" src={require("../assets/img/loading.gif")} /> 
                                </div>
                            )}

                            {
                                error && (
                                    <div className="btns" style={{ marginTop: '50px', zIndex: '99999999', fontSize: '20px' }}>
                                        {lang1['ResetPasswordScreen'].error}
                                    </div>
                                )
                            }

                        </div>

                    </FadeIn>

                </section>

            </section>
            {/* <Footer /> */}
        </div>
    );
}