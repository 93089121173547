import React, { useEffect } from 'react';

import Axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import FadeIn from 'react-fade-in';
import { Base64 } from 'js-base64';
import Footer from './pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { PayPalButton } from "react-paypal-button-v2";

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}
export default function Checkout() {
    const { id } = useParams();
    const [data, setData] = React.useState(true);
    const [load, setLoad] = React.useState(true);
    const history = useHistory();
    const alert = useAlert();
    const handleSubmitEnd = (data, order) => {

        if (order.status == 'COMPLETED') {
            var arr = JSON.parse(localStorage.getItem('token'));
            let palnId = localStorage.getItem('subscription').split(",");

            let formData2 = new FormData();
            formData2.append('lang', localStorage.getItem('lang'));
            formData2.append('access_token', arr.access_token);

            formData2.append('platform', 'web');

            formData2.append('planDuration', palnId[1]);
            formData2.append('iduser', arr.UserData._id);
            formData2.append('planId', palnId[0]);
            formData2.append('transactionId', order);

            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getSubscriptioInfo",
                formData2,
            ).then(res => {
                if (res.data.booleanValue) {
                    setLoad(true)
                    localStorage.removeItem('choosePlan');
                    localStorage.removeItem('subscription');
                    history.push(process.env.REACT_APP_FRONT_URL + 'interests');
                }
            });
        }
    }



    useEffect(() => {
        if (localStorage.getItem('subscription') == undefined) {
            history.push(process.env.REACT_APP_FRONT_URL);
        }
        setLoad(true)
        const params = new URLSearchParams(window.location.search) // id=123
        let id = params.get('id') // 123 

        let formData = new FormData();
        formData.append('id', id);
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/hyperpay/step2",
            formData,
        ).then(response2 => {
            let resultCode = response2.data.result.code
            resultCode = resultCode
            var regSucessString = /^(000\.000\.|000\.100\.1|000\.[36])/;
            var reg = new RegExp(regSucessString);
            var regSucessResult = reg.exec(resultCode);
            let partners;
            if (regSucessResult != null) {

                var arr = JSON.parse(localStorage.getItem('token'));
                let palnId = localStorage.getItem('subscription').split(",");

                let formData2 = new FormData();
                formData2.append('lang', localStorage.getItem('lang'));
                formData2.append('access_token', arr.access_token);

                formData2.append('platform', 'web');

                formData2.append('planDuration', palnId[1]);
                formData2.append('iduser', arr.UserData._id);
                formData2.append('planId', palnId[0]);
                formData2.append('transactionId', id);

                Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getSubscriptioInfo",
                    formData2,
                ).then(res => {
                    if (res.data.booleanValue) {
                        setLoad(true)
                        localStorage.removeItem('choosePlan');
                        localStorage.removeItem('subscription');
                        setTimeout(() => {
                            history.push(process.env.REACT_APP_FRONT_URL + 'interests');
                        }, 2000);

                    }
                });


                partners =
                    <div className="checkout">

                        <div className="succhyperpay" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79">
                                <g id="Group_4981" data-name="Group 4981" transform="translate(-919 -426)">
                                    <circle id="Ellipse_409" data-name="Ellipse 409" cx="39.5" cy="39.5" r="39.5" transform="translate(919 426)" fill="#65daa3" />
                                    <path id="Path_2407" data-name="Path 2407" d="M15.712,0,0,.736V31.273" transform="translate(953.876 477.978) rotate(-135)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" />
                                </g>
                            </svg>
                            <b>{lang1['checkout'].sucss}</b>
                        </div>


                    </div >;
            } else {
                partners =
                    <div className="checkout">




                        <div className="errorhyperpay" >
                            <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" viewBox="0 0 79 79">
                                <g id="Group_4982" data-name="Group 4982" transform="translate(-917 -424)">
                                    <circle id="Ellipse_409" data-name="Ellipse 409" cx="39.5" cy="39.5" r="39.5" transform="translate(917 424)" fill="#f74f58" />
                                    <g id="Group_1162" data-name="Group 1162" transform="translate(940.425 449.268)">
                                        <line id="Line_33" data-name="Line 33" x2="44.982" y2="0.929" transform="translate(0 0) rotate(45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" />
                                        <line id="Line_32" data-name="Line 32" x2="0.929" y2="44.981" transform="translate(31.15 0) rotate(45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" />
                                    </g>
                                </g>
                            </svg>

                            <b>{lang1['checkout'].error}</b>
                        </div>


                    </div >;
            }

            setData(partners)
        })

    }, []);


    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ display: load ? 'block' : 'none' }} >

                <section id="footer" >

                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        {/* <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} /> */}


                    </div>
                    <FadeIn>
                        <div className={"title fadeInAnmthionActive"} >
                            <img className="imgLogo pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />


                            {data}

                        </div>

                    </FadeIn>

                </section>

            </section>
            <Footer />
        </div>
    );
}