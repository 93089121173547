import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';

import '../../App.scss';
export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [lang, setLang] = React.useState('En');
    const [aboutShow, setAboutShow] = React.useState(false);
    const [ContactShow, setContactShow] = React.useState(false);
    const [privacyShow, setPrivacyShow] = React.useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {

    }, []);
    let history = useHistory();

    return (
        <div>
            <section id="MobileView"
                style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center', 
                    position:'relative',
                    height:'100vh'

                }}>
                <img src={require("../../assets/img/logo.png")} style={{ width: '50vw', textAlign: 'center' }} />
                <div style={{ color: '#FFA130', fontSize: '35px' }}>Welcome to Roya Kids</div>
                <div style={{ color: '#4F5472', fontSize: '18px',textAlign:'center',padding: '1vw 4vw', paddingBottom: '8vw' }}>Please download the application through Google Play or App Store</div>
                <a href="https://apps.apple.com/us/app/roya-kids/id1533079030" target="_block" style={{zIndex:'10'}}>
                    <img style={{width:'60vw' , marginBottom:'4vw'}} src={require("../../assets/img/Group 5034.png")} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.royakids.tv" target="_block" style={{zIndex:'10'}}>
                    <img style={{width:'60vw'}}  src={require("../../assets/img/Group 5035.png")} />
                </a>
                
                <img src={require("../../assets/img/Group 5036.png")} style={{ position:'absolute',bottom:'0',zIndex:'0' }} />

            </section>


        </div >
    );
}