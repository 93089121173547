import React, { Component } from 'react';


import { BrowserRouter, Route, Link } from "react-router-dom";

let lang = require('../../lang/en').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'En');
    lang = require('../../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../../lang/en').default;
    }
    else {
        lang = require('../../lang/ar').default;
    }
}


class LoginPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoginPopup: this.props.showLoginPopup,
            lang: lang
        }
    }
    componentWillReceiveProps(props) {

        this.setState({
            showLoginPopup: props.showLoginPopup,
        });
    }

    closePopup() {
        this.setState({ showLoginPopup: false })
    }

    render() {

        return (

            <div style={{ display: this.state.showLoginPopup ? 'block' : 'none' }}>

                <section id="LoginPopup" >
                    <div className="colseBtn"
                        onClick={this.closePopup.bind(this)}
                    >X</div>
                    <div className="boxPopup">


                        <div className="contentPopup">
                            <div className="titlecontentPopup">{this.state.lang['profilesSwitchScreen'].title}</div>
                            <Link to={process.env.REACT_APP_FRONT_URL + 'createAccount'} className="btn1">{this.state.lang['profilesSwitchScreen'].buttonCreateAccountLabel}</Link>
                            <Link to={process.env.REACT_APP_FRONT_URL + 'login'} className="btn2">{this.state.lang['profilesSwitchScreen'].buttonLoginLabel}</Link>
                        </div>
                        



                    </div>


                </section>


            </div>
        );
    }
}

export default LoginPopup;