import React, { Fragment, useState, useEffect } from 'react';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import FadeIn from 'react-fade-in';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Footer from '../Component/pageSec/footer';
import { SkipNext } from '@material-ui/icons';

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}


export default function SimpleMenu() {
    const history = useHistory();
    const alert = useAlert();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [valid, setValid] = React.useState(false);
    const [valid2, setValid2] = React.useState(false);
    const [counterScreen, setCounterScreen] = React.useState(null);

    const [stories, setStories] = React.useState([]);
    const [shows, setShows] = React.useState([]);
    const [songs, setSongs] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [hide, setHide] = React.useState(false);
    const [type, setType] = React.useState('Stories');


    const [dataArr, setDataArr] = React.useState([]);

    const [load, setLoad] = React.useState(true);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    const loadData = async () => {
        var arr = JSON.parse(localStorage.getItem('mainProfile'));
        var array = arr ? arr.categorieslist.CategoriesStory.split(",") : [];
        var array1 = arr ? arr.categorieslist.CategoriesSong.split(",") : [];
        var array2 = arr ? arr.categorieslist.CategoriesShow.split(",") : [];
        setStories(array);
        setSongs(array1);
        setShows(array2);

        setTimeout(function () {
            array.map((i) => {
                const element = document.getElementById('item_' + i);
                element.classList.add('itemInterestActive');

            });

        }.bind(this), 500);


    };
    useEffect(() => {

        if (localStorage.getItem('Interests') == undefined) {
            history.push(process.env.REACT_APP_FRONT_URL + '');
        }
        var arr = JSON.parse(localStorage.getItem('token'));

        let formData = new FormData();
        formData.append('access_token', arr.access_token);

        formData.append('lang', localStorage.getItem('lang'));

        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/categorieslist",
            formData,
        ).then(response => {
            setDataArr(response.data);
        });


        if (localStorage.getItem('InterestsEdit') != undefined && localStorage.getItem('InterestsEdit') == '1') {
            loadData();
        }



    }, []);

    const nextSec = () => {

        if (type == 'Stories') {
            if (stories.length == 0) {
                alert.show('Choose at least one!')
            }
            else {
                setHide(true)
                setTimeout(function () {
                    dataArr.result.map((i) => {
                        const element = document.getElementById('item_' + i._id);
                        element.classList.remove('itemInterestActive');

                    })

                    setType('Songs')
                    setCount(count + 1)
                    setHide(false)
                    songs.map((i) => {
                        const element = document.getElementById('item_' + i);
                        element.classList.add('itemInterestActive');

                    });
                }.bind(this), 1000);


            }
        }
        else if (type == 'Songs') {
            if (stories.length == 0) {
                alert.show('Choose at least one!')
            }
            else {
                dataArr.result.map((i) => {
                    const element = document.getElementById('item_' + i._id);
                    element.classList.remove('itemInterestActive');
                })

                setHide(true)
                setTimeout(function () {

                    setType('Shows')
                    setCount(count + 1)
                    setHide(false)
                }.bind(this), 600);

                shows.map((i) => {
                    const element = document.getElementById('item_' + i);
                    element.classList.add('itemInterestActive');

                });
            }
        }
        else {

            var arr = JSON.parse(localStorage.getItem('token'));
            let formData = new FormData();
            ////console.log("getAccessTokenTemp() = " + getAccessTokenTemp());
            formData.append('profile_id', localStorage.getItem('Interests'));
            formData.append('access_token', arr.access_token);
            formData.append('CategoriesStory', stories);
            formData.append('CategoriesSong', songs);
            formData.append('CategoriesShow', shows);
            //console.log("ServiceUpdateUserInterestsController!!!!");
            //console.log("formData = " + JSON.stringify(formData));

            formData.append('lang', localStorage.getItem('lang'));


            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/updateInterests",
                formData,
            ).then(response => {
                //settings
                if (localStorage.getItem('InterestsEdit') != undefined && localStorage.getItem('InterestsEdit') == '1') {

                    let formData2 = new FormData();
                    formData2.append('access_token', arr.access_token);

                    formData2.append('lang', localStorage.getItem('lang'));

                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getProfile",
                        formData2,
                    ).then(Profiles => {
                        if (Profiles.data.message == 'Failed Auth.') {
                            localStorage.removeItem('token');
                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                        }
                        Profiles.data.newarray.map((pr) => {
                            if (pr.mainProfile) {
                                localStorage.setItem('mainProfile', JSON.stringify(pr));
                                localStorage.removeItem('InterestsEdit');
                                history.push(process.env.REACT_APP_FRONT_URL + 'settings' + '/' + localStorage.getItem('Interests') + '/edit');
                                localStorage.removeItem('Interests');
                            }
                        })
                        localStorage.setItem('Profiles', JSON.stringify(Profiles.data));
                    })
                }
                else {

                    history.push(process.env.REACT_APP_FRONT_URL + 'home');
                }
            })
        }



    }
    const SkipNext = () => {
        if (type == 'Stories') {
            setHide(true)
            setTimeout(function () {
                dataArr.result.map((i) => {
                    const element = document.getElementById('item_' + i._id);
                    element.classList.remove('itemInterestActive');

                })

                setType('Songs')
                setCount(count + 1)
                setHide(false)
            
            }.bind(this), 1000);


        }
        else if (type == 'Songs') {
            dataArr.result.map((i) => {
                const element = document.getElementById('item_' + i._id);
                element.classList.remove('itemInterestActive');
            })

            setHide(true)
            setTimeout(function () {

                setType('Shows')
                setCount(count + 1)
                setHide(false)
            }.bind(this), 600);

           
        }
        else {

            var arr = JSON.parse(localStorage.getItem('token'));
            let formData = new FormData();
            ////console.log("getAccessTokenTemp() = " + getAccessTokenTemp());
            formData.append('profile_id', localStorage.getItem('Interests'));
            formData.append('access_token', arr.access_token);
            formData.append('CategoriesStory', stories);
            formData.append('CategoriesSong', songs);
            formData.append('CategoriesShow', shows);
            //console.log("ServiceUpdateUserInterestsController!!!!");
            //console.log("formData = " + JSON.stringify(formData));

            formData.append('lang', localStorage.getItem('lang'));


            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/updateInterests",
                formData,
            ).then(response => {
                //settings
                if (localStorage.getItem('InterestsEdit') != undefined && localStorage.getItem('InterestsEdit') == '1') {

                    let formData2 = new FormData();
                    formData2.append('access_token', arr.access_token);

                    formData2.append('lang', localStorage.getItem('lang'));

                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/getProfile",
                        formData2,
                    ).then(Profiles => {
                        if (Profiles.data.message == 'Failed Auth.') {
                            localStorage.removeItem('token');
                            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                        }
                        Profiles.data.newarray.map((pr) => {
                            if (pr.mainProfile) {
                                localStorage.setItem('mainProfile', JSON.stringify(pr));
                                localStorage.removeItem('InterestsEdit');
                                history.push(process.env.REACT_APP_FRONT_URL + 'settings' + '/' + localStorage.getItem('Interests') + '/edit');
                                localStorage.removeItem('Interests');
                            }
                        })
                        localStorage.setItem('Profiles', JSON.stringify(Profiles.data));
                    })
                }
                else {

                    history.push(process.env.REACT_APP_FRONT_URL + 'home');
                }
            })
        }


    }
    const SelectInterst = (id, type) => {

        if (type == 'Stories') {
            let tt = true;
            const element = document.getElementById('item_' + id);
            stories.map((item) => {
                if (item == id) {
                    tt = false;
                }
            });
            if (tt) {
                setStories([...stories, id]);
                element.classList.add('itemInterestActive')

            }
            else {
                setStories(stories.filter(item => item !== id));
                element.classList.remove('itemInterestActive')
            }
        }


        else if (type == 'Shows') {
            let tt = true;
            const element = document.getElementById('item_' + id);
            shows.map((item) => {
                if (item == id) {
                    tt = false;
                }
            });
            if (tt) {
                setShows([...shows, id]);
                element.classList.add('itemInterestActive')

            }
            else {
                setShows(shows.filter(item => item !== id));
                element.classList.remove('itemInterestActive')
            }
        }


        else if (type == 'Songs') {
            let tt = true;
            const element = document.getElementById('item_' + id);
            songs.map((item) => {
                if (item == id) {
                    tt = false;
                }
            });
            if (tt) {
                setSongs([...songs, id]);
                element.classList.add('itemInterestActive')

            }
            else {
                setSongs(songs.filter(item => item !== id));
                element.classList.remove('itemInterestActive')
            }
        }


    }
    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" >
                <section id="footer" >
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        {/* <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} /> */}


                    </div>
                    <FadeIn>
                        <div className="buttonSkip" onClick={() => SkipNext()}>{lang1['InterestsStoriesScreen'].buttonSkip} </div>
                        <div className="title fadeInAnmthionActive" style={{ opacity: hide ? 0 : 1, transition: 'all 1.2s' }} >
                            <div className="textTitle">
                                {type == 'Stories' ? lang1['InterestsStoriesScreen'].title : type == 'Songs' ? lang1['InterestsSongsScreen'].title : type == 'Shows' ? lang1['InterestsShowsScreen'].title : ''}
                                {/* Choose {type.charAt(0).toUpperCase() + type.slice(1)} Interests */}
                            </div>
                            <div className="Interests">
                                {dataArr.result && dataArr.result.map((i) => {
                                    return (
                                        <div key={i._id} className="itemInterest" id={'item_' + i._id} onClick={() => {
                                            SelectInterst(i._id, type);
                                        }}>
                                            <img src={dataArr.backendurl + (i.imgUpload == '' || i.imgUpload == null ? 'emp.png' : i.imgUpload)} />
                                            <div className="titleInt">{i.title}</div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="btns">
                                <a className={"btn1"}
                                    onClick={() => {
                                        nextSec()
                                    }}
                                >{count == 2 ? lang1['InterestsStoriesScreen'].finish : lang1['InterestsStoriesScreen'].buttonNext}</a>
                            </div>


                        </div>


                    </FadeIn>

                </section>

            </section>

            {/* <Footer /> */}
        </div>
    );
}