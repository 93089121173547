import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Footer from '../Component/pageSec/footer';


let lang = require('../lang/ar').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'Ar');
    lang = require('../lang/ar').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}



var url_string = window.location.href
var url = new URL(url_string);
var c = url.searchParams.get("lang");
if (c != null) {
    if (c === 'En') {
        localStorage.setItem('lang', 'En');
        lang = require('../lang/en').default; 
    }
    else {
        localStorage.setItem('lang', 'Ar');
        lang = require('../lang/ar').default; 
    }
}


class Header extends Component {
    state = {
        loginSta: false,
        lang: lang
    }
    componentDidMount() {

     

        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.setState({
                loginSta: true
            })
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined) {
                    // this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                    this.setState({
                        loginSta: true
                    })
                }
                else {

                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
                }
            })

        }
    }
    goToFreeMode() {
        localStorage.setItem('token', JSON.stringify({ access_token: 'guest' }));
        this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
    }
    render() {
        return (
            <div style={{ visibility: this.state.loginSta ? 'visible' : 'hidden', opacity: this.state.loginSta ? '1' : '0', transition: 'all 0.5s' }}>
                <section id="header" >
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>

                    <div className="title">
                        <div className="img">
                            <img src={require("../assets/img/logo.png")} />
                        </div>
                        <div className="textTitle">
                            {this.state.lang['authenticationScreen'].title}
                        </div>
                        <div className="btns">
                            <Link to={process.env.REACT_APP_FRONT_URL + 'CreateAccount'} className="btn1">
                                {this.state.lang['authenticationScreen'].buttonCreateAccount}
                            </Link>
                            <Link to={process.env.REACT_APP_FRONT_URL + 'login'} className="btn2">
                                {this.state.lang['authenticationScreen'].buttonLogin}
                            </Link>
                        </div>
                        <div className="btnsFree">
                            <button onClick={() => {
                                this.goToFreeMode()
                            }}>
                                {this.state.lang['authenticationScreen'].buttonGuestUser}</button>
                        </div>


                    </div>


                </section>

                <Footer />
            </div>
        );
    }
}

export default Header;