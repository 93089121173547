import React, { Fragment, useState, useEffect } from 'react';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import { useAlert } from "react-alert";

import UnderPopup from './underpopup';


let lang1 = require('../../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../../lang/en').default;
    }
    else {
        lang1 = require('../../lang/ar').default;
    }
}




export default function Playlist(props) {

    const [show, setShow] = React.useState(props.show);
    const [showCreate, setShowCreate] = React.useState(false);
    const [nameNewPlayList, setNameNewPlayList] = React.useState('');
    const [profile, setProfile] = React.useState(null);
    const [playListItems, setPlayListItems] = React.useState([]);

    const [showPopup, setShowPopup] = React.useState(false)
    const [textPopup, setTextPopup] = React.useState('');

    let history = useHistory();
    const alert = useAlert();



    React.useEffect(() => {
        setShow(props.show);
        setNameNewPlayList('')
        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);

        let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));
        let profileID = '';
        profileID = mainProfile ? mainProfile._id : '';
        setProfile(profileID)

        formData.append('profileId', profileID);

        formData.append('lang', localStorage.getItem('lang'));

        formData.append('content_id', props.contentId);
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getListPlayList",
            formData,
        ).then(response => {
            setPlayListItems(response.data.items);
            //return; 
        })



    }, [props.show]);


    const createNewPlaylist = () => {
        let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
        let mainProfile = JSON.parse(localStorage.getItem('mainProfile'));

        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('name', nameNewPlayList);
        formData.append('profileId', mainProfile._id);
        formData.append(props.type + 'list', JSON.stringify([props.contentId]));

        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/create",
            formData,
        ).then(response => {
            if (response.data.booleanValue) {
                setShowPopup(true);
                setTextPopup(response.data.msg);
                setTimeout(function () {
                    setShowPopup(false);
                    setTextPopup('');
                }.bind(this), 1000);

                setShowCreate(false);
                setShow(!show);
            }
        })


    }

    const addtoPlaylist = (id) => {
        var arr = JSON.parse(localStorage.getItem('token'));
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('playListId', id);
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/getContent",
            formData,
        ).then(response => {
            //props.contentId
            // console.log(props.type)
            let songsArr = [];
            let storiesArr = [];
            let showsArr = [];
            if (props.type == 'songs') {
                response.data.allarray[0].songslist.map((item) => {
                    songsArr.push(item._id)
                });
                response.data.allarray[1].showslist.map((item) => {
                    showsArr.push(item._id)
                });
                response.data.allarray[2].storieslist.map((item) => {
                    storiesArr.push(item._id)
                });

                songsArr.push(props.contentId)
            }
            else if (props.type == 'shows') {
                response.data.allarray[0].songslist.map((item) => {
                    songsArr.push(item._id)
                });
                response.data.allarray[1].showslist.map((item) => {
                    showsArr.push(item._id)
                });
                response.data.allarray[2].storieslist.map((item) => {
                    storiesArr.push(item._id)
                });
                showsArr.push(props.contentId)
            }
            else if (props.type == 'stories') {
                response.data.allarray[0].songslist.map((item) => {
                    songsArr.push(item._id)
                });
                response.data.allarray[1].showslist.map((item) => {
                    showsArr.push(item._id)
                });
                response.data.allarray[2].storieslist.map((item) => {
                    storiesArr.push(item._id)
                });
                storiesArr.push(props.contentId)
            }
            ////////////////////////////

            let formData2 = new FormData();
            formData2.append('access_token', arr.access_token);
            formData2.append('name', response.data.playListName);
            formData2.append('profileId', profile);
            formData2.append('songslist', JSON.stringify(songsArr));
            formData2.append('showslist', JSON.stringify(showsArr));
            formData2.append('storieslist', JSON.stringify(storiesArr));

            formData2.append('lang', localStorage.getItem('lang'));

            formData2.append('playlist_id', id);

            Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/playlist/edit",
                formData2,
            ).then(response => {
                setShowPopup(true);
                setTextPopup(response.data.msg);
                setTimeout(function () {
                    setShowPopup(false);
                    setTextPopup('');
                }.bind(this), 1000); 
                setShow(!show)
            })
            //return; 
        })
    };
    return (
        <div>
            <section id="under" style={{ visibility: showPopup ? 'visible' : 'hidden', opacity: showPopup ? '1' : '0', transition: 'opacity 1.5' }}>
                <div className="boxPopup">
                    <div className="contentPopup">
                        <div className="titlecontentPopup">{textPopup} </div>

                    </div>
                </div>
            </section>
            <section id="playlist" style={{ visibility: show ? 'visible' : 'hidden', opacity: show ? '1' : '0', transition: 'opacity 2.5' }}>
                <div className="colseBtn"
                    onClick={() => setShow(!show)}
                >X</div>
                <div className="boxPopup">

                    <div className="contentPopup">
                        <div className="titlecontentPopup">{lang1['AddToPlaylistPopup'].title}</div>

                    </div>
                    <div className="descriptionPopup">
                        <div className="descriptionPopup">
                            <div className="btn" onClick={() => {
                                setShowCreate(true)
                            }}>{lang1['AddToPlaylistPopup'].ButtonCreatePlaylistLabel}</div>

                            <div className="list">
                                {
                                    playListItems.length != 0 ?
                                        playListItems.map((item, index) => {
                                            if (!item.addedBefore) {
                                                return <div className="itemList" key={index} onClick={() => {
                                                    addtoPlaylist(item._id)
                                                }}>
                                                    <div className="imgsList">
                                                        {item.songsImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.songsImg[0]} /> : ''}
                                                        {item.songsImg[1] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.songsImg[1]} /> : ''}
                                                        {item.showsImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.showsImg[0]} /> : ''}
                                                        {item.storiesImg[0] ? <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.storiesImg[0]} /> : ''}

                                                    </div>

                                                    <div className="nameplaylist">{item.namePlayList}</div>

                                                    <div className="addtoplaylist"></div>
                                                </div>
                                            }

                                        })
                                        : ''
                                }

                            </div>
                        </div>
                    </div>


                </div>


            </section>
            <section id="CreatePlaylist" style={{ visibility: showCreate ? 'visible' : 'hidden', opacity: showCreate ? '1' : '0', transition: 'opacity 2.5' }} >
                <div className="colseBtn"
                    onClick={() => {
                        setShowCreate(false)
                    }}
                >X</div>
                <div className="boxPopup">

                    <div className="contentPopup">
                        <div className="titlecontentPopup">{lang1['AddToPlaylistPopup'].ButtonCreatePlaylistLabel}</div>

                    </div>
                    <div className="descriptionPopup">
                        <div className="descriptionPopup">
                            <div className="input">
                                <input placeholder={lang1['CreatePlaylistNamePopup'].inputTxtLabel}
                                    value={nameNewPlayList}
                                    onChange={(e) => {
                                        setNameNewPlayList(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="btn"
                                onClick={createNewPlaylist}
                            >{lang1['CreatePlaylistNamePopup'].ButtonCreateLabel}</div>
                        </div>
                    </div>


                </div>


            </section>
        </div>
    );
}