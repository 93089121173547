import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import ReactCodeInput from 'react-verification-code-input';
import FadeIn from 'react-fade-in';
import firebase from 'firebase';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import TwitterLogin from "react-twitter-login";

import Footer from '../Component/pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}


export default function SimpleMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [valid, setValid] = React.useState(true);
    const [valid2, setValid2] = React.useState(false);
    const [loginType, setLoginType] = React.useState('email');
    const [showPage, setShowPage] = React.useState(true);

    const [loading, setLoading] = React.useState(false);

    const [verifyyour, setVerifyyour] = React.useState(lang1['RegisterationPhoneNumberVerificationScreen'].title);

    //require("../assets/img/Group 1380.png")
    const [iconPage, setIconPage] = React.useState(require("../assets/img/Group 1360.png"));
    //
    const [verificationType, setVerificationType] = React.useState('phone');
    const [code, setcode] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [socialMediaUserID, setSocialMediaUserID] = React.useState('');


    const [msgError, setMsgError] = React.useState('The verification code you entered is incorrect');

    const [showAlert, setshowAlert] = React.useState([]);


    const [confirmationResult, setConfirmationResult] = React.useState('');

    const history = useHistory();
    const alert = useAlert();

    const firebaseConfig = {
        apiKey: "AIzaSyApLx7YXYSjrTunAHYYEnOUmQgOA9CLwvA",
        authDomain: "roya-kids.firebaseapp.com",
        databaseURL: "https://roya-kids.firebaseio.com",
        projectId: "roya-kids",
        storageBucket: "roya-kids.appspot.com",
        messagingSenderId: "64363108088",
        appId: "1:64363108088:web:c0022e3615d8d4caee8d0d"
    };


    const sendtoEmail = () => {
        setLoading(true)
        let formData = new FormData();
        formData.append('email', JSON.parse(localStorage.getItem('reg_id_user_email')));
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/verificationbyemail",
            formData,
        ).then(response => {
            console.log(response.data)
            setVerificationType('email')
            setLoading(false)
            setVerifyyour(lang1['RegisterationPhoneNumberVerificationScreen'].titleEmail);
            setIconPage(require("../assets/img/Group 1380.png"));

        })
    }



    const resendCode = () => {
        if (verificationType == 'phone') {
            firebase.auth().signInWithPhoneNumber('+' + phone, window.recaptchaVerifier)
                .then((confirmationResult) => {
                    setConfirmationResult(confirmationResult)
                })
        }
        else {
            sendtoEmail()
        }

    }



    const handleSubmitEnd = () => {


        if (verificationType == 'phone') {
            if (code.length == 6) {
                confirmationResult.confirm(code).then((result) => {
                    // User signed in successfully.
                    const user = result.user;
                    // ...
                    setErrorMsg(false)
                    history.push(process.env.REACT_APP_FRONT_URL + `createprofile`);

                }).catch((error) => {
                    // User couldn't sign in (bad verification code?)

                    setErrorMsg(true)
                    // ...
                });

            }
            else {
                setErrorMsg(true)
            }
        }
        else {
            if (code.length == 6) {
                setLoading(true)
                let formData = new FormData();
                formData.append('email', JSON.parse(localStorage.getItem('reg_id_user_email')));
                formData.append('code', code);
                formData.append('lang', localStorage.getItem('lang'));
                Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/checkverificationbyemail",
                    formData,
                ).then(response => {
                    if (response.data.booleanValue) {
                        setErrorMsg(false)
                        history.push(process.env.REACT_APP_FRONT_URL + `createprofile`);
                    }
                    else {
                        setMsgError(response.data.msg)
                        setErrorMsg(true)
                    }
                    setLoading(false)

                })
            }
            else {
                setErrorMsg(true)
            }
        }

    }




    const responseFacebook = (response) => {
        registerBySocial(response.name, response.id, 'facebook')
    }
    const responseGoogle = (response) => {
        // console.log(response);
        registerBySocial(response.profileObj.givenName, response.googleId, 'google')
    }
    const authHandler = (err, data) => {
        registerBySocial(data.screen_name, data.user_id, 'twitter')
    };



    const registerBySocial = (name, id, type) => {
        //accountId
        //accountName
        //type
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/registerFirstStep";
        let formData = new FormData();
        formData.append('type', type);
        formData.append('accountId', id);
        formData.append('accountName', name);
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(url,
            formData,
        ).then(response => {
            if (response.data.booleanValue) {
                localStorage.setItem('reg_id_user', JSON.stringify(response.data.iduser));
                localStorage.setItem('reg_id', JSON.stringify(id));
                localStorage.setItem('type', JSON.stringify(type));
                history.push(process.env.REACT_APP_FRONT_URL + `createprofile`);
            }
            else {
                alert.error(response.data.message);
            }
        })
            .catch((error) => {
                console.log(error)
            });


    }
    const handleSubmit = (event) => {
        setshowAlert([])
        let formIsValid = true;



        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        if (!emailRegexp.test(email)) {
            setshowAlert(oldArray => [...oldArray, lang1['AlertMessages'].invalidEmail]);
            formIsValid = false;
        }
        else {
            setValid(true);
        }
        if (password.length < 6) {
            setshowAlert(oldArray => [...oldArray, lang1['AlertMessages'].minPasswordLength]);
            formIsValid = false;
        }
        else {
            setValid(true);
        }
        // if (phone.length != 12) {
        //     setshowAlert(oldArray => [...oldArray, lang1['AlertMessages'].onInvalidMobileNumber]);
        //     formIsValid = false;
        // }

        if (formIsValid) {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/registerFirstStep";
            let formData = new FormData();
            formData.append('type', loginType);
            if (loginType != 'email') {
                formData.append('accountId', socialMediaUserID);
            }
            else {

                formData.append('email', email);
                formData.append('password', password);
            }

            formData.append('lang', localStorage.getItem('lang'));
            Axios.post(url,
                formData,
            ).then(response => {
                // console.log(response.data)
                if (response.data.booleanValue) {
                    localStorage.setItem('reg_id_user', JSON.stringify(response.data.iduser));
                    localStorage.setItem('reg_id_user_phone', JSON.stringify(phone));
                    localStorage.setItem('reg_id_user_email', JSON.stringify(email));
                    localStorage.setItem('reg_id_user_password', JSON.stringify(password));
                    localStorage.setItem('type', JSON.stringify('email'));
                    // setValid2(true)
                    // firebase.auth().signInWithPhoneNumber('+' + phone, window.recaptchaVerifier)
                    //     .then((confirmationResult) => {
                    //         setConfirmationResult(confirmationResult)
                    //     })
                    history.push(process.env.REACT_APP_FRONT_URL + `createprofile`);

                }
                else {
                    setshowAlert(oldArray => [...oldArray, response.data.message]);
                    setValid(false);
                }
            })
                .catch((error) => {
                    console.log(error)
                });
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    const handleChangeCode = (vals) => {
        setcode(vals)
    }
    useEffect(() => {  
        if (props.location.search.search('oauth_verifier') == -1) {
            setShowPage(false)
        }

        if (!firebase.apps.length) {


            firebase.initializeApp(firebaseConfig);


        }
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
        });


        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <div style={{ display: showPage ? 'none' : 'block' }}>
            <Backdrop className="backdrop" open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ opacity: loading ? 0 : 1, visibility: loading ? 'hidden' : 'visible', transition: 'all 1s' }}>
                <section id="footer" >
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        <img id="backbtnImg" className="img pointer" src={require("../assets/img/logo.png")} onClick={() => { history.push(process.env.REACT_APP_FRONT_URL) }} />

                        {/* <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} /> */}


                    </div>
                    <FadeIn>

                        <div className={!valid2 ? "title fadeInAnmthionActive" : "title fadeInAnmthion"} >
                            <div className="img">
                                <img src={require("../assets/img/Group 535.png")} />
                            </div>
                            <div className="textTitle">
                                {lang1['createAccountScreen'].title}
                            </div>
                            <div className="errorMsg" style={{ opacity: showAlert.length != 0 ? '1' : '0', visibility: showAlert.length != 0 ? 'visible' : 'hidden', transition: 'all 1s', height: showAlert.length != 0 ? 'auto' : '0' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="33" viewBox="0 0 29 33">
                                    <g id="Group_4992" data-name="Group 4992" transform="translate(-13 -9.802)">
                                        <g id="Group_1332" data-name="Group 1332" transform="translate(13.213 12.27)">
                                            <circle id="Ellipse_386" data-name="Ellipse 386" cx="14.5" cy="14.5" r="14.5" transform="translate(-0.213 -0.468)" fill="#fff" />
                                        </g>
                                        <text id="_" data-name="!" transform="translate(30, 36.802)" fill="#fd7777" font-size="25" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">!</tspan></text>
                                    </g>
                                </svg>

                                <ul style={{ margin: '1vw', padding: '0' }}>
                                    {showAlert.map((item) => {
                                        return <li>{item}</li>
                                    })}
                                </ul>

                            </div>
                            <div className="input">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.312" height="15.755" viewBox="0 0 19.312 15.755">
                                    <g id="Group_1159" data-name="Group 1159" transform="translate(1 1)">
                                        <path id="Path_62" data-name="Path 62" d="M1138.33,560.429v8.736a2.51,2.51,0,0,0,2.509,2.509h12.294a2.51,2.51,0,0,0,2.509-2.509v-8.736a2.46,2.46,0,0,0-.522-1.526,2.486,2.486,0,0,0-1.987-.984h-12.294a2.509,2.509,0,0,0-2.509,2.509Z" transform="translate(-1138.33 -557.92)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_63" data-name="Path 63" d="M1139.37,558.9l5.635,5.635a3.541,3.541,0,0,0,5,0l5.635-5.635a2.486,2.486,0,0,0-1.987-.984h-12.294A2.5,2.5,0,0,0,1139.37,558.9Z" transform="translate(-1138.853 -557.92)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_64" data-name="Path 64" d="M1145.3,571.63a2.741,2.741,0,0,0-.291.256l-5.635,5.635a2.5,2.5,0,0,0,1.992.984h12.294a2.486,2.486,0,0,0,1.987-.984l-5.635-5.635a2.364,2.364,0,0,0-.3-.256" transform="translate(-1138.853 -564.75)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </svg>

                                <input type="text" placeholder={lang1['createAccountScreen'].inputEmail}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="input">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.19" height="21.958" viewBox="0 0 19.19 24.958">
                                    <g id="Group_1160" data-name="Group 1160" transform="translate(1 1)">
                                        <path id="Path_66" data-name="Path 66" d="M1155.522,617.12V625.8a2.492,2.492,0,0,1-2.491,2.491h-12.208a2.492,2.492,0,0,1-2.491-2.491V617.12a2.5,2.5,0,0,1,2.491-2.491h12.208a2.49,2.49,0,0,1,2.491,2.491Z" transform="translate(-1138.332 -605.328)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_67" data-name="Path 67" d="M1155.545,605.23v-3.916a5.352,5.352,0,1,0-10.7,0v3.916Z" transform="translate(-1141.597 -595.962)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_68" data-name="Path 68" d="M1153.3,624.063V622.62a1.138,1.138,0,0,1,1.141-1.142,1.131,1.131,0,0,1,1.135,1.142v1.443" transform="translate(-1145.843 -608.764)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                        <path id="Path_69" data-name="Path 69" d="M1155.57,628.327a2.277,2.277,0,1,1-2.277-2.277A2.278,2.278,0,0,1,1155.57,628.327Z" transform="translate(-1144.696 -611.058)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </svg>

                                <input type="password" placeholder={lang1['createAccountScreen'].inputPassword}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}
                                />
                            </div>
                            {/* <div className="inputPhone">
                                <PhoneInput
                                    country={'jo'}
                                    enableSearch={true}
                                    disableSearchIcon={true}

                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        disableSearchIcon: false.valueOf,

                                    }}
                                    onChange={phone => setPhone(phone)}
                                />
                            </div> */}


                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >{lang1['createAccountScreen'].buttonNext}</a>
                            </div>
                            <div className="otherlogin">
                                <p>{lang1['createAccountScreen'].or}</p>
                                <div className="socailMedia">
                                    <FacebookLogin
                                        appId="227181481933982"
                                        callback={responseFacebook}
                                        fields="name,email,picture"
                                        cssClass="my-facebook-button-class"
                                        icon={<img src={require("../assets/img/fb.png")} />}

                                    />
                                    {/* <GoogleLogin
                                        clientId="64363108088-5b7uht7189ejcdk7mt997plalp4d0388.apps.googleusercontent.com"
                                        buttonText="Login"
                                        render={renderProps => (
                                            <img onClick={renderProps.onClick} disabled={renderProps.disabled} src={require("../assets/img/go.png")} />
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    /> */}
                                    <TwitterLogin
                                        authCallback={authHandler}
                                        consumerKey={'O2dCvYC0qCzcXf7WFOsdPmzbp'}
                                        consumerSecret={'6TIUntSq8vXyDsZulV7bMoN5tA1Bm8JC9L8Kemrk16DACN5DCT'}
                                        className="TwitterLogin"
                                    >
                                        <svg viewBox="0 0 24 24" aria-hidden="true" class="r-1fmj7o5 r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-10uooop"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg>
                                    </TwitterLogin>
                                    {/* <img src={require("../assets/img/Group 4870.png")} /> */}
                                    {/* <img src={require("../assets/img/Group 4869.png")} /> */}
                                    {/* <img src={require("../assets/img/Group 4868.png")} /> */}
                                </div>
                                <div className="signup">{lang1['createAccountScreen'].signuptxt} <Link to={process.env.REACT_APP_FRONT_URL + 'login'} className="btn2">{lang1['createAccountScreen'].signup}</Link></div>
                            </div>

                        </div>


                    </FadeIn>
                    <FadeIn>

                        <div className={valid2 ? "title fadeInAnmthionActive" : "title fadeInAnmthion"}>
                            <div className="img">
                                <img src={iconPage} />
                            </div>
                            <div className="textTitle">
                                {verifyyour}
                            </div>
                            <div className="errorMsg" style={{ opacity: errorMsg ? '1' : '0' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="33" viewBox="0 0 29 33">
                                    <g id="Group_4992" data-name="Group 4992" transform="translate(-13 -9.802)">
                                        <g id="Group_1332" data-name="Group 1332" transform="translate(13.213 12.27)">
                                            <circle id="Ellipse_386" data-name="Ellipse 386" cx="14.5" cy="14.5" r="14.5" transform="translate(-0.213 -0.468)" fill="#fff" />
                                        </g>
                                        <text id="_" data-name="!" transform="translate(25 36.802)" fill="#fd7777" font-size="25" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">!</tspan></text>
                                    </g>
                                </svg>

                                {msgError}</div>
                            <div className="inputPhoneV">
                                <ReactCodeInput fields={6} onChange={handleChangeCode} autoFocus={true} />

                            </div>

                            <div className="btns">
                                <a className="btn1"
                                    onClick={() => {
                                        handleSubmitEnd();
                                    }}
                                >{lang1['RegisterationPhoneNumberVerificationScreen'].buttonNextLabel}</a>
                            </div>
                            <div id="recaptcha-container"></div>
                            <div className="textunderphone" style={{ marginBottom: '2vw' }}>
                                {lang1['RegisterationPhoneNumberVerificationScreen'].instructionsText} <a
                                    onClick={() => {
                                        resendCode();
                                    }}
                                > {lang1['RegisterationPhoneNumberVerificationScreen'].buttonResendCodeLabel}</a>
                            </div>
                            <div className="textunderphone" style={{ display: verificationType == 'email' ? 'none' : '' }}>
                                {lang1['RegisterationEnterPhoneNumberScreen'].instructionsText} <a onClick={() => {
                                    sendtoEmail();
                                }}>{lang1['RegisterationEnterPhoneNumberScreen'].buttonSendEmailLabel} </a>
                            </div>

                        </div>
                    </FadeIn>


                </section>

            </section>

            <Footer />
        </div>
    );
}