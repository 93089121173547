import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';




let lang1 = require('../../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '' ) {
    localStorage.setItem('lang', 'En');
    lang1 = require('../../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../../lang/en').default;
    }
    else {
        lang1 = require('../../lang/ar').default;
    }
}



export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [plan, setPlan] = React.useState([]);
  const [load, setLoad] = React.useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    localStorage.setItem('loginChoosePlan', plan.subscriptionPrice);
    setLoad(false);
    var arr = JSON.parse(localStorage.getItem('token'));
    if (arr == undefined) {
      this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
    }
    else {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      const url = backend_url + "/app/accesscode";
      let formData = new FormData();
      formData.append("access_token", arr.access_token);
      Axios.post(url,
        formData,
      ).then(response => {
        if (response.data.data == undefined) {
          this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
          let backend_url = process.env.REACT_APP_BACKEND_URL;
          const url = backend_url + "/app/getPlanUser";
          let formData = new FormData();
          formData.append('access_token', arr.access_token);

          formData.append('lang',localStorage.getItem('lang'));

          Axios.post(url,
            formData,
          ).then(response2 => { 
            setLoad(true);
            setPlan(response2.data)

          })
        }
      })

    }
  }, []);
  let history = useHistory();



  return (
    <div>
      {/* <Backdrop className="backdrop" open={!load}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <section id="Subscription" style={{ display: load ? 'block' : 'none' }} >

        <div className="SubscriptionCurrent">{lang1['CurrentSubscriptionScreen'].title}</div>

        <div className="planuser">
          <img src={require("../../assets/img/Subscription.png")} />
          <div className="detName">
            <div className="planName">{plan.planName}</div>
            {<div className="planPrice" style={{ display: plan.planId == 'plan_1' ? 'none' : 'block' }}>{plan.subscriptionPrice}</div>}
            <div className="planRenew" style={{ display: plan.planId == 'plan_1' ? 'none' : 'block' }}><a>{lang1['CurrentSubscriptionScreen'].renewDate}</a> {plan.renewDate}</div>
          </div>
        </div>
        <div className="btns">
          <a className="btn1" onClick={() => {
            localStorage.setItem('choosePlan', JSON.parse(localStorage.getItem('token')).UserData._id);
            localStorage.setItem('loginChoosePlan', plan.subscriptionPrice);
            history.push(process.env.REACT_APP_FRONT_URL + 'subscription');
          }}>{lang1['CurrentSubscriptionScreen'].upgradeSubscriptionButtonLabel}</a>
        </div>
      </section>


      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ borderRadius: '20px' }}
      >
        <MenuItem onClick={handleClose}><svg width="69" height="69" viewBox="0 0 69 69">
          <g id="Group_4985" data-name="Group 4985" transform="translate(-998 -755)">
            <circle id="Ellipse_296" data-name="Ellipse 296" cx="34.5" cy="34.5" r="34.5" transform="translate(998 755)" fill="#7b67db" />
            <g id="Group_4971" data-name="Group 4971" transform="translate(1010.409 763.365)">
              <g id="Group_2228" data-name="Group 2228" transform="translate(18.408 16.74)">
                <path id="Path_1904" data-name="Path 1904" d="M-466.612-2099.369a7.172,7.172,0,0,1-7.173,7.173,7.172,7.172,0,0,1-7.173-7.173,7.173,7.173,0,0,1,7.173-7.173A7.173,7.173,0,0,1-466.612-2099.369Z" transform="translate(485.599 2106.542)" fill="#fff" />
                <path id="Path_1906" data-name="Path 1906" d="M-478.693-2036.243v4.083a4.426,4.426,0,0,1-4.425,4.426H-497.9a4.427,4.427,0,0,1-4.425-4.426v-4.083c0-5.3,1.9-15.328,1.9-15.328l12.138,5.74A9.588,9.588,0,0,1-478.693-2036.243Z" transform="translate(502.322 2059.333)" fill="#fff" />
              </g>
              <g id="Group_2229" data-name="Group 2229" transform="translate(5.602 13.699)">
                <path id="Path_1904-2" data-name="Path 1904" d="M-460.53-2096.328a10.214,10.214,0,0,1-10.214,10.214,10.214,10.214,0,0,1-10.214-10.214,10.214,10.214,0,0,1,10.214-10.213A10.214,10.214,0,0,1-460.53-2096.328Z" transform="translate(480.958 2106.542)" fill="#7b67db" />
                <path id="Path_1906-2" data-name="Path 1906" d="M-478.693-2033.256v4.745c0,2.839-1.98,5.143-4.425,5.143H-497.9c-2.443,0-4.425-2.3-4.425-5.143v-4.745c0-6.155,4.295-11.144,9.589-11.144h4.452C-482.986-2044.4-478.693-2039.411-478.693-2033.256Z" transform="translate(502.322 2060.942)" fill="#7b67db" />
              </g>
              <g id="Group_2230" data-name="Group 2230" transform="translate(0 16.74)">
                <path id="Path_1904-3" data-name="Path 1904" d="M-466.612-2099.369a7.172,7.172,0,0,1-7.173,7.173,7.172,7.172,0,0,1-7.173-7.173,7.173,7.173,0,0,1,7.173-7.173A7.173,7.173,0,0,1-466.612-2099.369Z" transform="translate(485.599 2106.542)" fill="#fff" />
                <path id="Path_1906-3" data-name="Path 1906" d="M-478.693-2034.811v4.083a4.426,4.426,0,0,1-4.425,4.426H-497.9a4.427,4.427,0,0,1-4.425-4.426v-4.083a9.589,9.589,0,0,1,9.589-9.589h4.452A9.588,9.588,0,0,1-478.693-2034.811Z" transform="translate(502.322 2057.901)" fill="#fff" />
              </g>
              <g id="Group_2231" data-name="Group 2231" transform="translate(12.122)">
                <path id="Path_323" data-name="Path 323" d="M0,0,4.063,4.063,0,8.126" transform="translate(22.19 8.874) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_2580" data-name="Path 2580" d="M-4580.854-13512.432v-3.719s-.129-8.764,8.964-8.764,9.163,8.55,9.163,9.4v2.1" transform="translate(4580.854 13524.914)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
              </g>
            </g>
          </g>
        </svg>
          <div className="MenuItem">Switch Profile</div></MenuItem>
        <MenuItem onClick={handleClose}><svg width="68.832" height="68.832" viewBox="0 0 68.832 68.832">
          <g id="Group_4986" data-name="Group 4986" transform="translate(-26.012 -118.333)">
            <g id="Group_1379" data-name="Group 1379" transform="translate(26.012 118.333)">
              <ellipse id="Ellipse_299" data-name="Ellipse 299" cx="34.416" cy="34.416" rx="34.416" ry="34.416" transform="translate(0 0)" fill="#7b67db" />
            </g>
            <g id="Group_1875" data-name="Group 1875" transform="translate(46.028 135.662)">
              <g id="Group_1874" data-name="Group 1874" transform="translate(0 0)">
                <path id="Path_2412" data-name="Path 2412" d="M611.712,275.037l6.492,6.49-.1.1-7,.31.506-6.8Z" transform="translate(-611.109 -251.339)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <rect id="Rectangle_1243" data-name="Rectangle 1243" width="18.9" height="9.18" transform="matrix(0.706, -0.708, 0.708, 0.706, 4.503, 19.918)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
                <path id="Path_2413" data-name="Path 2413" d="M646,253.105l2.231-2.233a1.721,1.721,0,0,0,0-2.433l-4.057-4.057a1.722,1.722,0,0,0-2.434,0l-2.231,2.232Z" transform="translate(-617.909 -243.879)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
              </g>
            </g>
          </g>
        </svg>
          <div className="MenuItem">Manage Profile</div></MenuItem>
      </Menu>
    </div>
  );
}