import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';

let lang1 = require('../../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
  localStorage.setItem('lang', 'En');
  lang1 = require('../../lang/en').default;
}
else {
  if (localStorage.getItem('lang') === 'En') {
    lang1 = require('../../lang/en').default;
  }
  else {
    lang1 = require('../../lang/ar').default;
  }
}


export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [idProfile, setIdProfile] = React.useState(null);
  const [optionProfile, setOptionProfile] = React.useState(null);
  const [activePrfoile, setActivePrfoile] = React.useState(null);

  const handleClick = (event, id) => {
    setIdProfile(id);

    setOptionProfile(<div>
      <MenuItem style={{ display: activePrfoile == id ? 'none' : '' }} onClick={() => {
        setMainProfile(id)
      }}><svg width="69" height="69" viewBox="0 0 69 69">
          <g id="Group_4985" data-name="Group 4985" transform="translate(-998 -755)">
            <circle id="Ellipse_296" data-name="Ellipse 296" cx="34.5" cy="34.5" r="34.5" transform="translate(998 755)" fill="#7b67db" />
            <g id="Group_4971" data-name="Group 4971" transform="translate(1010.409 763.365)">
              <g id="Group_2228" data-name="Group 2228" transform="translate(18.408 16.74)">
                <path id="Path_1904" data-name="Path 1904" d="M-466.612-2099.369a7.172,7.172,0,0,1-7.173,7.173,7.172,7.172,0,0,1-7.173-7.173,7.173,7.173,0,0,1,7.173-7.173A7.173,7.173,0,0,1-466.612-2099.369Z" transform="translate(485.599 2106.542)" fill="#fff" />
                <path id="Path_1906" data-name="Path 1906" d="M-478.693-2036.243v4.083a4.426,4.426,0,0,1-4.425,4.426H-497.9a4.427,4.427,0,0,1-4.425-4.426v-4.083c0-5.3,1.9-15.328,1.9-15.328l12.138,5.74A9.588,9.588,0,0,1-478.693-2036.243Z" transform="translate(502.322 2059.333)" fill="#fff" />
              </g>
              <g id="Group_2229" data-name="Group 2229" transform="translate(5.602 13.699)">
                <path id="Path_1904-2" data-name="Path 1904" d="M-460.53-2096.328a10.214,10.214,0,0,1-10.214,10.214,10.214,10.214,0,0,1-10.214-10.214,10.214,10.214,0,0,1,10.214-10.213A10.214,10.214,0,0,1-460.53-2096.328Z" transform="translate(480.958 2106.542)" fill="#7b67db" />
                <path id="Path_1906-2" data-name="Path 1906" d="M-478.693-2033.256v4.745c0,2.839-1.98,5.143-4.425,5.143H-497.9c-2.443,0-4.425-2.3-4.425-5.143v-4.745c0-6.155,4.295-11.144,9.589-11.144h4.452C-482.986-2044.4-478.693-2039.411-478.693-2033.256Z" transform="translate(502.322 2060.942)" fill="#7b67db" />
              </g>
              <g id="Group_2230" data-name="Group 2230" transform="translate(0 16.74)">
                <path id="Path_1904-3" data-name="Path 1904" d="M-466.612-2099.369a7.172,7.172,0,0,1-7.173,7.173,7.172,7.172,0,0,1-7.173-7.173,7.173,7.173,0,0,1,7.173-7.173A7.173,7.173,0,0,1-466.612-2099.369Z" transform="translate(485.599 2106.542)" fill="#fff" />
                <path id="Path_1906-3" data-name="Path 1906" d="M-478.693-2034.811v4.083a4.426,4.426,0,0,1-4.425,4.426H-497.9a4.427,4.427,0,0,1-4.425-4.426v-4.083a9.589,9.589,0,0,1,9.589-9.589h4.452A9.588,9.588,0,0,1-478.693-2034.811Z" transform="translate(502.322 2057.901)" fill="#fff" />
              </g>
              <g id="Group_2231" data-name="Group 2231" transform="translate(12.122)">
                <path id="Path_323" data-name="Path 323" d="M0,0,4.063,4.063,0,8.126" transform="translate(22.19 8.874) rotate(90)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <path id="Path_2580" data-name="Path 2580" d="M-4580.854-13512.432v-3.719s-.129-8.764,8.964-8.764,9.163,8.55,9.163,9.4v2.1" transform="translate(4580.854 13524.914)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
              </g>
            </g>
          </g>
        </svg>
        <div className="MenuItem">{lang1['ManageProfilePopup'].switchProfileButtonLabel}</div></MenuItem>
      <MenuItem onClick={() => {
        history.push(process.env.REACT_APP_FRONT_URL + `settings/${id}/edit`);
      }}><svg width="68.832" height="68.832" viewBox="0 0 68.832 68.832">
          <g id="Group_4986" data-name="Group 4986" transform="translate(-26.012 -118.333)">
            <g id="Group_1379" data-name="Group 1379" transform="translate(26.012 118.333)">
              <ellipse id="Ellipse_299" data-name="Ellipse 299" cx="34.416" cy="34.416" rx="34.416" ry="34.416" transform="translate(0 0)" fill="#7b67db" />
            </g>
            <g id="Group_1875" data-name="Group 1875" transform="translate(46.028 135.662)">
              <g id="Group_1874" data-name="Group 1874" transform="translate(0 0)">
                <path id="Path_2412" data-name="Path 2412" d="M611.712,275.037l6.492,6.49-.1.1-7,.31.506-6.8Z" transform="translate(-611.109 -251.339)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <rect id="Rectangle_1243" data-name="Rectangle 1243" width="18.9" height="9.18" transform="matrix(0.706, -0.708, 0.708, 0.706, 4.503, 19.918)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <path id="Path_2413" data-name="Path 2413" d="M646,253.105l2.231-2.233a1.721,1.721,0,0,0,0-2.433l-4.057-4.057a1.722,1.722,0,0,0-2.434,0l-2.231,2.232Z" transform="translate(-617.909 -243.879)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
              </g>
            </g>
          </g>
        </svg>
        <div className="MenuItem">{lang1['ManageProfilePopup'].manageProfileButtonLabel}</div></MenuItem>
    </div>)

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setMainProfile = (id) => {
    setActivePrfoile(id)
    var arr = JSON.parse(localStorage.getItem('token'));
    let formData = new FormData();
    formData.append("access_token", arr.access_token);
    formData.append('profile_id', id);

    formData.append('lang', localStorage.getItem('lang'));

    //console.log("** formData = " + JSON.stringify(formData))
    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/setMainProfile",
      formData,
    ).then(response => {

      if (response.data.booleanValue == true) {
        history.push(process.env.REACT_APP_FRONT_URL + 'home');
      }
    })
  }
  useEffect(() => {
    var arr = JSON.parse(localStorage.getItem('token'));
    if (arr == undefined) {
      history.push(process.env.REACT_APP_FRONT_URL + 'login');
    }
    else {
      let backend_url = process.env.REACT_APP_BACKEND_URL;
      const url = backend_url + "/app/accesscode";
      let formData = new FormData();
      formData.append("access_token", arr.access_token);
      Axios.post(url,
        formData,
      ).then(response => {
        if (response.data.data == undefined) {
          this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
          // let backend_url = process.env.REACT_APP_BACKEND_URL;
          // const url = backend_url + "/app/listFavorite";
          // let formData = new FormData();
          // formData.append('access_token', arr.access_token);
          // formData.append('typeOFContent', this.state.type);

          // formData.append('profile_id', arr.UserData._id);
          // formData.append('lang', 'En');
          let arrayProfile22 = JSON.parse(localStorage.getItem('Profiles'));

          arrayProfile22.newarray.map((p)=>{
            if(p.mainProfile){
              setActivePrfoile(p._id)
            }
          })


          // Axios.post(url,
          //     formData,
          // ).then(response2 => {
          //     items = response2.data.allarray;


          // })
        }
      })

    }
  }, [optionProfile, idProfile]);
  let history = useHistory();

  const getprofile = () => {

    let backend_url = process.env.REACT_APP_BACKEND_URL;
    let list = [];
    let list2 = [];
    if (localStorage.getItem('Profiles') == undefined) {
      window.location.reload();

    }
    else {
      let arrayProfile = JSON.parse(localStorage.getItem('Profiles'));
      arrayProfile.newarray.map((profile, index) => {
        
        list.push(<div className="profile" key={profile._id} aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => {
          handleClick(e, profile._id);
        }}>
          <div className="imgStyle" key={index}>
            {/* <Button variant="contained" component="label" className="editimg" ></Button> */}
            <img className={profile.mainProfile ? "img3" : "img2"} src={backend_url + '/public/' + profile.avatarData[0]} />
            <div className="nameProfile">{profile.fname}</div>
          </div>
        </div>);
      })
      if (arrayProfile.maxNumberProfile - arrayProfile.newarray.length > 0) {
        for (let i = 0; i < arrayProfile.maxNumberProfile - arrayProfile.newarray.length; i++) {
          list.push(<div className="profile">
            <div className="imgStyle" onClick={() => {
              localStorage.setItem('CreateProfile', 'y');
              history.push(process.env.REACT_APP_FRONT_URL + 'settings/CreateProfile');
            }}>
              <div className="img">+</div>
            </div>
          </div>);
        }
      }
      return list;
    }

  }
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('Profiles');
    localStorage.removeItem('minImg');
    localStorage.removeItem('tabId');
    localStorage.removeItem('loginChoosePlan');
    localStorage.removeItem('choosePlan');
    localStorage.removeItem('plan'); 
    localStorage.removeItem('subscription');  
    localStorage.removeItem('mainProfile');  
    
    history.push(process.env.REACT_APP_FRONT_URL);
  }
  return (
    <div>
      <section id="Profiles" >

        {getprofile()}

      </section>
      <div className="Logoutstyle" onClick={logout}>
        <svg xmlns="http://www.w3.org/2000/svg" width="29.68" height="21.815" viewBox="0 0 29.68 21.815">
          <g id="Group_984" data-name="Group 984" transform="translate(1.5 1.5)">
            <path id="Path_323" data-name="Path 323" d="M-1203.961,378.87l3.336,3.336-3.335,3.336" transform="translate(1227.305 -372.714)" fill="none" stroke="#ff5b7c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <line id="Line_31" data-name="Line 31" x1="15.119" transform="translate(11.562 9.492)" fill="none" stroke="#ff5b7c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            <path id="Path_324" data-name="Path 324" d="M-1212.808,385.355a9.417,9.417,0,0,1-8.159,4.71,9.4,9.4,0,0,1-9.4-9.4,9.4,9.4,0,0,1,9.4-9.411,9.427,9.427,0,0,1,8.256,4.879" transform="translate(1230.37 -371.25)" fill="none" stroke="#ff5b7c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
          </g>
        </svg>

        <div className="textlogout">{lang1['profilesSwitchScreen'].buttonLogoutLabel}</div>
      </div>


      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ borderRadius: '20px' }}
      >

        {optionProfile}
      </Menu>

    </div>
  );
}