import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import ReactCodeInput from 'react-verification-code-input';
import FadeIn from 'react-fade-in';
import InputLabel from '@material-ui/core/InputLabel';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Footer from '../Component/pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}





export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [valid, setValid] = React.useState(false);
    const [valid2, setValid2] = React.useState(false);
    const [loginType, setLoginType] = React.useState('email');

    const [code, setcode] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [socialMediaUserID, setSocialMediaUserID] = React.useState('');
    var today = new Date();
    var ddd = today.getDate();
    var mmm = today.getMonth();
    var yyy = (today.getFullYear() - 1);

    const [name, setName] = React.useState(null);
    const [mm, setMM] = React.useState(mmm);
    const [dd, setDD] = React.useState(ddd);
    const [yy, setYY] = React.useState(yyy);
    const [img, setImg] = React.useState(require('../assets/img/Mask Group 59.png'));
    const [imgSend, setImgSend] = React.useState(null);
    const [counterScreen, setCounterScreen] = React.useState(null);

    const [gender, setGender] = React.useState('m');
    const [avatars, setAvatars] = React.useState([]);

    const [load, setLoad] = React.useState(true);

    const [popupShow, setPopupShow] = React.useState(false);
    const [popupValue, setpopupValue] = React.useState(false);
    const [popupTitle, setpopupTitle] = React.useState('');
    const [popupType, setPopupType] = React.useState(null);

    const monthNames = localStorage.getItem('lang') == 'En' ? ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ] : ["يناير", "فبراير", "مارس", "إبريل", "مايو", "يونيو",
            "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];


    function getDays() {
        let aa = [];
        for (var i = 1; i <= daysInMonth(mm + 1, yy + 1); i++) {
            aa.push(i)
        }
        return aa;
    }

    function daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }


    const years = [];
    for (var i = 1990; i <= (new Date().getFullYear() - 1); i++) {
        years.push(i);
    }

    const history = useHistory();
    const alert = useAlert();

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip);
    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: theme.palette.common.black,
        },
    }));


    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();

        return <Tooltip arrow classes={classes} {...props} />;
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);
    const handleSubmitEnd = () => {
        // console.log(JSON.parse(localStorage.getItem('reg_id_user')))
        setLoad(false);
        let backend_url = process.env.REACT_APP_BACKEND_URL;


        const url = backend_url + "/app/registerStepTwo";

        let formData = new FormData();

        formData.append('_id', JSON.parse(localStorage.getItem('reg_id_user')));
        formData.append('phone', JSON.parse(localStorage.getItem('reg_id_user_phone')));
        formData.append('fname', name);
        formData.append('bdate', (dd + '-' + (mm + 1) + '-' + yy));
        formData.append('gender', gender);
        formData.append('pimage', img.replace(process.env.REACT_APP_BACKEND_URL + '/public/', ""));

        formData.append('lang', 'En');
        console.log(img.replace(process.env.REACT_APP_BACKEND_URL + '/public/', ""))
        Axios.post(url,
            formData,
        ).then(response => {
            setLoad(true);
            if (response.data.booleanValue) {
                let formData2 = new FormData();
                if (JSON.parse(localStorage.getItem('type')) == 'email') {
                    formData2.append("email", JSON.parse(localStorage.getItem('reg_id_user_email')));
                    formData2.append("password", JSON.parse(localStorage.getItem('reg_id_user_password')));
                    formData2.append('lang', 'En');
                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/loginbyemailandpassword",
                        formData2,
                    ).then(response => {
                        console.log(response)
                        if (response.data.booleanValue == true) {
                            localStorage.removeItem('reg_id_user');
                            localStorage.removeItem('reg_id_user_phone');
                            localStorage.removeItem('reg_id_user_email');
                            localStorage.removeItem('reg_id_user_password');
                            localStorage.setItem('token', JSON.stringify(response.data));

                            localStorage.setItem('Interests', response.data.UserData._id);
                            localStorage.setItem('choosePlan', response.data.UserData._id);
                            // history.push(process.env.REACT_APP_FRONT_URL + 'interests');
                            history.push(process.env.REACT_APP_FRONT_URL + 'subscription');

                        }
                    });
                }
                /////////////////////////////////////
                else {
                    formData2.append('accountId', JSON.parse(localStorage.getItem('reg_id')));
                    formData2.append('lang', 'En');
                    Axios.post(process.env.REACT_APP_BACKEND_URL + "/app/loginBySocialMedia",
                        formData2,
                    ).then(response => {
                        console.log(response)
                        if (response.data.booleanValue == true) {

                            localStorage.removeItem('reg_id_user');
                            localStorage.removeItem('reg_id');
                            localStorage.removeItem('type');
                            localStorage.setItem('token', JSON.stringify(response.data));
                            localStorage.setItem('Interests', response.data.UserData._id);
                            localStorage.setItem('choosePlan', response.data.UserData._id);
                            // history.push(process.env.REACT_APP_FRONT_URL + 'interests');
                            history.push(process.env.REACT_APP_FRONT_URL + 'subscription');

                        }
                    });



                }

            }
        })


    }
    const handleSubmit = () => {
        // console.log(mm,dd,yy)
        // console.log(name)
        // console.log(gender) 
        if (valid) {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/getavatar";
            let formData = new FormData();
            formData.append('gender', gender);


            Axios.post(url,
                formData,
            ).then(response => {
                setAvatars(response.data.result);
                console.log(response.data.result);
                setValid2(true)
            })
                .catch((error) => {
                    console.log(error)
                });
        }


    }


    const handleClose = () => {
        setAnchorEl(null);
    };
    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    const handleChangeCode = (vals) => {
        setcode(vals)
    }


    useEffect(() => {
        //  
        if (localStorage.getItem('reg_id_user') == undefined) {
            history.push(process.env.REACT_APP_FRONT_URL + 'createAccount');
        }

    }, []);


    function fileUpload(e, id) {

        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            // console.log(reader.result)
            setImg(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };


        // let image = document.getElementById(id);
        // image.src = URL.createObjectURL(e.target.files[0]);
        // // setImgSend(e.target.files[0]);
        // console.log(e.target.files[0])

    };

    const activeBtn = () => {
        console.log(name)
        let cilkeVal = true;
        setValid(true);
        if (name == null || name == '' || name.length <= 1) {
            cilkeVal = false;
        }

        if (gender == null || gender == '') {
            cilkeVal = false;
        }


        setValid(cilkeVal);

    }
    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" >
                <section id="footer" >
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        {/* <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} /> */}


                    </div>
                    <FadeIn>

                        <div className={!valid2 ? "title fadeInAnmthionActive" : "title fadeInAnmthion"} >
                            <div className="img">
                                <img src={require("../assets/img/Group 534.png")} />
                            </div>
                            <div className="textTitle">
                                {lang1['RegistrationChildFirstNameScreen'].titlePage}
                            </div>


                            <div className="bodyEdit">
                                <div className="formProfile">
                                    <div className="row">


                                        <div className="formdata">
                                            <div className="rowinner">
                                                <div className="titleRow">{lang1['RegistrationChildFirstNameScreen'].title}</div>
                                                <TextField
                                                    placeholder={lang1['RegistrationChildFirstNameScreen'].inputChildNameLabel}
                                                    variant="outlined"
                                                    style={{ width: '30vw', backgroundColor: '#fff' }}
                                                    onKeyUp={(e) => {
                                                        setName(e.target.value);
                                                        activeBtn();
                                                    }}
                                                />
                                            </div>
                                            <div className="rowinner">
                                                <div className="titleRow">{lang1['RegistrationChildDateOfBirthScreen'].title}</div>


                                                <FormControl variant="outlined">

                                                    <Select
                                                        labelId="demo-simple-select-filled-label"
                                                        id="demo-simple-select-filled"
                                                        value={dd}
                                                        onChange={(e) => {
                                                            setDD(e.target.value);
                                                            // activeBtn();
                                                        }}
                                                        className="inputDate"
                                                    >
                                                        {getDays().map((item, index) =>
                                                            <MenuItem value={item}>{item}</MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>


                                                <FormControl variant="outlined">
                                                    <Select
                                                        placeholder="Month"
                                                        id="demo-simple-select-filled"
                                                        value={mm}
                                                        onChange={(e) => {
                                                            setMM(e.target.value);
                                                            // activeBtn();
                                                        }}
                                                        style={{ width: '8vw', backgroundColor: '#fff' }}
                                                        className="inputDate"
                                                    >
                                                        {monthNames.map((item, index) =>
                                                            <MenuItem value={index}>{item}</MenuItem>
                                                        )}

                                                    </Select>
                                                </FormControl>
                                                <FormControl variant="outlined">
                                                    <HtmlTooltip
                                                        title={yy == today.getFullYear() ?
                                                            <React.Fragment>
                                                                <Typography color="inherit">{lang1['AlertMessages'].Warning}</Typography>
                                                                {lang1['AlertMessages'].acceptedYear}
                                                            </React.Fragment> : ''
                                                        }
                                                    >
                                                        <Select
                                                            labelId="demo-simple-select-filled-label"
                                                            id="demo-simple-select-filled"
                                                            value={yy}
                                                            onChange={(e) => {
                                                                setYY(e.target.value);
                                                                // activeBtn();
                                                            }}
                                                            className="inputDate"
                                                        >
                                                            {years.map((item, index) =>
                                                                <MenuItem value={item}>{item}</MenuItem>
                                                            )}
                                                        </Select>
                                                    </HtmlTooltip>

                                                </FormControl>

                                            </div>
                                            <div className="rowinner2">
                                                <div className="titleRow">{lang1['RegistrationChildGenderScreen'].title}</div>
                                                <div className="gendertype" onClick={() => {
                                                    setGender('m');
                                                    activeBtn()
                                                }}>
                                                    <img src={require("../assets/img/male.png")} style={{ width: '6vw' }} />
                                                    <div className="textgender" style={{ opacity: gender == 'm' ? '1' : '0.5' }}>{lang1['RegistrationChildGenderScreen'].labelMale} <b style={{ display: gender == 'm' ? 'block' : 'none', padding: '0 5px' }}><svg xmlns="http://www.w3.org/2000/svg" width="22.086" height="22.086" viewBox="0 0 22.086 22.086">
                                                        <path id="Path_2388" data-name="Path 2388" d="M6.345,0,.262.285,0,14.57" transform="translate(8.184 18.438) rotate(-135)" fill="none" stroke="#7b67db" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" />
                                                    </svg>
                                                    </b></div>
                                                </div>
                                                <div className="gendertype" onClick={() => {
                                                    setGender('f');
                                                    activeBtn()
                                                }}>
                                                    <img src={require("../assets/img/female.png")} style={{ width: '6vw' }} />
                                                    <div className="textgender" style={{ opacity: gender == 'f' ? '1' : '0.5' }}>{lang1['RegistrationChildGenderScreen'].labelFemale} <b style={{ display: gender == 'f' ? 'block' : 'none', padding: '0 5px' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.086" height="22.086" viewBox="0 0 22.086 22.086">
                                                            <path id="Path_2388" data-name="Path 2388" d="M6.345,0,.262.285,0,14.57" transform="translate(8.184 18.438) rotate(-135)" fill="none" stroke="#7b67db" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" />
                                                        </svg>
                                                    </b></div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className="btns">
                                <a className={valid ? "btn1" : "disbtn"}
                                    onClick={() => {
                                        return valid ? handleSubmit() : ''
                                    }}
                                >{lang1['RegistrationChildFirstNameScreen'].buttonContinueLabel}</a>
                            </div>


                        </div>


                    </FadeIn>
                    <FadeIn>

                        <div className={valid2 ? "title fadeInAnmthionActive" : "title fadeInAnmthion"} >
                            <div className="imgProfile">
                                <Button variant="contained" component="label" className="editimg" >
                                    <input
                                        accept="image/x-png,image/gif,image/jpeg"
                                        type="file"
                                        onChange={(e) => {
                                            fileUpload(e, "cover-image");
                                        }}
                                        style={{ display: "none" }}
                                    />
                                </Button>

                                <img src={img} id="cover-image" />

                            </div>
                            <div className="textTitle">
                                {lang1['RegistrationChildProfileImageScreen'].title}

                            </div>

                            <div className="avatar">
                                <div className="titleAvatar">{lang1['RegistrationChildProfileImageScreen'].titleSub}</div>
                                <div className="listAvatar">
                                    {avatars.map((item) => {
                                        return <img src={process.env.REACT_APP_BACKEND_URL + '/public/' + item.imgUpload} style={{ backgroundColor: item.hexcode }} onClick={
                                            () => {
                                                setImg(process.env.REACT_APP_BACKEND_URL + '/public/' + item.imgUpload)
                                            }
                                        } />
                                    })}

                                </div>
                            </div>

                            <div className="btns">
                                <a className={valid ? "btn1" : "disbtn"}
                                    onClick={() => {
                                        return valid ? handleSubmitEnd() : ''
                                    }}
                                >{lang1['RegistrationChildProfileImageScreen'].buttonContinueLabel}</a>
                            </div>


                        </div>


                    </FadeIn>


                </section>

            </section>
            {/* <Footer /> */}

        </div>
    );
}