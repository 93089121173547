import React, { useEffect } from 'react';

import Axios from 'axios';
import { useHistory, useParams } from "react-router-dom";

import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import FadeIn from 'react-fade-in';
import { Base64 } from 'js-base64';
import Footer from './pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import city from './city.json';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';


import TextField from '@material-ui/core/TextField';
import { PayPalButton } from "react-paypal-button-v2";

let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}
export default function Checkout() {
    const { id } = useParams();
    const [data, setData] = React.useState(true);
    const [load, setLoad] = React.useState(true);
    const [name, setName] = React.useState(null);
    const [citys, setCity] = React.useState(99999999999);
    const [citys2, setCity2] = React.useState(null);
    const [email, setEmail] = React.useState(JSON.parse(localStorage.getItem('token')).UserData.email != undefined ? JSON.parse(localStorage.getItem('token')).UserData.email : null);
    const [address, setAddress] = React.useState(null);

    
    const history = useHistory();
    const alert = useAlert(); 

    useEffect(() => {
        if (localStorage.getItem('subscription') == undefined) {
            history.push(process.env.REACT_APP_FRONT_URL);
        }
        setLoad(true)
        let palnId = localStorage.getItem('subscription').split(",");

        const script = document.createElement("script");

        script.src = "https://oppwa.com/v1/paymentWidgets.js?checkoutId=" + Base64.decode(id);
        script.async = true;

        document.body.appendChild(script);

        let partners =
            <div className="checkout">


                <div className="detal1">

                    <div className="paypal" >
                        <form action={'/hyperpay/status'} class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>

                    </div>
                </div>


            </div >

        setData(partners)






    }, []);


    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ display: load ? 'block' : 'none' }} >

                <section id="footer" >

                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        {/* <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} /> */}


                    </div>
                    <FadeIn>
                        <div className={"title fadeInAnmthionActive"} >
                            <img className="imgLogo pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />

                           
                            {data}

                        </div>

                    </FadeIn>

                </section>

            </section>
            <Footer />
        </div>
    );
}