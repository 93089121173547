import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';

import Footer from '../Component/pageSec/footer';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { render } from "react-dom";
import { Tune } from '@material-ui/icons';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import TwitterLogin from "react-twitter-login";

const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER
};



let lang = require('../lang/ar').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'Ar');
    lang = require('../lang/ar').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../lang/en').default;
    }
    else {
        lang = require('../lang/ar').default;
    }
}

class Header extends Component {
    state = {
        username: "",
        password: "",
        token: "",
        MsgError: '',
        showMgs: false,
        open: false,
        showPage: true,
        lang: lang
    }
    handleChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    responseFacebook = (response) => {
        // console.log(response)
        this.registerBySocial(response.name, response.id, 'facebook');
    }

    responseGoogle = (response) => {
        this.registerBySocial(response.googleId, response.googleId, 'google');
    }

    authHandler = (err, data) => {
        this.registerBySocial(data.screen_name, data.user_id, 'twitter')
    };


    registerBySocial = (name, id, type) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/loginBySocialMedia";
        let formData = new FormData();
        formData.append('accountId', id);
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(url,
            formData,
        ).then(response => {
            if (response.data.booleanValue) {
                localStorage.setItem('token', JSON.stringify(response.data));
                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
            }
            else {
                if (response.data.isBlock == false && response.data.booleanValue == false) {

                    const url = backend_url + "/app/registerFirstStep";
                    let formData = new FormData();
                    formData.append('type', type);
                    formData.append('accountId', id);
                    formData.append('accountName', name);
                    formData.append('lang', localStorage.getItem('lang'));
                    Axios.post(url,
                        formData,
                    ).then(response => {
                        if (response.data.booleanValue) {
                            localStorage.setItem('reg_id_user', JSON.stringify(response.data.iduser));
                            localStorage.setItem('reg_id', JSON.stringify(id));
                            localStorage.setItem('type', JSON.stringify(type));
                            this.props.history.push(process.env.REACT_APP_FRONT_URL + `createprofile`);
                        }
                        else {
                            alert.error(response.data.message);
                        }
                    })
                        .catch((error) => {
                            console.log(error)
                        });


                }
                else {
                    this.setState({
                        MsgError: response.data.err,
                        showMgs: true
                    });
                }

            }
        })
            .catch((error) => {
                console.log(error)
            });


    }






    handleSubmit = (model) => {
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/loginbyemailandpassword";
        let formData = new FormData();
        formData.append("email", this.state.username);
        formData.append("password", this.state.password);
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(url,
            formData,
        ).then(response => {
            if (response.data.booleanValue == true) {
                localStorage.setItem('token', JSON.stringify(response.data));
                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');

            }
            else {
                this.setState({
                    MsgError: response.data.err,
                    showMgs: true
                });
                // alert(response.data.err);
            }

        })
            .catch((error) => {
                console.log(error)
            });
    }
    componentDidMount() {
        //oauth_verifier
        console.log(this.props.location.search.search('oauth_verifier'))
        console.log(this.props)
        if (this.props.location.search.search('oauth_verifier') == -1) {
            this.setState({ showPage: false })
        }
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.setState({ open: true })
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined) {
                    this.setState({ open: true })
                }
                else {
                    this.setState({ open: false })
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
                }
            })

        }
    }


    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleSubmit()
        }
    }
    render() {
        return (
            <div style={{ display: this.state.showPage ? 'none' : 'block' }}>
                <section id="footer" style={{ visibility: this.state.open ? 'visible' : 'hidden', opacity: this.state.open ? '1' : '0', transition: 'all 1s' }}>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />
                        <img id="backbtnImg" className="img pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL) }} />

                        {/* <img id="backbtn" onClick={() => {
                            this.props.history.push(process.env.REACT_APP_FRONT_URL);
                        }} src={require("../assets/img/Group 4877.png")} /> */}

                    </div>

                    <div className="title">
                        <div className="img">
                            <img src={require("../assets/img/Group 813.png")} />
                        </div>
                        <div className="textTitle">
                            {this.state.lang['LoginScreen'].title}
                        </div>
                        <div className="errorMsg" style={{ opacity: this.state.showMgs ? '1' : '0' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="33" viewBox="0 0 29 33">
                                <g id="Group_4992" data-name="Group 4992" transform="translate(-13 -9.802)">
                                    <g id="Group_1332" data-name="Group 1332" transform="translate(13.213 12.27)">
                                        <circle id="Ellipse_386" data-name="Ellipse 386" cx="14.5" cy="14.5" r="14.5" transform="translate(-0.213 -0.468)" fill="#fff" />
                                    </g>
                                    <text id="_" data-name="!" transform="translate(30 36.802)" fill="#fd7777" font-size="25" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">!</tspan></text>
                                </g>
                            </svg>

                            {this.state.MsgError}</div>
                        <div className="input">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.312" height="15.755" viewBox="0 0 19.312 15.755">
                                <g id="Group_1159" data-name="Group 1159" transform="translate(1 1)">
                                    <path id="Path_62" data-name="Path 62" d="M1138.33,560.429v8.736a2.51,2.51,0,0,0,2.509,2.509h12.294a2.51,2.51,0,0,0,2.509-2.509v-8.736a2.46,2.46,0,0,0-.522-1.526,2.486,2.486,0,0,0-1.987-.984h-12.294a2.509,2.509,0,0,0-2.509,2.509Z" transform="translate(-1138.33 -557.92)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_63" data-name="Path 63" d="M1139.37,558.9l5.635,5.635a3.541,3.541,0,0,0,5,0l5.635-5.635a2.486,2.486,0,0,0-1.987-.984h-12.294A2.5,2.5,0,0,0,1139.37,558.9Z" transform="translate(-1138.853 -557.92)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_64" data-name="Path 64" d="M1145.3,571.63a2.741,2.741,0,0,0-.291.256l-5.635,5.635a2.5,2.5,0,0,0,1.992.984h12.294a2.486,2.486,0,0,0,1.987-.984l-5.635-5.635a2.364,2.364,0,0,0-.3-.256" transform="translate(-1138.853 -564.75)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                </g>
                            </svg>
                            <input type="text" placeholder={this.state.lang['LoginScreen'].inputEmail}
                                value={this.state.username}
                                onKeyPress={(e) => this.handleKeyPress(e)}
                                onChange={(e) => {
                                    this.handleChange(e, "username");
                                }}
                            />
                        </div>
                        <div className="input">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.19" height="21.958" viewBox="0 0 19.19 24.958">
                                <g id="Group_1160" data-name="Group 1160" transform="translate(1 1)">
                                    <path id="Path_66" data-name="Path 66" d="M1155.522,617.12V625.8a2.492,2.492,0,0,1-2.491,2.491h-12.208a2.492,2.492,0,0,1-2.491-2.491V617.12a2.5,2.5,0,0,1,2.491-2.491h12.208a2.49,2.49,0,0,1,2.491,2.491Z" transform="translate(-1138.332 -605.328)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_67" data-name="Path 67" d="M1155.545,605.23v-3.916a5.352,5.352,0,1,0-10.7,0v3.916Z" transform="translate(-1141.597 -595.962)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_68" data-name="Path 68" d="M1153.3,624.063V622.62a1.138,1.138,0,0,1,1.141-1.142,1.131,1.131,0,0,1,1.135,1.142v1.443" transform="translate(-1145.843 -608.764)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    <path id="Path_69" data-name="Path 69" d="M1155.57,628.327a2.277,2.277,0,1,1-2.277-2.277A2.278,2.278,0,0,1,1155.57,628.327Z" transform="translate(-1144.696 -611.058)" fill="none" stroke="#8cacc1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                </g>
                            </svg>
                            <input type="password" placeholder={this.state.lang['LoginScreen'].inputPassword}
                                value={this.state.password}
                                onKeyPress={(e) => this.handleKeyPress(e)}
                                onChange={(e) => {
                                    this.handleChange(e, "password");
                                }}
                            />
                        </div>
                        <div className="forgetpassword">
                            <Link to={process.env.REACT_APP_FRONT_URL + 'forgetpassword'} className="btn1">{this.state.lang['LoginScreen'].buttonForgetPassword}</Link>
                        </div>
                        <div className="btns">
                            <a className="btn1" onClick={() => {
                                this.handleSubmit()
                            }}>{this.state.lang['LoginScreen'].buttonNext}</a>
                        </div>
                        <div className="otherlogin">
                            <p>{this.state.lang['LoginScreen'].or}</p>
                            <div className="socailMedia">
                                <FacebookLogin
                                    appId="227181481933982"
                                    callback={this.responseFacebook}
                                    fields="name,email,picture"
                                    cssClass="my-facebook-button-class"
                                    icon={<img src={require("../assets/img/fb.png")} />}
                                />
                                {/* <GoogleLogin
                                    clientId="64363108088-5b7uht7189ejcdk7mt997plalp4d0388.apps.googleusercontent.com"
                                    buttonText="Login"
                                    render={renderProps => (
                                        <img onClick={renderProps.onClick}  src={require("../assets/img/go.png")} />
                                    )}
                                    buttonText="Login"
                                    onSuccess={this.responseGoogle} 
                                    cookiePolicy={'single_host_origin'}
                                /> */}
                                <TwitterLogin
                                    authCallback={this.authHandler}
                                    consumerKey={'O2dCvYC0qCzcXf7WFOsdPmzbp'}
                                    consumerSecret={'6TIUntSq8vXyDsZulV7bMoN5tA1Bm8JC9L8Kemrk16DACN5DCT'}
                                    className="TwitterLogin"
                                >
                                    <svg viewBox="0 0 24 24" aria-hidden="true" class="r-1fmj7o5 r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-10uooop"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg>
                                </TwitterLogin>
                                {/* <img src={require("../assets/img/Group 4870.png")} /> */}
                                {/* <img src={require("../assets/img/Group 4869.png")} /> */}
                                {/* <img src={require("../assets/img/Group 4868.png")} /> */}
                            </div>
                            <div className="signup">{this.state.lang['LoginScreen'].signuptxt} <Link to={process.env.REACT_APP_FRONT_URL + 'CreateAccount'} className="btn1">{this.state.lang['LoginScreen'].signup}</Link></div>
                        </div>

                    </div>

                </section>

                <Footer />

            </div>
        );
    }
}

export default Header;