import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";



let lang1 = require('../../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
  localStorage.setItem('lang', 'En');
  lang1 = require('../../lang/en').default;
}
else {
  if (localStorage.getItem('lang') === 'En') {
    lang1 = require('../../lang/en').default;
  }
  else {
    lang1 = require('../../lang/ar').default;
  }
}


export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState(localStorage.getItem('lang'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {

  }, []);
  let history = useHistory();



  return (
    <div>
      <section id="Language" >
        <div className="langlsit">
          <div className={lang == 'Ar' ? "langActive" : "lang"} onClick={() => setLang('Ar')}>{lang1['LanguageSelectionScreen'].buttonArabicLabel}
            <div className="Check"></div>
          </div>
          <div className={lang == 'En' ? "langActive" : "lang"} onClick={() => setLang('En')}>{lang1['LanguageSelectionScreen'].buttonEnglishLabel}
            <div className="Check"></div>
          </div>
        </div>

        <div className="btns">
          <a className="btn1" onClick={() => {
            localStorage.setItem('lang', lang);
            history.push(process.env.REACT_APP_FRONT_URL + `home`);
            history.go(0);

          }}>{lang1['LanguageSelectionScreen'].buttonSaveLabel}</a>
        </div>
      </section>


    </div>
  );
}