import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from 'axios';
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAlert } from "react-alert";
import ReactCodeInput from 'react-verification-code-input';
import FadeIn from 'react-fade-in';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import { Base64 } from 'js-base64';

import Footer from '../Component/pageSec/footer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import PayPalCheckout from 'react-paypal-checkout-button';


let freelabel;
let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
    freelabel = 'Free';
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
        freelabel = 'Free';
    }
    else {
        lang1 = require('../lang/ar').default;
        freelabel = 'مجاني';
    }
}

export default function SimpleMenu() {

    const [valid, setValid] = React.useState(false);
    const [valid2, setValid2] = React.useState(false);



    const [load, setLoad] = React.useState(true);

    let radioExp = '';
    const [data, setData] = React.useState(true);


    const history = useHistory();
    const alert = useAlert();


    const handleSubmitEnd = (idPlan, type, t3) => {
        if (type == 'free') {
            localStorage.removeItem('choosePlan');
            history.push(process.env.REACT_APP_FRONT_URL + 'interests');
        }
        else if (type == 'paid') {
            // if (radioExp != '') {
                localStorage.setItem('subscription', [idPlan, t3]);
                history.push(process.env.REACT_APP_FRONT_URL + 'checkout');
            // }
            // else {
            //     alert.show(lang1['PlanSelection'].noPlanSelected)
            // }
        }
    }


    const handleChange = e => {
        radioExp = e.target.value;
    };
    useEffect(() => {
        if (localStorage.getItem('choosePlan') == undefined) {
            history.push(process.env.REACT_APP_FRONT_URL);
        }
        setLoad(false)
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/getPlanSubscription";
        let formData = new FormData();
        formData.append('lang', localStorage.getItem('lang'));
        Axios.post(url,
            formData,
        ).then(response2 => {
            setLoad(true)
            let partners = response2.data.row && response2.data.row.length > 0 ?
                response2.data.row.map((p, index) =>

                    <div className="plan" key={p._id} style={{
                        display: localStorage.getItem('plan') != null ? (Base64.decode(localStorage.getItem('plan')) == 'plan_1' && p.planType == "free" ? 'none' : 'block') : 'block'
                    }}>
                        <div className="titlePlan" style={{ backgroundColor: p.hexcode }}>{p.title}</div>
                        <div className="optionPlan">
                            {p.planType == 'free' ? freelabel : ''}
                            {p.planType != 'free' ?
                                <div>
                                    <span>$</span>{index == 1 ? p.monthly : p.annualy}
                                </div>
                                :
                                ''}
                        </div>
                        <div className="detPlan">
                            <div className="itemDet">
                                <div className="leftDet">{p.swn}</div>
                                <div className="ritDet">{lang1['PlanSelection'].watchAtSameTimeOneDevice}</div>
                            </div>
                            <div className="itemDet">
                                <div className="leftDet">
                                    <img className="Cimg" src={(p.exclusive === 'true') ? require("../assets/img/Path 1894.png") : require("../assets/img/Group 797.png")} />
                                </div>
                                <div className="ritDet">{lang1['PlanSelection'].exclusive}</div>
                            </div>
                            <div className="itemDet">
                                <div className="leftDet">
                                    <img className="Cimg" src={(p.watch === 'true') ? require("../assets/img/Path 1894.png") : require("../assets/img/Group 797.png")} />

                                </div>
                                <div className="ritDet">{lang1['PlanSelection'].watchDevices}</div>
                            </div>
                            <div className="itemDet">
                                <div className="leftDet">

                                    <img className="Cimg" src={(p.cancel === 'true') ? require("../assets/img/Path 1894.png") : require("../assets/img/Group 797.png")} />
                                </div>
                                <div className="ritDet">{lang1['PlanSelection'].cancel}</div>
                            </div>
                        </div>
                        <div 
                            className={`btnPlan ${!!(localStorage.getItem('plan') && Base64.decode(localStorage.getItem('plan')) === p._id) ? "disabledBtnPlan" : ""}`}
                            style={{ backgroundColor: p.hexcode }}
                            onClick={() => {
                                if(!localStorage.getItem('plan') || Base64.decode(localStorage.getItem('plan')) !== p._id){
                                    handleSubmitEnd(p._id, p.planType, index == 1 ? 'monthly' : 'annualy')
                                }
                            }}
                        >
                            {lang1['PlanSelection'].buttonLabel}
                        </div>
                    </div>


                ) : <span>No plan available</span>;



            setData(partners)
        })




    }, []);


    return (
        <div>
            <Backdrop className="backdrop" open={!load}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <section id="createAcount" style={{ display: load ? 'block' : 'none' }} >
                <section id="footer" >
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                        <img id="backbtn" onClick={() => {
                            history.goBack()
                        }} src={require("../assets/img/Group 4877.png")} />


                    </div>
                    <FadeIn>
                        <div className={!valid2 ? "title fadeInAnmthionActive" : "title fadeInAnmthion"} >

                            <div className="textTitle">
                                {lang1['PlanSelection'].screenTitle}
                            </div>

                            <div className="Subscription">
                                {data}

                            </div>
                            {/* <PayPalCheckout
                                clientId='AYmVb67NurEi1DI9BlOIVR9OOf_y0E3w8kPrIXl2x-Z7UeK2dZzA_nhH5iEKGAyxRaMSYDUEvlt7eB5o'
                                amount={100}
                                currency='USD'
                                onSuccess={(data, order) => {
                                    console.log(data, order)
                                }}
                                onError={(error) => {
                                    console.log(error)
                                }}
                            /> */}
                        </div>

                    </FadeIn>

                </section>

            </section>

            {/* <Footer /> */}
        </div>
    );
}