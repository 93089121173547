import React, { Component } from 'react';

import { connect } from 'react-redux';
import Axios from 'axios';
import Playlist from './playlist';



let lang = require('../../lang/en').default;


if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
    localStorage.setItem('lang', 'En');
    lang = require('../../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang = require('../../lang/en').default;
    }
    else {
        lang = require('../../lang/ar').default;
    }
}


class infoConent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPopup2: this.props.show2.showPopup2,
            favSt: this.props.show2.favSt,
            showPlaylist: false,
            reload: this.props.show2.reload,
            lang: lang
        }
    }
    componentWillReceiveProps(props) {
        this.setState({
            showPopup2: props.show2.showPopup2,
            favSt: props.show2.favSt,
            reload: props.show2.reload
        });
    }

    closePopup2() {
        this.setState({ showPopup2: false })
    }

    reloadNow() {
        this.setState({ reload: true })
    }
    setFav(id, type, st) {
        var arr = JSON.parse(localStorage.getItem('token'));
        let backend_url = process.env.REACT_APP_BACKEND_URL;
        const url = backend_url + "/app/setFavorite";
        let formData = new FormData();
        formData.append('access_token', arr.access_token);
        formData.append('profile_id', arr.UserData._id);
        formData.append('contentID', id);
        formData.append('lang', localStorage.getItem('lang'));
        let stsent = !this.state.favSt ? 1 : 0;
        formData.append('typeOFContent', type);
        formData.append('favOrUnfav', stsent)

        Axios.post(url,
            formData,
        ).then(response2 => {
            this.setState({
                favSt: !this.state.favSt
            })

            if (this.props.reloadData != undefined) {
                this.props.reloadData(id, type)
                this.setState({ showPopup2: false })
            }
        })

    }
    showPlaylistFun() {
        this.setState({
            showPlaylist: !this.state.showPlaylist
        })

    }
    render() {

        return (

            <div style={{ visibility: this.state.showPopup2 ? 'visible' : 'hidden', opacity: this.state.showPopup2 ? '1' : '0', transition: 'opacity 2.5' }}
            >

                <section id="infoConent2" >
                    <div className="colseBtn"
                        onClick={this.closePopup2.bind(this)}
                    >X</div>
                    <div className="boxPopup">

                        <div className="titleContent">{this.props.show2.data2.title}</div>
                        <div className="btns2">
                            <a className={this.state.favSt ? "btnfavActive" : "btnfav"}
                                onClick={() => {
                                    this.reloadNow.bind(this)
                                    this.setFav(this.props.show2.data2._id, this.props.show2.type, this.props.show2.favSt)
                                }}
                            >{this.state.favSt ? this.state.lang['ItemMenuPopup'].buttonRemoveFromFavouriteLabel : this.state.lang['ItemMenuPopup'].buttonAddToFavouriteLabel}</a>
                            <a className="btnPlaylist"

                                onClick={() =>
                                    this.setState({
                                        showPlaylist: this.state.showPlaylist ? false : true
                                    })
                                }

                            >{this.state.lang['AddToPlaylistPopup'].title}</a>

                        </div>
                    </div>


                </section>

                <Playlist show={this.state.showPlaylist} contentId={this.props.show2.data2._id} type={this.props.show2.type} />

            </div>
        );
    }
}

export default infoConent;