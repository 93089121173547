import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Axios from 'axios';
import Carousel from 'react-elastic-carousel';

import { TransitionGroup, CSSTransition } from "react-transition-group";
import Profiles from '../Component/settingscomponent/Profiles';
import Subscription from '../Component/settingscomponent/Subscription';
import Language from '../Component/settingscomponent/Language';
import Notification from '../Component/settingscomponent/Notification';
import Privacy from '../Component/settingscomponent/Privacy';
import Terms from '../Component/settingscomponent/Terms';



let lang1 = require('../lang/en').default;
if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    localStorage.setItem('lang', 'En');
    lang1 = require('../lang/en').default;
}
else {
    if (localStorage.getItem('lang') === 'En') {
        lang1 = require('../lang/en').default;
    }
    else {
        lang1 = require('../lang/ar').default;
    }
}


class Settings extends Component {
    state = {
        items: [],
        sec: 0,
        popupshow: [],
        tags: [],
        showPopup: false,
        showPopup2: false,
        favSt: '',
        keyword: '',
        genres: [],
        GenreList: [],
        GenreListselected: [],
        lang1: lang1
    }


    componentDidMount() {
        localStorage.setItem('loginChoosePlan', '');
        var arr = JSON.parse(localStorage.getItem('token'));
        if (arr == undefined) {
            this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
        }
        else {
            let backend_url = process.env.REACT_APP_BACKEND_URL;
            const url = backend_url + "/app/accesscode";
            let formData = new FormData();
            formData.append("access_token", arr.access_token);
            Axios.post(url,
                formData,
            ).then(response => {
                if (response.data.data == undefined && JSON.parse(localStorage.getItem('token')).access_token != 'guest') {
                    this.props.history.push(process.env.REACT_APP_FRONT_URL + 'login');
                }
                else {
                    // let backend_url = process.env.REACT_APP_BACKEND_URL;
                    // const url = backend_url + "/app/listFavorite";
                    // let formData = new FormData();
                    // formData.append('access_token', arr.access_token);
                    // formData.append('typeOFContent', this.state.type);

                    // formData.append('profile_id', arr.UserData._id);
                    // formData.append('lang', 'En');




                    // Axios.post(url,
                    //     formData,
                    // ).then(response2 => {
                    //     console.log(response2)
                    //     this.setState({
                    //         items: response2.data.allarray
                    //     })

                    // })
                }
            })

        }
    }

    changeSec(id) {
        this.setState({ sec: id });
    }

    loginDev() {
        return (
            <div>
                <div className="contentPopupStting">
                    <div className="titlecontentPopup">{this.state.lang1['profilesSwitchScreen'].title}</div>
                    <Link to={process.env.REACT_APP_FRONT_URL + 'createAccount'} className="btn1">{this.state.lang1['profilesSwitchScreen'].buttonCreateAccountLabel}</Link>
                    <Link to={process.env.REACT_APP_FRONT_URL + 'login'} className="btn2">{this.state.lang1['profilesSwitchScreen'].buttonLoginLabel}</Link>
                </div>
            </div>
        )
    }
    NotificationDev() {
        return (
            <div>
                <div className="contentPopupStting">
                    <div className="Notification">{this.state.lang1['NotificationsScreen'].noContentsAvailableLabel}</div>

                </div>
            </div>
        )
    }
    subscriptionDev() {
        return (
            <div className="SubscriptionSetting">
                <svg className="svg" viewBox="0 0 85.22 116.115">
                    <g id="Group_1017" data-name="Group 1017" transform="translate(0 5.5)">
                        <path id="Path_67" data-name="Path 67" d="M1193.1,637.75V620.094a24.132,24.132,0,1,0-48.265,0V637.75Z" transform="translate(-1126.363 -595.962)" fill="none" stroke="#ff3b41" strokeLinecap="round" strokeLinejoin="round" strokeWidth="11" />
                        <path id="Path_506" data-name="Path 506" d="M1223.552,626.98v43.005a12.355,12.355,0,0,1-12.351,12.35h-60.519a12.355,12.355,0,0,1-12.35-12.35V626.98a12.4,12.4,0,0,1,12.35-12.351H1211.2a12.345,12.345,0,0,1,12.351,12.351Z" transform="translate(-1138.332 -571.72)" fill="#f02129" />
                        <path id="Path_66" data-name="Path 66" d="M1223.552,626.98v43.005a12.355,12.355,0,0,1-12.351,12.35h-60.519a12.355,12.355,0,0,1-12.35-12.35V626.98a12.4,12.4,0,0,1,12.35-12.351H1211.2a12.345,12.345,0,0,1,12.351,12.351Z" transform="translate(-1138.332 -579.569)" fill="#ff3b41" />
                        <path id="Path_508" data-name="Path 508" d="M1169.38,682.335h-18.7a12.355,12.355,0,0,1-12.35-12.35V626.98a12.4,12.4,0,0,1,12.35-12.351h45.737Z" transform="translate(-1138.332 -579.569)" fill="#ff5258" />
                        <path id="Path_68" data-name="Path 68" d="M1153.3,634.291v-7.153a5.639,5.639,0,0,1,5.656-5.659,5.6,5.6,0,0,1,5.627,5.659v7.153" transform="translate(-1116.326 -569.5)" fill="#d10b12" stroke="#d10b12" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <path id="Path_69" data-name="Path 69" d="M1173.594,637.336a11.29,11.29,0,1,1-11.288-11.286A11.292,11.292,0,0,1,1173.594,637.336Z" transform="translate(-1119.686 -562.778)" fill="#d10b12" stroke="#d10b12" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <path id="Path_507" data-name="Path 507" d="M1788.869-1549.516v-3.842a5.62,5.62,0,0,1,5.62-5.62h3.886" transform="translate(-1779.899 1602.977)" fill="none" stroke="#ffa2b5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
                    </g>
                </svg>
                <div className="minTilie">{this.state.lang1['SubscribeNowPopup'].title}</div>
                <div className="subTilie">{this.state.lang1['SubscribeNowPopup'].hint}</div>
                <Link to={process.env.REACT_APP_FRONT_URL + 'createAccount'} className="btn1">{this.state.lang1['SubscribeNowPopup'].buttonLabel}</Link>
            </div>
        )
    }
    render() {

        return (
            <div>
                <section id="settings" >

                    <div className="header">
                        <div className="leftSec">
                            <img className="img pointer" src={require("../assets/img/logo.png")} onClick={() => { this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home') }} />
                            <img id="backbtn" onClick={() => {
                                this.props.history.push(process.env.REACT_APP_FRONT_URL + 'home');
                            }} src={require("../assets/img/Group 4877.png")} />
                        </div>
                        <div className="centerSec">

                            <div className="settingsTitle">{this.state.lang1['HeaderTitles'].titleSettings}</div>
                        </div>
                        <div className="rightSec">
                            <div>
                                {/* <img src={require("../assets/img/logo.png")} /> */}
                            </div>
                        </div>
                    </div>
                    <div className="stage">

                        <img id="cloud1" src={require("../assets/img/Path 2346.png")} />

                        <img id="cloud2" src={require("../assets/img/Path 2337.png")} />

                    </div>
                    <div className="bodyHome">
                        <div className="topmenu">
                            <div className="itemMenu" onClick={() => {
                                this.changeSec(0);
                            }}>
                                <svg >
                                    <defs>
                                        <filter id="spacer" x="0" y="0" width="161.876" height="155.029" filterUnits="userSpaceOnUse">
                                            <feOffset dy="10" input="SourceAlpha" />
                                            <feGaussianBlur stdDeviation="12" result="blur" />
                                            <feFlood floodColor="#ccd9de" floodOpacity="0.153" />
                                            <feComposite operator="in" in2="blur" />
                                            <feComposite in="SourceGraphic" />
                                        </filter>
                                        <clipPath id="clipPath">
                                            <ellipse id="Ellipse_322" data-name="Ellipse 322" cx="35.095" cy="35.523" rx="35.095" ry="35.523" transform="translate(0 0)" fill="#f5fbff" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_4984" data-name="Group 4984" transform="translate(-378.264 -146.568)">
                                        <g transform="matrix(1, 0, 0, 1, 378.26, 146.57)" filter="url(#spacer)">
                                            <rect id="spacer-2" data-name="spacer" width="89.876" height="83.029" rx="21" transform="translate(36 26)" fill="#fff" opacity={this.state.sec == 0 ? '1' : '0'} />
                                        </g>
                                        <g id="Mask_Group_38" data-name="Mask Group 38" transform="translate(424.536 170)" clipPath="url(#clip-path)">
                                            <path id="Intersection_5" data-name="Intersection 5" d="M14.429,59.062A32.871,32.871,0,0,1,0,55.748V47.011A19.267,19.267,0,0,1,15.917,28.04,14.153,14.153,0,0,1,5.5,14.232,14,14,0,0,1,19.259,0,14,14,0,0,1,33.016,14.232,14.153,14.153,0,0,1,22.6,28.04a19.267,19.267,0,0,1,15.917,18.97v8.738a32.871,32.871,0,0,1-14.429,3.313Z" transform="translate(15.407 17.975)" fill={this.state.sec == 0 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                        </g>
                                    </g>
                                </svg>



                                <div className={this.state.sec == 0 ? "titleMenuActive" : "titleMenu"}>{this.state.lang1['SettingsScreen'].buttonTabProfilesLabel}</div>
                            </div>



                            <div className="itemMenu" onClick={() => {
                                this.changeSec(1);
                            }}>
                                <svg  >

                                    <g id="Group_4984" data-name="Group 4984" transform="translate(-575.992 -146.568)">
                                        <g transform="matrix(1, 0, 0, 1, 575.99, 146.57)" filter="url(#spacer)">
                                            <rect id="spacer-3" data-name="spacer" width="89.876" height="83.029" rx="21" transform="translate(36 26)" fill="#fff" opacity={this.state.sec == 1 ? '1' : '0'} />
                                        </g>
                                        <g id="Group_983" data-name="Group 983" transform="translate(637.147 188.831)">
                                            <rect id="spacer-2" data-name="spacer" width="25.679" height="50.502" rx="6" transform="translate(8 0)" fill={this.state.sec == 1 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />

                                            <line id="Line_29" data-name="Line 29" y2="30.743" transform="translate(39.929 9.998)" fill="none" strokeLinecap="round" strokeWidth="5" stroke={this.state.sec == 1 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                            <line id="Line_30" data-name="Line 30" y2="30.743" transform="translate(0 9.998)" fill="none" strokeLinecap="round" strokeWidth="5" stroke={this.state.sec == 1 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                        </g>
                                    </g>
                                </svg>



                                <div className={this.state.sec == 1 ? "titleMenuActive" : "titleMenu"}>{this.state.lang1['SettingsScreen'].buttonTabSubscriptionsLabel}</div>
                            </div>

                            <div className="itemMenu" onClick={() => {
                                this.changeSec(2);
                            }}>
                                <svg  >
                                    <defs>
                                        <filter id="spacer" x="0" y="0" width="162" height="155" filterUnits="userSpaceOnUse">
                                            <feOffset dy="10" input="SourceAlpha" />
                                            <feGaussianBlur stdDeviation="12" result="blur" />
                                            <feFlood floodColor="#ccd9de" floodOpacity="0.153" />
                                            <feComposite operator="in" in2="blur" />
                                            <feComposite in="SourceGraphic" />
                                        </filter>
                                    </defs>
                                    <g id="Group_4986" data-name="Group 4986" transform="translate(-792.857 -146.117)">
                                        <g transform="matrix(1, 0, 0, 1, 792.86, 146.12)" filter="url(#spacer)">
                                            <rect id="spacer-2" data-name="spacer" width="90" height="83" rx="21" transform="translate(36 26)" fill="#fff" opacity={this.state.sec == 2 ? '1' : '0'} />
                                        </g>
                                        <g id="Group_982" data-name="Group 982" transform="translate(848.032 189.149)" >
                                            <path id="Path_311" data-name="Path 311" d="M-1530.048,1409.121a23.9,23.9,0,0,0-23.9,23.9,23.8,23.8,0,0,0,4.566,14.05l-2.946,6.471,7.291-1.905v0a23.8,23.8,0,0,0,14.986,5.285,23.9,23.9,0,0,0,23.9-23.9A23.9,23.9,0,0,0-1530.048,1409.121Z" transform="translate(1554.921 -1408.146)" fill={this.state.sec == 2 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                        </g>
                                    </g>
                                </svg>




                                <div className={this.state.sec == 2 ? "titleMenuActive" : "titleMenu"}>{this.state.lang1['SettingsScreen'].buttonTabLanguagesLabel}</div>
                            </div>


                            <div className="itemMenu" onClick={() => {
                                this.changeSec(3);
                            }}>
                                <svg >
                                    <defs>
                                        <filter id="spacer" x="0" y="0" width="162.732" height="155.029" filterUnits="userSpaceOnUse">
                                            <feOffset dy="10" input="SourceAlpha" />
                                            <feGaussianBlur stdDeviation="12" result="blur" />
                                            <feFlood floodColor="#ccd9de" floodOpacity="0.153" />
                                            <feComposite operator="in" in2="blur" />
                                            <feComposite in="SourceGraphic" />
                                        </filter>
                                    </defs>
                                    <g id="Group_4987" data-name="Group 4987" transform="translate(-972.304 -146.568)">
                                        <g transform="matrix(1, 0, 0, 1, 972.3, 146.57)" filter="url(#spacer)">
                                            <rect id="spacer-2" data-name="spacer" width="90.732" height="83.029" rx="21" transform="translate(36 26)" fill="#fff" opacity={this.state.sec == 3 ? '1' : '0'} />
                                        </g>
                                        <g id="Group_981" data-name="Group 981" transform="translate(1034.342 188.498)">
                                            <g id="Group_323" data-name="Group 323" transform="translate(0 0)">

                                                <g id="Group_321" data-name="Group 321" transform="translate(0 7.018)">
                                                    <path id="Path_295" data-name="Path 295" d="M-1395.878,1470.894l.005,0c-3.717-4.016-3.955-5.082-3.955-7.734v-5.784a14.317,14.317,0,0,0-14.319-14.316,14.316,14.316,0,0,0-14.318,14.316v5.784c0,2.652-.24,3.718-3.957,7.734l.007,0a4.525,4.525,0,0,0-1.2,3.072v.191a4.553,4.553,0,0,0,4.553,4.55h29.835a4.551,4.551,0,0,0,4.55-4.55v-.191A4.531,4.531,0,0,0-1395.878,1470.894Z" transform="translate(1433.616 -1443.057)" fill={this.state.sec == 3 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                                </g>
                                                <g id="Group_322" data-name="Group 322" transform="translate(13.495 45.195)">
                                                    <path id="Path_296" data-name="Path 296" d="M-1423.028,1473.01a5.989,5.989,0,0,0,5.934,5.2,5.992,5.992,0,0,0,5.937-5.2Z" transform="translate(1423.028 -1473.01)" fill={this.state.sec == 3 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>



                                <div className={this.state.sec == 3 ? "titleMenuActive" : "titleMenu"} >{this.state.lang1['SettingsScreen'].buttonTabNotificationsLabel}</div>
                            </div>
                            <div className="itemMenu" onClick={() => {
                                this.changeSec(4);
                            }}>
                                <svg  >
                                    <defs>
                                        <filter id="spacer" x="0" y="0" width="162.732" height="155.029" filterUnits="userSpaceOnUse">
                                            <feOffset dy="10" input="SourceAlpha" />
                                            <feGaussianBlur stdDeviation="12" result="blur" />
                                            <feFlood floodColor="#ccd9de" floodOpacity="0.153" />
                                            <feComposite operator="in" in2="blur" />
                                            <feComposite in="SourceGraphic" />
                                        </filter>
                                    </defs>
                                    <g id="Group_4988" data-name="Group 4988" transform="translate(-1169.176 -146.568)">
                                        <g transform="matrix(1, 0, 0, 1, 1169.18, 146.57)" filter="url(#spacer)">
                                            <rect id="spacer-2" data-name="spacer" width="90.732" height="83.029" rx="21" transform="translate(36 26)" fill="#fff" opacity={this.state.sec == 4 ? '1' : '0'} />
                                        </g>
                                        <path id="Path_1992" data-name="Path 1992" d="M-546.643-286h-7.887s-2.739,8.818-10.845,11.612c0,0,.986,19.334,18.732,26.181,17.746-6.846,18.732-26.181,18.732-26.181C-536.017-277.182-538.756-286-538.756-286Z" transform="translate(1797.895 480.853)" fill={this.state.sec == 4 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                    </g>
                                </svg>




                                <div className={this.state.sec == 4 ? "titleMenuActive" : "titleMenu"} >{this.state.lang1['SettingsScreen'].buttonTabPrivacyPoliciesLabel}</div>
                            </div>
                            <div className="itemMenu" onClick={() => {
                                this.changeSec(5);
                            }}>
                                <svg >
                                    <defs>
                                        <filter id="spacer" x="0" y="0" width="161.876" height="155.029" filterUnits="userSpaceOnUse">
                                            <feOffset dy="10" input="SourceAlpha" />
                                            <feGaussianBlur stdDeviation="12" result="blur" />
                                            <feFlood floodColor="#ccd9de" floodOpacity="0.153" />
                                            <feComposite operator="in" in2="blur" />
                                            <feComposite in="SourceGraphic" />
                                        </filter>
                                    </defs>
                                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#spacer)">
                                        <rect id="spacer-2" data-name="spacer" width="89.876" height="83.029" rx="21" transform="translate(36 26)" fill="#fff" opacity={this.state.sec == 5 ? '1' : '0'} />
                                    </g>
                                    <path id="_6.Terms-_-conditions2-01" data-name="6.Terms-&amp;-conditions2-01" d="M151.14,141.285h-7.123l.056-37.044a5.22,5.22,0,0,0-5.268-5.162h-1.538a.4.4,0,0,0-.188-.046H104.927a5.228,5.228,0,0,0-5.269,5.171v5.043a.46.46,0,0,0,.469.459h6.9l-.066,37.082a5.226,5.226,0,0,0,5.269,5.162l.009-.458v.458h1.94s0,.011.01.011h32.152a5.229,5.229,0,0,0,5.269-5.173v-5.042A.462.462,0,0,0,151.14,141.285Zm-36.775-10.948a1.418,1.418,0,0,1-.993.4,1.44,1.44,0,0,1-.993-.4,1.38,1.38,0,0,1-.412-.973,1.348,1.348,0,0,1,.412-.973,1.463,1.463,0,0,1,1.987,0,1.345,1.345,0,0,1,.413.973A1.393,1.393,0,0,1,114.365,130.337Zm0-9.36a1.418,1.418,0,0,1-.993.4,1.439,1.439,0,0,1-.993-.4,1.351,1.351,0,0,1-.412-.973,1.377,1.377,0,0,1,.412-.973,1.491,1.491,0,0,1,1.987,0,1.374,1.374,0,0,1,.413.973A1.348,1.348,0,0,1,114.365,120.977Zm0-9.35a1.419,1.419,0,0,1-.993.405,1.441,1.441,0,0,1-.993-.405,1.363,1.363,0,0,1-.412-.973,1.382,1.382,0,0,1,.412-.973,1.474,1.474,0,0,1,1.987,0,1.353,1.353,0,0,1,0,1.947Zm15.393,28.465H119.2a1.377,1.377,0,1,1,0-2.754h10.556a1.377,1.377,0,1,1,0,2.754Zm7.968-9.35H119.2a1.378,1.378,0,1,1,0-2.755h18.524a1.378,1.378,0,1,1,0,2.755Zm0-9.361H119.2a1.378,1.378,0,1,1,0-2.755h18.524a1.378,1.378,0,1,1,0,2.755Zm0-9.35H119.2a1.379,1.379,0,1,1,0-2.757h18.524a1.379,1.379,0,1,1,0,2.757Z" transform="translate(-46.792 -57.484)" fill={this.state.sec == 5 ? "#ffb601" : 'rgba(152, 136, 227, 0.51)'} />
                                </svg>








                                <div className={this.state.sec == 5 ? "titleMenuActive" : "titleMenu"} >{this.state.lang1['SettingsScreen'].buttonTabTermsOfUseLabel}</div>
                            </div>
                        </div>
                        {localStorage.getItem('token') != undefined && (
                            <div className="Result">
                                {this.state.sec == 0 ?
                                    JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? <Profiles /> : this.loginDev() : this.state.sec == 1 ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? <Subscription /> : this.subscriptionDev() : this.state.sec == 2 ? <Language /> : this.state.sec == 3 ? JSON.parse(localStorage.getItem('token')).access_token != 'guest' ? <Notification /> : this.NotificationDev() : this.state.sec == 4 ? <Privacy /> : this.state.sec == 5 ? <Terms /> : ''}
                            </div>
                        )}

                    </div>
                </section>


            </div >
        );
    }
}

export default Settings;